import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const chineseLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['简体中文 (Chinese Simplified)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': '请使用笔记本电脑或台式机查看您的录像。',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': '您确定要邀请尚未在项目中完成场景的<strong>所有分配的学员</strong>吗？他们将收到一封邀请其连夜安排的电子邮件。',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': '您确定要邀请尚未完成此场景的<strong>所有分配的学员</strong>吗？他们将收到一封邀请其连夜安排的电子邮件。',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': '客户希望保留其录像数据的天数。超过此天数，录像将被删除。',
    'Settings.SSO.CopyScenarioLink': '复制场景邀请链接',
    'MursionPortal.Label.VideoRecordingSharing': '用户分享或下载其课程视频',
    'MursionPortal.Status.Capitalized.LateCancelled': '延迟_已取消',
    'MursionPortal.Status.Capitalized.EarlyCancelled': '提前_已取消',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': '恭喜！',
    'MursionPortal.Text.PasswordChanged': '您的密码已更改',
    'MursionPortal.Text.PasswordShouldBeSame': '您的新密码必须匹配。',
    'Filters.LateRescheduled': '延迟改期',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': '已安排但尚未完成的即将进行的课程（包括正在运行的课程）',
    'MursionPortal.Checkbox.EnableSmartMetrics': '启用课程学员语音分析',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': '延迟改期时间',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': '此模拟延迟改期的时间',
    'SessionReports.Label.CopyVideoLink': '复制视频链接',
    'Login.LoginAgain': '重新登录',
    'Dashboard.VideosTable.Column.SessionStart': '课程开始',
    'Filters.Date.LastSevenDays': '上周',
    'Filters.Date.NextSevenDays': '下周',
    'Filters.Date.ThisYear': '今年',
    'Mursion.Portal.Status.LearnerMissed': '学员已错过',
    'Mursion.Portal.Status.SystemCancelled': '已取消',
    'Mursion.Portal.Status.LateCancelled': '延迟取消',
    'Mursion.Portal.Status.EarlierCancelled': '提前取消',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': '已完成',
    'MursionPortal.Dashboard.UpcomingSessions': '即将进行的课程',
    'MursionPortal.Label.Opened': '已打开',
    'MursionPortal.Label.ActedOn': '执行',
    'MursionPortal.Label.Unread': '未读',
    'MursionPortal.Label.Timeline': '项目时间表',
    'Projects.ProjectInfo.Label.TimelineUtc': '项目时间表(UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': '描述',
    'MursionPortal.TimelineUTC': '项目时间表<code>{word}</code>(UTC)',
    'MursionPortal.Label.ChangePassword': '更改密码',
    'MursionPortal.Label.ChangeYourPassword': '更改您的密码',
    'MursionPortal.Label.NewChangePassword': '输入您的新密码',
    'MursionPortal.Label.OldPassword': '输入您当前的密码',
    'MursionPortal.Label.RepeatChangePassword': '重新输入您的新密码',
    'MursionPortal.Prompt.SessionScheduled': '<code>课程</code>已取消！',
    'MursionPortal.Status.Booked': '已预订',
    'MursionPortal.Status.Pending': '待处理',
    'MursionPortal.Completed': '已完成',
    'MursionPortal.Status.Missed': '已错过',
    'MursionPortal.Status.Cancelled': '已取消',
    'MursionPortal.Status.LateCancelled': '延迟取消',
    'MursionPortal.Status.EarlyCancelled': '提前取消',
    'MursionPortal.Status.LicenseCancelled': '已取消',
    'MursionPortal.Status.Error': '错误',
    'MursionPortal.ConversationalFlow.label.zoom.all': '全部',
    'Clients.ImportUsers.DownloadErrorsButton': '下载错误',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': '下载错误',
    'MursionPortal.Dashboard.NextOnProject': '要预定的场景',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': '您必须在{endDate}之前完成此场景。',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': '您必须在{endDate}之前最多完成 {completionRate} 次。',
    'MursionPortal.Dashboard.NothingPlanned.text1': '你们都赶上了！你们',
    'MursionPortal.Dashboard.NothingPlanned.text2': '预定的课程将显示在此处。',
    'MursionPortal.Learner.Missed': '学员已错过',
    'MursionPortal.AriaImage.Attribute.Next': '下一步：',
    'MursionPortal.AriaImage.Attribute.Close': '关闭：',
    'MursionPortal.AriaImage.Attribute.Back': '返回：',
    'MursionPortal.AriaImage.Attribute.Delete': '删除：',
    'MursionPortal.AriaImage.Attribute.Remove': '删除',
    'MursionPortal.AriaImage.Attribute.Document': '文件',
    'MursionPortal.AriaImage.Attribute.Video': '视频',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': '删除文件',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate}次',
    'MursionPortal.Button.RefreshPage': '刷新页面',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': '确定要关闭吗？您的日历页面将保持过时状态。',
    'Reports.SessionListHeader.Label.AverageTime': '<code>每个课程的平均时间</code>',
    'MursionPortal.Users.Archive.HasSessions': '无法存档Simulation Specialist，因为为此Simulation Specialist预定了一个或多个课程。请交换或更换Sim Specialist。',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': '如果有一个Sim在此模拟中获得认证，但不适用于新的模拟，则使用。',
    'MursionPortal.Label.InactiveSIMCertification': '无效Sim认证',
    'Dashboard.Filters.FilterBySessionDate': '按课程日期筛选',
    'Dashboard.LeftPane.CategoryHeader.Emails': '电子邮件',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': '这是学员在项目中注册模拟后必须取消的天数/小时/分钟数。如果学员在截止日期后取消，则出于计费目的，课程将被视为完成。',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': '允许课程录制',
    'MursionPortal.Label.SendRecordedVideosTo': '将录像发送至：',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': '如果学员不同意录制，则允许学员加入课程',
    'Projects.ProjectInfo.SchedulingInterval': '预定间隔时间',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': '使预定能够不按课程持续时间稳序进行。',
    'Projects.ProjectInfo.SchedulingIntervalLength': '预定间隔时长',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': '确定学员看到调度块的频率。如果设置为15，学员将以15分钟的间隔时间看到调度块。不设置课程的持续时间，只设置课程运行的频率。',
    'Projects.ProjectInfo.DeliveryMode.tooltip': '学员使用的平台。如果您希望启用Mursion Magic和/或ML3直接连接软件，请设置为ML3。 ML3z = Zoom。Mursion Meet = Google Meet。',
    'MursionPortal.Label.WebOnly': '仅限网络',
    'MursionPortal.Label.DesktopOnly': '仅限台式机',
    'MursionPortal.Label.DesktopAndWeb': '台式机和Web',
    'MursionPortal.EmailCategory.CallsToAction': '行动号召',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': '发送',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': '所有电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': '趋势',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': '类别摘要',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': '注册电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': '行动号召电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': '预定电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': '重新预定电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': '视频电子邮件',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': '邮件已发送',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': '电子邮件分析让您大致了解学员如何使用<link>Mursion的电子邮件通信</link>。电子邮件可以分成以下三类',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': '学员打开了一封或多封收到的电子邮件。',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': '学员打开了电子邮件并单击了一个或多个链接，例如<strong>预定模拟</strong>或<strong>加入模拟</strong>链接。',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': '学员尚未打开电子邮件。如果有大量未读电子邮件，请确认您的学员正在接收它们。请咨询您的IT团队，确认<color>no-reply@mursion.com</color>未被贵组织的垃圾邮件筛选器拦截。',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': '向用户发送电子邮件，邀请其创建Mursion帐户',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Account Owner从项目或场景页面发来的提醒电子邮件',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': '课程特定的电子邮件。“邀请预定”和其他“加入课程”电子邮件',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': '在错过或取消模拟后提示学员重新预定的电子邮件',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': '提醒学员可以观看模拟视频的电子邮件',
    'MursionPortal.Dashboard.EmailCategoryFilter': '电子邮件类别',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': '搜索电子邮件类别...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': '课程已改期',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': '搜索电子邮件类型...',
    'MursionPortal.Text.NoImageAvailable': '没有可用的图像',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': '单击下面的<strong>下载当前会员资格</strong>，为学员更新团队分配(<strong>edit_learner_team_memberships.xlsx</strong>)。',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': '下载当前会员资格',
    'MursionPortal.Project.SameDay': '同一天',
    'MursionPortal.Project.CancelationDeadline.Validation': '取消截止日期应大于0。',
    'ClientUserTable.AddNewUsers.RolesHeading': '您一次只能添加一种用户。选择您要添加的用户类型。',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Account Owner是您整个组织的主要管理员。',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Facilitator是团队级别的管理员，负责管理一个或多个特定团队。',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': '学员是参加Mursion模拟的任何人。这将是您的大部分用户群。',
    'ClientUserTable.AddNewUsers.Heading': '添加新用户',
    'ClientUserTable.AddNewUsers.Roles.Heading': '为用户分配角色',
    'ClientUserTable.AddNewUsers.ImportUsers': '请为最多500个用户完成以下步骤。如果导入的用户数超过500个，请使用我们的“<code>导入用户</code>”功能。',
    'ClientUserTable.AddNewAttributes.label': '添加用户属性',
    'ClientUserTable.AddNewAttributes.Header.Text': '请注意，这些属性将应用于此列表中的所有用户。',
    'ClientUserTable.UserAuthentication.label': '用户认证',
    'ClientUserTable.NewUsersAdded.Heading': '新用户已添加！',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': '为用户分配团队',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': '学员和Facilitator必须分配到一个团队。如果您还没有创建团队，请<code>单击此处</code>。',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': '选择团队',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': '请添加500个或更少的用户。要导入超过500个用户，您可以使用我们的“导入用户”功能。',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': '您必须至少选择一个团队。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': '添加用户电子邮件地址',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': '您可以一次添加一封电子邮件或粘贴一个列表。电子邮件应以空格、逗号或分号分隔。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': '在此处键入或粘贴电子邮件地址。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '一次{maximumUsersAllowed}个用户',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': '总数',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': '重复',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': '已存在',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': '无效',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': '手语翻译',
    'ClientUserTable.AssignTeams.TotalUsers': '您即将添加<code>{users}个新的{role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': '您即将添加<code>{users}个新的{role}</code>并更新<code>{existingUsers}个现有的{role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': '您即将更新<code>{existingUsers}个现有的{role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': '将分配给<code>{numberOfTeams}个团队</code>',
    'ClientUserTable.Confirmation.Heading': '添加前请确认',
    'MursionPortal.InviteToSchedule.Confirmation.Text': '在此处了解更多信息：<code>邀请预定</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Account Owner指南',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Facilitator指南',
    'MursionPortal.Users.Confirmation.Text': '您的用户已添加到Mursion门户。',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': '返回用户',
    'MursionPortal.Users.Next.Steps.Text': '下一步',
    'MursionPortal.Users.StepOne.Text': '第1步：',
    'MursionPortal.Users.StepTwo.Text': '第2步：',
    'MursionPortal.Users.StepThree.Text': '第3步：',
    'MursionPortal.Users.ThatIt.Text': '就是这样！',
    'MursionPortal.Users.InviteToScheduleLearner.Text': '邀请您的学员预定',
    'MursionPortal.Users.LearnersLink.Text': '您可以将此链接发送给您的学员',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': '在任何项目或场景中使用<strong>邀请预定</strong>按钮。',
    'MursionPortal.Users.CopyInviteLink.Text': '复制邀请链接',
    'MursionPortal.Users.ShareContent.Text': '与您的管理员共享支持内容',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Owner与Facilitator的职责不同，可能需要了解有关门户的更多信息。与您的Account Owner和Facilitator分享这些链接，以便他们可以解答在Mursion使用过程中可能遇到的任何问题。',
    'MursionPortal.Users.Administrators.Text': '您的管理员现在可以访问Mursion门户。',
    'MursionPortal.Users.Progress.Text': '您的学员将开始预定模拟。您可以在仪表板上跟踪其进度。',
    'MursionPortal.Users.Access.Text': '确保您的IT部门已授予这些用户访问Mursion App的权限',
    'MursionPortal.Users.Registrationemail.Text': '您的用户应该刚刚从no-reply@mursion.com收到注册电子邮件。',
    'MursionPortal.AddMoreUsers.Btn.Text': '添加更多用户',
    'MursionPortal.Users.Ok.Btn.Text': '好的，我知道了！',
    'Users.Button.AddNewUsers': '添加新用户',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': '取消期限不能超过365天。',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': '密码已重置',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': '验证',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': '邀请预定',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': '总结',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': '改期已错过',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': '改期已取消',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'PS改期',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': '录像已损坏',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': '录像未上传',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': '录像可用',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': '录像已共享',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': '预订',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': '行为准则',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': '用户已删除',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': '考勤提醒',
    'Users.Button.AddNewLearners': '添加新学员',
    'MursionPortal.Scenario.Label.LastDelivered': '最近已授课',
    'MursionPortal.Scenario.Label.TotalDeliveries': '总授课量',
    'MursionPortal.Scenario.Text.NotYetDelivered': '尚未授课',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': '这些{existingUsers}用户已存在。继续会将这些用户添加到选定的团队（除了他们已经分配到的任何团队之外）。',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator',
    'ClientUserTable.AddNewUsers.Learners': '学员',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': '所选时段不再可用。请刷新以查看可预定的时段',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': '课程第一次提醒',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': '课程第二次提醒',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': '显示SIM空闲时间',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': '当学员预定时，他们将看到您选择的范围内的SIM Specialist空闲时间。',
    'Projects.InputValidation.GreaterThanZero': '应大于0。',
    'MursionPortal.Weeks': '周',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': '时段要求',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': '截至2022年11月24日，“打开并执行”数据是准确的。',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': '在此日期之前，所有电子邮件都被归类为“未读”。',
    'MursionPortal.Learners.Filters.LearnerCompletion': '学员完成',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': '尚未完成',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': '已完成1节课',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': '已完成2节课以上',
    'MursionPortal.Learners.Filters.MissedLateActivity': '错过/迟到的活动',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1节课',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2节课',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3节课',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4节课以上',
    'MursionPortal.BackToCompletedSessions': '返回已完成的课程',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': '您是要向尚未完成此场景的<strong>所有</strong>已分配学员发送邀请，还是要<strong>选择学员</strong>进行邀请？',
    'MursionPortal.BackToSessionSummaries': '返回课程总结',
    'MursionPortal.BackToLearnerSessions': '返回学员课程',
    'MursionPortal.LearnerSessionReport.SessionObjective': '课程目标',
    'MursionPortal.LearnerSessionReport.SessionStrategy': '您应用这些战略的情况如何？',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': '课程战略靶心目标',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': '坚持下去！',
    'MursionPortal.LearnerSessionReport.SessionDetail': '场景描述',
    'MursionPortal.LearnerSessionReport.SessionDate': '课程日期',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': '请先删除所有重复或无效的电子邮件，然后再继续。',
    'MursionPortal.BackToSessionDetail': '返回课程详情',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': '电子邮件分析',
    'MursionPortal.Session.LearnerSession.Report': '报告',
    'MursionPortal.Session.LearnerSession.Recording': '正在录像',
    'MursionPortal.Session.LearnerSession.SessionDetails': '课程详情',
    'MursionPortal.Session.LearnerSessionReport': '学员课程报告',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': '选择学员',
    'MursionPortal.Scenario.Button.Label': '复制学员电子邮件',
    'MursionPortal.VideoStatusFilter.Label': '视频状态',
    'MursionPortal.TooltipText.VideoNotUploaded': '如果此视频在48小时后仍未上传，请联系支持人员。',
    'MursionPortal.VideoStatusFilter.SearchText': '搜索视频状态...',
    'MursionPortal.VideoStatusFilter.Uploaded': '已上传',
    'MursionPortal.VideoStatusFilter.NotUploaded': '未上传',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': '邀请',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': '复制电子邮件',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': '如果学员在最近2天内尚未预定或收到邀请，您的邀请将连夜发送。',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>选定的学员</code>将连夜收到一封邀请预定的电子邮件（如果他们在最近2天内尚未预定或收到邀请）。',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': '确定要邀请<code>所有选定的学员</code>？',
    'ClientUserTable.Column.LastInvited': '最近已邀请',
    'ClientUserTable.ColumnTooltip.LastInvited': '最近发送邀请安排的电子邮件的日期。如果刚刚已邀请，这可能是明天。',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': '选定学员的电子邮件地址已复制到您的剪贴板',
    'MursionPortal.ScenarioInfo.MarkAllInactive': '标记全部无效',
    'MursionPortal.ScenarioInfo.DecertifyAll': '全部取消认证',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': '确定要停用所有SIM Specialist吗？',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': '确定要取消所有SIM Specialist认证吗？',
    'Dashboard.Button.ViewSession': '查看课程',
    'MursionPortal.SessionReport.NotAvailable': '不可用',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': '为1:1授课启用“报告”选项卡',
    'MursionPortal.Learner.Session.Report': '学员课程报告',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims}个SIM已停用，共{totalSims}个。',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims}个SIM已取消认证，共{totalSims}个。',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims}SIM无法取消认证，因为它们已预定课程。将它们标记为非活动状态，以尊重它们的课程并防止未来的课程分配。',
    'MursionPortal.AriaLabel.ViewMore': '查看更多',
    'MursionPortal.SimAttendance.SimulationAttendance': '模拟学员考勤',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': '来不及完成',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': '学员已加入但由于未准备好或其他原因未完成模拟。',
    'MursionPortal.Label.SchedulingRate': '预定率',
    'Mursion.Portal.Status.No.Show': '未出现',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code}次',
    'MursionPortal.Scenario.Modal.Search.Placeholder': '搜索电子邮件地址、名字、姓氏',
    'MursionPortal.Scenario.SchedulingRateFulfilled': '您必须先完成预定的课程。',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': '选择要邀请的学员',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': '通过播放模拟录像来反思对话',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': '表示使用的课程软件',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.LearnerSessionReport.YourPerspective': '您的观点',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': '您进入模拟时的信心为<strong>{preConfidenceLevel}</strong>，模拟后为<strong>{postConfidenceLevel}。</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': '非常低',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': '低',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': '适中',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': '高',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': '很高',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': '您<strong>成功</strong>展示了这个战略',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': '您将<strong>从实施该战略的更多实践中受益</strong>',
    'MursionPortal.SchedulingRate.ValidationMessage': '预定率必须小于或等于完成率',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': '后续步骤：熟能生巧！',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': '重复是使这些技能成为第二天性的关键。',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': '您的报告很快就会准备好',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': '您有{exceededCharLength}个字符已超出字符限制。请修改。',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added}个，共{totalChar}个',
    'MursionPortal.Scenario.CharacterLimit.Message': '字符限制：{maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': '在此处添加描述',
    'MursionPortal.Scenario.AttachmentField.text': '您可以添加更多文件',
    'MursionPortal.sessionTable.Column.OriginalSim': '初始SIM',
    'MursionPortal.sessionTable.Column.NewSim': '新SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code}证书已停用。',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': '确定要停用此SIM Specilaist的所有证书吗？',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': '没有有效证书',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': '没有无效证书',
    'MursionPortal.Users.Modal.ActiveCerts': '有效证书',
    'MursionPortal.Users.Modal.InactiveCerts': '无效证书',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': '标记全部无效',
    'MursionPortal.SurveyFilter.Incomplete': '未完成',
    'MursionPortal.SurveyFilter.SearchText': '搜索调查...',
    'MursionPortal.Dashboard.SurveyReminder.Text': '您要完成{surveyCount}个调查',
    'MursionPortal.Dashboard.RemindMeLater': '稍后提醒我',
    'MursionPortal.Dashboard.SurveysIncomplete': '调查未完成',
    'MursionPortal.AriaLabel.NormalFont': '正常字体',
    'MursionPortal.AriaLabel.MediumFont': '中等字体',
    'MursionPortal.AriaLabel.LargeFont': '大字体',
    'MursionPortal.AriaLabel.Font': '字体',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': '已改期和已取消',
    'MursionPortal.Dashboard.Summary.Certifications': '认证',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': '选择',
    'MursionPortal.Label.MissCancellationRate': '错过/取消率',
    'MursionPortal.Label.NotAllowed': '不允许',
    'Dashboard.LeftPane.CategoryHeader.Simulations': '课程',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': '课程摘要',
    'Dashboard.LeftPane.Simulations.SimulationDetail': '课程详情',
    'MursionPortal.Message.NotEnoughDataToDisplay': '该课程没有足够的数据。',
    'MursionPortal.Message.Notdata.SessionMatrix': '该课程没有足够的数据来提供课程分析。',
    'MursionPortal.Tooltip.SchedulingWindow': '最终用户可以按天、小时、分钟来设置该值，之后只有用户才能预定课程。',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': '我们现在没有任何可用的时间段。请稍后重试。如果您立即需要帮助，请<code1>联系</code1><code>支持人员</code>。',
    'Session.Edit.Modal.JoinSession': '开始课程',
    'MursionPortal.SimAttendance.Tooltip.Completed': '学员完成了课程的模拟部分（不包括课程结束时的汇报）。',
    'MursionPortal.SimAttendance.Tooltip.Late': '学员连接太晚，无法完成模拟。',
    'MursionPortal.SimAttendance.Tooltip.Left': '学员在完成模拟之前故意断开连接。这可能是由于学员未准备好，或者学员可能因紧急情况离开。',
    'Session.Edit.Modal.JoinSessionBtnTooltip': '“开始课程”按钮将在课程开始<code>{minutesBeforeJoin}</code>分钟前启用。',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': '课程摘要数据',
    'Dashboard.Learners.LearnerActivity.TableHeader': '学员活动数据',
    'Dashboard.Learners.LearnerSimulation.TableHeader': '学员课程数据',
    'Dashboard.ContractProgress.Column.Missed': '学员已错过',
    'Dashboard.ContractProgress.Column.TotalRescheduled': '改期总数',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': '改期的补课',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': '延迟改期',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': '提前改期',
    'Dashboard.ContractProgress.Column.CompletedUtilization': '课程完成',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': '预计完成课程',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': '购买的课程减去任何完成的课程。',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': '预定的模拟总数',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': '标记为改期的补课的模拟总数',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': '延迟改期的模拟。',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': '提前改期的模拟。',
    'Dashboard.Learners.Column.SimulationId': '课程 - ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': '复制课程ID',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Recorded': '已录像',
    'Dashboard.Learners.Column.LearnerRecordingConsent': '学员录像同意书',
    'Dashboard.Learners.Column.RecordingRecipients': '录像接收者',
    'Dashboard.Learners.Column.RecordingUploaded': '录像已上传',
    'Dashboard.Learners.ColumnTooltip.SSOID': '已配置的用户',
    'Dashboard.Learners.ColumnTooltip.Recorded': '表示模拟是否被录像，或是否被拒绝。',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': '表示学员是否同意对模拟进行录像。',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': '模拟录像被发送到的角色。',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': '表示模拟录像是否上传。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': '学员延迟改期的课程。',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': '延迟改期',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': '学员身份（所有合同）',
    'Dashboard.SimulationTable.Column.SimulationStatus': '课程状态',
    'MursionPortal.Placeholder.ContractValue': '选择合同',
    'MursionPortal.Placeholder.NoContractValue': '无可用合同',
    'Dashboard.Learners.Column.AssignedTeams': '分配的团队',
    'SessionReports.VideoRecordingDeletedWithSupportCase': '很遗憾，由于待处理#{supportCase}，此视频无法查看。',
    'MursionPortal.Dashboard.CompletedSessions': '完成的课程',
    'MursionPortal.Dashboard.ViewAll': '查看全部',
    'MursionPortal.Dashboard.DateOfSimulation': '课程日期',
    'Dashboard.LeftPane.CategoryHeader.Videos': '视频',
    'Dashboard.LeftPane.Videos.NotUploaded': '录像未上传',
    'Dashboard.NotUploaded.TableHeader': '录像未上传数据',
    'Dashboard.VideosTable.Column.SessionId': 'SessionID',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',
    'Dashboard.VideosTable.Column.ClientId': 'ClientID',
    'Dashboard.VideosTable.Column.SessionEnd': '课程结束',
    'Dashboard.VideosTable.Column.SoftwareVersion': '场景版本',
    'Dashboard.VideosTable.NoVideosError': '找不到任何视频。',
    'Filters.SimSpecialist': 'SIM名称',
    'Filters.SimSpecialistPlaceHolderHint': '搜索SimSpecialist',
    'MursionPortal.Placeholder.SimSpecialistValue': '选择SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': '加载用户时发生错误！',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': '合同日期',
    'Dashboard.ContractProgress.InfoBadge.ContractID': '合同ID',
    'Dashboard.Learners.Column.EarlyCancelled': '提前取消',
    'Dashboard.Learners.Column.LateReschedules': '延迟改期',
    'Dashboard.Learners.Column.EarlyReschedules': '提前改期',
    'Dashboard.Learners.Column.RescheduledMakeUp': '改期的补课',
    'Dashboard.Learners.Column.ErrorSim': '错误(SIM)',
    'Dashboard.Learners.Column.CancelledOps': '已取消（操作）',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': '该学员目前被分配到的项目',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': '该学员目前被分配到的团队',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': '该学员目前被分配到的场景',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': '该学员已预定但尚未完成的场景',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': '该学员已完成的场景',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': '学员、facilitator或account owner提前取消的模拟（在取消截止日期之前）或模拟',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': '学员、facilitator或account owner延迟改期的模拟（在取消截止日期之后）',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': '学员、facilitator、account owner之前错过的模拟或模拟',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': '学员、facilitator或account owner提前改期的模拟时间（在取消截止日期之前）',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': '在连接的SIM端以错误结束的模拟',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Mursion取消的模拟',
    'SessionReports.VideoRecordingPermissionNotGranted': '此视频不可用，因为参与者不希望被录像。',
    'SessionReports.NoVideoAvailableForSession': '此课程没有视频。',
    'MursionPortal.NotAvailable': '不适用',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': '为1启用模拟后SIM反馈调查',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': '为研讨会授课启用模拟后SIM反馈调查',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': '模拟后SIM反馈调查1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': '模拟后SIM反馈调查研讨会授课',
    'Clients.Portal.Button.EditMemberships': '编辑会员资格',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': '只显示可用项目',
    'MursionPortal.Status.Undefined': '未定义',
    'MursionPortal.Status.Orphan': 'Orphan',
    'MursionPortal.Status.Reserved': '已保留',
    'MursionPortal.Status.Waif': 'Waif',
    'MursionPortal.Status.Upcoming': '即将进行',
    'MursionPortal.Status.Running': '正在运行',
    'MursionPortal.Status.NeedsReview': '需要审查',
    'MursionPortal.Status.Reviewed': '已审查',
    'MursionPortal.ErrorMessage.NoDataAvailable': '该课程没有足够的数据来提供课程分析。',
    'Dashboard.NotUploaded.MainHeader': '录像未上传',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': '该课程没有足够的数据来创建此图表',
    'MursionPortal.Label.SIMSpecialist': 'SIM Specialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': '学员录像同意书',
    'Dashboard.SimulationTable.Column.RecordingRecipients': '录像接收者',
    'Dashboard.SimulationTable.Column.RecordingUploaded': '录像已上传',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': '表示学员是否同意对模拟进行录像',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': '模拟录像被发送到的角色',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': '表示模拟录像是否上传',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': '学员插话',
    'MursionPortal.label.AvatarInterjects': '头像插话',
    'MursionPortal.label.LearnerInterrupted': '学员插话',
    'MursionPortal.label.AvatarInterrupted': '头像已插话',
    'MursionPortal.label.Silence': '无声',
    'Clients.Modal.Label.UserRecordingConsent': '用户录像同意书',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': '询问用户录像同意书',
    'MursionPortal.Notset': '未设置',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Mursion Social用户录像同意书',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': '询问用户是否同意录像。如果未启用，SIM Specialist将口头询问。',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': '未预订课程且在选定时间可用的认证SIM',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': '在系统中不可用且未在选定时间预订其他课程的认证SIM',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': '显示录像数据保留政策配置',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': '返回场景',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': '返回日历',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': '由于贵公司的数据保留政策，该视频不可用。',
    'SessionReports.VideoRecordingAvailabilityMessage': '该视频将在{videoAvailabilityDate}之前可供观看',
    'SessionReports.SessionOrLearnerStatusError': '由于技术错误，该课程没有录制视频。',
    'Clients.ImportUsers.NextStepButton': '下一步',
    'Clients.ImportUsers.CancelButton': '取消',
    'Clients.ImportUsers.UploadValidateButton': '上传和验证',
    'Session.Table.Column.Attendance': '考勤',
    'Session.Table.Column.Value.CompleteAttendance': '完成考勤',
    'Session.Table.Column.Value.AttendanceComplete': '考勤完成',
    'Session.Table.Column.Value.NotApplicable': '（不适用）',
    'Session.Table.Column.Survey': '调查',
    'Session.Table.Column.Value.CompleteGoogleForm': '完成Google表格',
    'Session.Table.Column.Value.CompleteSurvey': '完成调查',
    'Session.Table.Column.Value.SurveyComplete': '调查完成',
    'Projects.Modal.Text.LearnerSelected': '至少需要选择{maxLearners}个学员',
    'Session.Table.Column.Value.SessionNotCompleted': '课程未完成',
    'MursionPortal.Project.InviteToSchedule': '邀请预定',
    'MursionPortal.Project.InvitedToday': '立即邀请',
    'MursionPortal.Project.YourInvitationsText': '您的邀请函将连夜寄出。',
    'MursionPortal.Project.InviteToSchedule.Tooltip': '已发送该场景的邀请函。您明天将能够再次发送邀请函。',
    'MursionPortal.Project.InvitedToday.Tooltip': '尚未预定或取消/错过此场景的学员将收到一封预定电子邮件邀请函。',
    'MursionPortal.Project.InviteMessage.SentDate': '最后一次发送时间{sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': '请选择提供者',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': '全球限制',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': '假日限制',
    'ITManager.TechRestriction.Table.Provider': '提供者',
    'Session.Table.Column.Survey.HoverText': '如果启用，SIM将收到一个链接来完成对学员的反馈',
    'MursionPortal.Project.InviteMessage.InvitedBy': '由{user} - {role}邀请',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners}学员仍然需要预定',
    'Session.Table.Column.Value.AttendanceRequired': '需要考勤',
    'MursionPortal.Button.Schedule.Another': '再次预定',
    'MursionPortal.EulaAgreement.CodeOfConduct': '每一个Mursion模拟都有一个现场Simulation Specialist，在学习课程中为最多五个虚拟头像提供口语对话。Simulation Specialist的工作是提出对话挑战，使您这样的学员能够在每个场景中练习复杂的人类技能。在Mursion，我们认识到打造一个安全场所的重要性，员工可以在此大胆地练习可能有点紧张、微妙和复杂的对话。为确保学员和Simulation Specialist都能自信地参与，我们要求两组人都承诺遵守基本的行为准则。',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': '方法概述和行为准则',
    'MursionPortal.EulaAgreement.UserAgreement.Header': '用户协议',
    'MursionPortal.Import.BackToUser': '返回用户',
    'MursionPortal.Import.BackToTeam': '返回团队',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': '确保上传前您的数据准确无误。',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': '如果文件中有任何错误，它们将显示在步骤4的结果电子表格中。按照第4步中的说明，更正原始模板中的错误，并重新上传文件。',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': '您也可以在重新上传时将新用户与失败的用户一起导入。',
    'Mursion.Portal.Edit.Team.ReadyToUpload': '当文件准备好后，单击上传和验证',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': '正在上传和验证文件',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': '团队作业成功更新',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount}个用户已导入！',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': '错误！',
    'Mursion.Portal.Edit.Team.UploadSuccess': '所有用户都已成功添加到门户中。',
    'Mursion.Portal.Edit.Team.DoneButton': '完成',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': '下载成功 - 继续下一步',
    'Mursion.Portal.Edit.Team.DownloadError': '下载错误 - 请重试',
    'Mursion.Portal.Edit.Team.UploadFileAgain': '重新上传文件',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': '继续而不更正错误',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': '成功导入',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': '导入不成功',
    'Mursion.Portal.Import.User.HeaderTitle': '导入用户',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': '如果文件中有任何错误，它们将显示在步骤4的结果电子表格中。按照第4步中的说明，更正<strong>原始模板</strong>中的错误，并重新上传文件。',
    'Mursion.Portal.Import.User.ReadyToUpload': '当文件准备好并且格式正确时，请上传文件进行验证',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': '我们正在验证数据和导入用户。请在15分钟内或当您收到此过程已完成的提示时再回来查看。',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount}个用户已导入！',
    'Mursion.Portal.Import.User.ErrorContinueTitle': '继续而不更正错误！',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': '请根据默认名称重命名艺术包',
    'Mursion.Portal.Import.User.Instruction.Upload': '单击下面的<strong>下载Excel模板</strong>，打开下载的文件（<strong>client_internal_users.xlsx</strong>或<strong>client_external_users.xlsx</strong>）。',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>在模板中，电子邮件、角色和SSO ID字段是必填项 - 不要留空。在每个标题下正确添加用户信息。</strong>不要更改标题、模板结构或文件名，因为这可能导致导入失败。',
    'Mursion.Portal.Import.User.Instruction.NewUser': '在<strong>J-S或F-O列</strong>中添加用户应分配到的任何团队的名称（确保您的拼写与名称完全一致）。',
    'Mursion.Portal.Import.User.Instruction.Error': '当您输入所有用户后，把文件保存到桌面或您记得的地方，然后单击<strong>继续</strong>。',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': '如果文件中有任何错误，它们将显示在步骤4的结果电子表格中。按照说明更正<strong>原始模板</strong>中的错误，并重新上传文件。',
    'Mursion.Portal.Import.User.Instruction.UploadList': '如果您选择继续而不修复错误，<strong>下载失败的上传列表</strong>文件将仅在7天内可用。',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': '下载Excel模板',
    'Mursion.Portal.Import.User.UploadFileStatus': '正在上传文件',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': '我们正在验证数据和导入用户。此过程将在后台继续，您可以导航至另一个选项卡或窗口，并在任何时候返回此屏幕。此导入过程需要不到5分钟时间。',
    'Mursion.Portal.Import.User.FileStatus.validation': '正在导入用户',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': '最后一步：更正错误',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>在模板中，电子邮件和角色字段是必填项 – 不要留空</strong>。不要更改标题、模板结构或文件名，因为这可能导致导入失败。',
    'Mursion.Portal.Import.User.ReviewCorrect': '更正<strong>原始模板</strong>（<strong>client_internal_users.xlsx</strong>或<strong>client_external_users.xlsx</strong>）中的错误并重新上传文件。',
    'Mursion.Portal.Import.User.DownloadErrorButton': '下载错误',
    'Mursion.Portal.Edit.Team.EditTeamTitle': '编辑团队成员',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': '在每个标题下正确添加用户信息。在L至U列中，添加学员应分配到的任何团队的名称（即要添加或更新的团队），每个团队一列。如果需要，可以将一些团队栏留空。请确保此做法正确，因为这将覆盖现有的团队分配。',
    'Mursion.Portal.Edit.Team.Instruction.Process': '每次上传一个文件 - 每个文件的用户数限制为5,000个。',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>电子邮件和角色字段是必填项 - 不要留空。</strong>不要更改标题、模板结构或文件名，因为这可能导致导入失败。',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': '仅支持.xlsx格式的文件。',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': '我们正在验证数据和更新团队分配。此过程将在后台继续，您可以导航至另一个选项卡或窗口，并在任何时候返回此屏幕。此过程需要不到5分钟时间。',
    'Mursion.Portal.Edit.Team.FileStatus.validation': '正在更新团队成员',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': '您的文件用户数超过5,000个。请联系支持人员或上传多个用户数少于5,000的文件。',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>在模板中，电子邮件和角色字段是必填项 – 不要留空。</strong>不要更改标题、模板结构或文件名，因为这可能导致导入失败。',
    'Mursion.Portal.Edit.Team.DownloadFile': '更正原始模板文件（<strong>edit_learner_team_memberships.xlsx</strong>）中的错误 – 不要更改标题、模板结构或文件名，因为这可能导致导入失败。',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'SIM检查表URL',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': '必须是有效的URL',
    'MursionPortal.Label.SpeakingIsZero': '是0%',
    'MursionPortal.Label.InterruptionIsZero': '是0%',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': '通过选择预定您的Mursion课程，您同意Mursion的模拟授课模式，并确认您将维护和遵守Mursion行为准则。在<code>此处</code>阅读更多。',
    'Mursion.Portal.Import.User.InvalidFile': '仅上传csv或excel模板。',
    'Mursion.Portal.Import.User.MaxFileSize': '文件大小不得超过5MB',
    'Mursion.Portal.Import.User.Tooltip': '单击此处导入新用户或根据导出文件更新现有用户。',
    'Mursion.Portal.Edit.Team.Tooltip': '单击此处可以批量分配或重新分配团队成员资格。',
    'MursionPortal.Project.InviteLearnersToSchedule': '邀请学员预定',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': '按BookIt进行预定',
    'Dashboard.Report.SendReport': '发送报告',
    'Dashboard.Report.ScheduleReport': '计划报告',
    'Dashboard.Report.EditSchedule': '编辑计划',
    'Dashboard.Report.ViewSchedule': '查看计划',
    'Dashboard.Report.DeleteSchedule': '删除计划',
    'Dashboard.Report.Filter.Title': '选定的筛选条件',
    'Dashboard.Report.SelectReportFrequency.Label': '选择报告频率',
    'Dashboard.Report.SelectSessions.Label': '选择要包括的课程',
    'Dashboard.Report.SelectReportStartDate.Label': '选择报告开始日期',
    'Dashboard.Report.SelectReportStartDate.Tooltip': '如果您选择了每周接收报告，您的报告将在每周的选定日期送到。如果您选择了每月接收报告，它将在每月的选定日期送到。',
    'Dashboard.Report.SelectAccountOwner.Label': '选择account owner或facilitator',
    'Dashboard.Report.Dropdown.Select.Placeholder': '选择',
    'Dashboard.Report.ManuallyAddRecipients.Label': '手动添加接收者',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': '在此添加电子邮件',
    'Dashboard.Report.RecipientsBox.Label': '接收者',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': '接收者不能超过30个',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': '报告已发送至接收者。',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': '您的报告已计划！',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': '通过单击<strong>编辑计划</strong>，随时停止或编辑。',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': '更改已保存！',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': '通过单击<strong>编辑计划</strong>，随时停止报告。',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': '计划已删除',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': '确定要删除此报告的计划？',
    'Dashboard.Report.ConfirmationModal.Button': '好的！',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': '是，删除',
    'Dashboard.Report.EditSchedule.EditButton': '保存计划编辑',
    'Dashboard.Report.ReportFrequencyOption.Daily': '每日',
    'Dashboard.Report.ReportFrequencyOption.Weekly': '每周',
    'Dashboard.Report.ReportFrequencyOption.Monthly': '每月',
    'Dashboard.Report.ReportSessionOption.PreviousDay': '前一天的课程',
    'Dashboard.Report.ReportSessionOption.Previous7Days': '前7天的课程',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': '当天的课程',
    'Dashboard.Report.ReportSessionOption.Next7Days': '未来7天的课程',
    'Dashboard.Report.ReportSessionOption.MonthToDate': '本月至今的课程',
    'MursionPortal.Dashboard.UpcomingSession': '即将进行的课程',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': '前一整个月的课程',
    'Dashboard.Report.ReportSessionOption.Previous30Days': '前30天的课程',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': '场景排序',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': '单击按钮，查看和配置该项目中的场景顺序。',
    'Projects.ProjectInfo.Button.ScenarioSequence': '场景序列',
    'MursionPortal.Projects.DragAndDropModal.Text': '按优先顺序拖放场景',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': '拖动手柄图像',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': '关闭图标',
    'MursionPortal.Project.Scheduled.NextDate': '计划的{nextDate}',
    'MursionPortal.Project.Sequencing': '排序',
    'MursionPortal.Project.Checkbox.Sequencing': '启用排序',
    'MursionPortal.Dashboard.Join.Tooltip': '此课程可用后，您将能够加入',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': '此项目中尚未预定或取消/错过场景的学员将收到一封预定电子邮件邀请函。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': '键盘快捷键',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': '空格键',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': '选择或放弃该场景。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': '左右方向键或上下方向键',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': '左右拖动场景。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Esc键',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': '关闭场景序列模式',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Enter键',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': '关闭键盘快捷键工具提示',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': '打开键盘快捷键工具提示',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': '不允许重复输入',
    'Projects.ProjectInfo.EnableSessionTimeStep': '启用课程时间步长',
    'Projects.ProjectInfo.SessionTimeStepInfo': '如果未在项目级别设置课程时间步长值，将使用被许可人级别课程时间步长',
    'Session.Edit.Modal.JoinSimulation': '加入课程',
    'MursionPortal.Selector.RequestStatus': '请求状态',
    'MursionPortal.Dashboard.RetakeSimulation': '补考模拟',
    'MursionPortal.Dashboard.NextSimulationAssigned': '分配的下一个模拟',
    'MursionPortal.AriaLabel.SelectTime': '选择时间',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': '选择开始日期',
    'MursionPortal.InstructoScenarioTeamMapping.Header': '选择要映射的场景和团队',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': '此场景和团队已映射到一个不同的环境。',
    'MursionPortal.DataSummaryPoints.manualFulFilled': '手动填写',
    'MursionPortal.DataSummaryPoints.pendingRequest': '基于待定需求',
    'MursionPortal.DataSummaryPoints.declined': '拒绝的请求',
    'MursionPortal.DataSummaryPoints.avgFulfilled': '手动平均完成率',
    'MursionPortal.DataSummaryPoints.totalSwap': '交换总数',
    'MursionPortal.DataSummaryPoints.swapAccepted': '接受的交换',
    'MursionPortal.Dashboard.MissedSimulations': '错过的模拟',
    'MursionPortal.Dashboard.RescheduledSimulations': '改期模拟',
    'MursionPortal.Dashboard.BookedSimulations': '预订的模拟',
    'MursionPortal.Dashboard.CancelledSimulations': '已取消的模拟',
    'Dashboard.UserTable.Column.In': '在...中',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': '确定要禁用排序吗？这也将删除每个场景中的序列号。',
    'Projects.ProjectInfo.Disable.Sequencing': '禁用排序',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': '请重试。我们遇到了一个技术问题。',
    'SessionFlow.Text.Event.DeletedByUsername': '被{user}删除',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': '取消选中“启用课程时间步长”，或输入至少{minutes}分钟的时间步长值',
    'MursionPortal.AriaImage.Attribute.Previous': '上一步',
    'MursionPortal.AriaImage.Attribute.Notify': '通知',
    'MursionPortal.AriaImage.Attribute.Upload': '上传',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': '软件版本',
    'MursionPortal.Report.FilterHeading.DeliveryModes': '授课模式',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': '学员课程状态',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': '学员状态',
    'MursionPortal.Report.FilterHeading.SimulationStatus': '模拟状态',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': '这会将此项目的所有设置和配置复制到一个新项目',
    'MursionPortal.Project.DuplicateProjectBtnText': '复制到Magic项目',
    'MursionPortal.ScenarioInfo.Label.Until': '您必须在{date}之前将此事完成{completionRate}次。',
    'Clients.Modal.Button.Schedule.Tooltip.Text': '您需要先预定上一个场景',
    'MursionPortal.Scenario.Name': '场景名称',
    'MursionPortal.ScenarioCard.BackToProjects.Button': '返回项目',
    'MursionPortal.ScenarioCard.ViewMore.Button': '查看更多',
    'MursionPortal.ScenarioInfo.Documents': '场景文件',
    'MursionPortal.ScenarioInfo.Documents.File': '{extension}文件',
    'MursionPortal.ScenarioCard.ViewSessions': '查看课程',
    'MursionPortal.Label.ScenarioPreview': '场景预览',
    'MursionPortal.ScenarioInfo.AssignedTeams': '搜索或选择团队',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': '获认证的SIM Specialist',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': '添加Sim Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': '编辑图标',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': '删除SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': '搜索或选择SIM Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': '添加Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': '未激活的SIM Specialist',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': '搜索或选择未激活的SIM Specialist',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': '此场景已设置排序，学员尚未预定上一个场景。',
    'Clients.Modal.Label.FacilitatorView.Tooltip': '如果设置为客户级别，facilitator将能够看到Account Owner级别详细信息。如果设置为团队级别，facilitator只能看到他们被分配到的团队的信息。',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': '为学员提供课程自动分析。',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': '在Account Owner的仪表板上添加利用率报告。',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': '如果客户有自定义数据保留政策，则启用。',
    'MursionPortal.Label.SoftwareType.Tooltip': '“仅限网络”表示学员只能通过Mursion Magic这个基于浏览器的平台访问其课程。',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': '本项目的所有课程必须在项目时限内完成。',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': '取消选中以防止录像。',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': '学员可以选择不进行录像。如果取消选中，同时学员拒绝录像权限，他们将无法参加该课程。',
    'Projects.ProjectInfo.label.Tags.Tooltip': '标签是组织分组，以在客户级别快速找到类似项目。',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': '学员可以申请需求时段模拟的时间范围',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': '设置学员在计划时看到的计划选项的数量。如果设置为零，学员将能够立即预订一个开放的时间段。',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': '允许学员重新预定课程。请注意，除非选中“同一天”，否则学员不能在同一天预订课程。',
    'MursionPortal.Project.SameDay.Tooltip': '允许学员在同一日历日的晚些时候重新预定。',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': '启用私人SSO：',
    'Cleints.Modal.Label.DomainsLabel': '客户网域：',
    'Clients.Modal.Label.Logo': '客户标识：',
    'Projects.ProjectInfo.Label.CancellationDeadline': '取消截止期限',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': '在Mursion Social课程期间收集学员的考勤情况。',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': '录像数据保留（以天为单位）：',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': '录像数据保留政策：',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': '至少一个特殊字符',
    'MursionPortal.ErrorMessage.InvalidCaptcha': '无效的验证码。请重试。',
    'MursionPortal.Project.MissedAlert.TooLateJoin': '您来的有点晚，无法加入',
    'MursionPortal.Project.MissedAlert.DontWorry': '别担心！事情已发生。',
    'MursionPortal.Project.MissedAlert.ClickReschedule': '单击重新预定您的模拟。',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': '单击下面重新预定您的模拟。',
    'MursionPortal.Password.Strength.Tooweak': '密码强度太弱',
    'MursionPortal.Password.Strength.Weak': '密码强度为弱',
    'MursionPortal.Password.Strength.Medium': '密码强度为中',
    'MursionPortal.Password.Strength.Strong': '密码强度为强',
    'Dashboard.LeftPane.User.SchedulingInfo':'计划信息',
    'Dashboard.Button.ExportToEmail':'导出数据',
    'Filters.All':'全部',
    'Filters.More':'更多',
    'Filters.AllExcept':'全部，除了',
    'Filters.MoreClients':'更多客户',
    'Filters.AllClients':'所有客户',
    'Filters.Role':'角色',
    'Filters.TimeZone':'时区',
    'Dashboard.UserActivity.TableHeader':'用户活动数据',
    'MursionPortal.GenerateLearnerSurveyLink':'复制调查链接',
    'MursionPortal.SurveyLinkLabel':'您的调查链接已复制到剪贴板',
    'MursionPortal.Attention':'注意！',
    'MursionPortal.LinkCannotBeGenerated':'无法生成链接，因为该场景项目中缺少合同ID字段。',
    'Dashboard.UserTable.Column.UserName':'姓名',
    'Dashboard.UserTable.Column.DateInvited':'已邀请',
    'Dashboard.UserTable.Column.DateRegistered':'已注册',
    'Dashboard.UserTable.Column.DateArchived':'已存档',
    'Dashboard.UserTable.Column.DateLastLogin':'上次登录',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'软件',
    'Dashboard.UserTable.Column.SessionsScheduled':'模拟已安排',
    'Dashboard.UserTable.Column.SessionsCompleted':'模拟已完成',
    'Dashboard.UserTable.Column.SessionsMissed':'模拟已错过',
    'Dashboard.UserTable.Column.LateCancellations':'模拟已取消',
    'Dashboard.UserTable.Column.SessionsError':'模拟错误',
    'Session.Table.Column.ProjectId':'合同ID',
    'Session.Edit.Modal.Button.Leave':'取消我的课程',
    'Users.TableModal.SSOIDPlaceHolder':'SSO ID',
    'Users.Table.Modal.SSOID':'SSO ID',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'行业',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'软件',
    'Dashboard.SchedulingInfo.MainHeader':'计划信息',
    'Dashboard.SchedulingInfo.TableHeader':'计划信息数据',
    'RestorePassword.ResetPassword':'重置密码',
    'RestorePassword.EmailHint':'输入电子邮箱',
    'Dashboard.ProjectUtilization.MainHeader':'项目利用率',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Mursion Magic类型',
    'Dashboard.SessionLearners.MainHeader':'课程学员',
    'MursionPortal.Table.ScrollMessage':'向左滚动以查看其他列',
    'Dashboard.ProjectsActivity.MainHeader':'项目活动',
    'MursionPortal.Table.ColumnHeader.ProjectId':'合同ID',
    'MursionPortal.Button.ClearAll':'全部清除',
    'MursionPortal.Dashboard.Chart.Registration':'用户注册',
    'MursionPortal.Dashboard.Chart.InviteAll':'邀请所有人',
    'MursionPortal.Dashboard.Chart.Unregistered':'未注册',
    'MursionPortal.Dashboard.Chart.Registered':'已注册',
    'MursionPortal.Dashboard.Chart.Login':'登录',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'未登录',
    'MursionPortal.Dashboard.Chart.LoggedIn':'已登录',
    'MursionPortal.Dashboard.Chart.Scheduling':'用户已安排',
    'MursionPortal.Dashboard.Chart.NotScheduled':'未安排',
    'MursionPortal.Dashboard.Chart.Simulations':'模拟',
    'MursionPortal.Dashboard.Chart.LateCancellations':'延迟取消',
    'MursionPortal.Dashboard.Chart.Completed':'已完成',
    'MursionPortal.Dashboard.Chart.Errors':'错误',
    'MursionPortal.Dashboard.Chart.Scheduled':'已安排',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'已邀请的用户或已配置并登录的SSO用户。',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'已邀请但尚未登录的用户或已配置但尚未登录的SSO用户。',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'尚未安排模拟的已注册/已配置SSO用户',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'已安排至少一次模拟的已注册/已配置SSO用户',
    'MursionPortal.Label.ProjectId':'合同ID',
    'MursionPortal.Label.SoftwareType':'类型',
    'MursionPortal.Label.SharingOrDownloading':'不允许共享或下载',
    'Settings.SSO.Modal.EmployeeIDClaimName':'员工ID要求名称',
    'Settings.SSO.Modal.LocationClaimName':'位置要求名称',
    'Settings.SSO.Modal.DepartmentClaimName':'部门要求名称',
    'Settings.SSO.Modal.PhoneClaimName':'电话要求名称',
    'Settings.SSO.Modal.TitleClaimName':'职位要求名称',
    'Settings.SSO.Modal.TimezoneClaimName':'时区要求名称',
    'Settings.SSO.Modal.LanguageClaimName':'语言要求名称',
    'MursionPortal.ProjectId':'合同ID',
    'MursionPortal.AltText.MursionPortal':'Mursion门户',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'单击下面参加您的模拟',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'单击下面的按钮，以便通过Mursion桌面应用程序访问模拟',
    'MursionPortal.Message.OpenWebApplication':'您也可以在我们基于Web的应用程序Mursion Magic中访问模拟',
    'MursionPortal.Button.OpenMursionWeb':'启动Mursion Magic',
    'MursionPortal.Message.Or':'或',
    'MursionPortal.Message.PortalHeadingText':'EQ训练模拟',
    'ITManager.TechRestriction.Table.CreateButton':'添加限制',
    'ITManager.TechRestriction.EditDialog.TagName':'标签名称',
    'ITManager.TechRestriction.EditDialog.Title':'编辑技术限制',
    'ITManager.TechRestriction.CreateDialog.Title':'新技术限制',
    'MursionPortal.DateTimePicker.StartDate':'开始日期',
    'MursionPortal.DateTimePicker.EndDate':'结束日期',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'所选时间不可用。',
    'ITManager.TechRestriction.ViewDialog.Title':'查看技术限制',
    'MursionPortal.DateTimePicker.Timezone':'时区',
    'MursionPortal.Text.Team':'团队',
    'MursionPortal.Text.Industry':'行业',
    'MursionPortal.Text.Environment':'环境',
    'MursionPortal.Text.Avatar':'头像',
    'MursionPortal.Status.Capitalized.Upcoming':'即将进行',
    'MursionPortal.Status.Capitalized.Undefined':'未定义',
    'MursionPortal.Status.Capitalized.Orphan':'ORPHAN',
    'MursionPortal.Status.Capitalized.Waif':'WAIF',
    'MursionPortal.Status.Capitalized.Reserved':'已保留',
    'MursionPortal.Status.Capitalized.Booked':'已预订',
    'MursionPortal.Status.Capitalized.Pending':'待处理',
    'MursionPortal.Status.Capitalized.Running':'正在运行',
    'MursionPortal.Status.Capitalized.Missed':'已错过',
    'MursionPortal.Status.Capitalized.NeedsReview':'需要_检查',
    'MursionPortal.Status.Capitalized.Reviewed':'已检查',
    'MursionPortal.Status.Capitalized.Cancelled': '已取消',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'已取消',
    'MursionPortal.Status.Capitalized.Error':'错误',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'离线',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'R',
    'MursionPortal.Status.Abbreviation.Swap':'S',
    'MursionPortal.Status.Abbreviation.Prebooked':'PB',
    'MursionPortal.Status.Abbreviation.Booked':'B',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'M',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'找不到SSO配置。',
    'MursionPortal.Label.SelfReviewTracker':'自我检查跟踪器',
    'MursionPortal.ToggleButton.All':'全部',
    'MursionPortal.Label.PerformanceTracker':'性能跟踪器',
    'MursionPortal.ToggleButton.TurnTaking':'话轮转换',
    'MursionPortal.Tooltip.Collaborative':'这是您和头像在对话过程中话轮转换而不互相打断的时间度量。',
    'MursionPortal.Tooltip.Disruptive':'这是您或头像在对话过程中互相打断的时间度量。',
    'MursionPortal.Tooltip.Speaking':'这是您在对话过程中与头像交谈所花费的时间度量。',
    'MursionPortal.Tooltip.Listening':'这是您在对话过程中听头像说话所花费的时间度量。',
    'MursionPortal.Tooltip.MiScore':'您在对话过程中产生的社会效益。',
    'MursionPortal.Tooltip.Percentile':'在用户界面中的某个地方包括对其含义的描述',
    'MursionPortal.Label.MiScore':'MI分数',
    'MursionPortal.Label.Percentile':'百分位',
    'MursionPortal.Label.NotEnoughData':'数据不足',
    'MursionPortal.Label.Listening':'听',
    'MursionPortal.Label.Speaking':'说',
    'MursionPortal.Label.Collaborative':'合作',
    'MursionPortal.Label.Disruptive':'开拓性',
    'MursionPortal.ToggleButton.LocalLeaderboard':'本地排行榜',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'全球排行榜',
    'MursionPortal.ToggleButton.DeepInsights':'深入见解',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'目前正在处理课程分析数据。处理完成后，数据将显示在页面上。',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'课程分析为空或格式不支持。',
    'MursionPortal.Label.Positive':'正',
    'MursionPortal.Label.Negative':'负',
    'MursionPortal.Label.Neutral':'中立',
    'MursionPortal.Header.Tracking':'跟踪',
    'MursionPortal.Button.ReadMore':'了解更多',
    'MursionPortal.Button.Hide':'隐藏',
    'MursionPortal.Title.Interchange':'互换。自然对话和话轮转换，无干预或插话',
    'MursionPortal.Title.InterventionByYou':'受到您的干预。成功打断正在讲话的人，阻止他们继续',
    'MursionPortal.Title.InterventionByAvatars':'受到头像的干预。成功打断正在讲话的人，阻止他们继续',
    'MursionPortal.Title.InterjectionByYou':'受到您的干预。试图打断正在讲话的人，但没有成功',
    'MursionPortal.Title.InterjectionByAvatars':'受到头像的干预。试图打断正在讲话的人，但没有成功',
    'MursionPortal.Title.Pauses':'停顿。长于0.2秒且短于0.75秒',
    'MursionPortal.Label.VoiceSelf':'自己的声音',
    'MursionPortal.Label.VoiceOthers':'别人的声音',
    'MursionPortal.Label.Avatars':'头像',
    'MursionPortal.Label.You':'您',
    'MursionPortal.ColumnHeader.User':'用户',
    'MursionPortal.ColumnHeader.Percentile':'百分位',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'场景已完成',
    'MursionPortal.Message.LeaderboardsUpdated':'排行榜将在更多人完成模拟后更新。',
    'MursionPortal.Message.CheckBack':'请偶尔回来看一看您的排名！',
    'MursionPortal.Label.SimStart':'模拟开始',
    'MursionPortal.Label.SimEnd':'模拟结束',
    'MursionPortal.Tooltip.Summary.Speaking':'在整个对话过程中与头像交谈的时间',
    'MursionPortal.Tooltip.Summary.Listening':'在整个对话过程中听头像说话的时间',
    'MursionPortal.Capitalized.NotAvailable':'不适用',
    'MursionPortal.ColumnHeader.Trait':'特征',
    'MursionPortal.ColumnHeader.Me':'我',
    'MursionPortal.ColumnHeader.OthersAvg':'别人（平均）',
    'MursionPortal.TooltipText.Speaking':'您比{percentage}的人说得多。',
    'MursionPortal.TooltipText.Listening':'您比{percentage}的人听得多。',
    'MursionPortal.TooltipText.Collaborative':'您比{percentage}的人更具合作精神。',
    'MursionPortal.TooltipText.Disruptive':'您比{percentage}的人更具开拓性。',
    'MursionPortal.Label.FeedbackValue':'反馈值',
    'MursionPortal.Button.AnalyticsData':'分析数据',
    'MursionPortal.Button.RawAnalyticsData':'原始分析数据',
    'MursionPortal.Header.SocialEffectiveness':'社会效益',
    'MursionPortal.Label.Current':'当前',
    'MursionPortal.Header.ArticulationRate':'语速',
    'MursionPortal.Label.Average':'平均',
    'MursionPortal.Header.Conversation':'对话',
    'MursionPortal.Header.SpeakingListening':'说/听',
    'MursionPortal.Header.CumulativeTurnTaking':'累积话轮转换',
    'MursionPortal.Header.InstantaneousTurnTaking':'瞬时话轮转换',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'场景_模板',
    'MursionPortal.ColumnHeader.Provider':'提供者',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>索</code>引',
    'MursionPortal.Header.Score':'分数',
    'MursionPortal.visibilityHidden.Selected':'已选定',
    'MursionPortal.AriaLabel.CloseTechSupport':'关闭技术支持',
    'MursionPortal.Monitoring':'监控',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'点对点',
    'MursionPortal.Monitoring.Peer.NoUser':'请选择用户以查看详细信息',
    'MursionPortal.Monitoring.Sessions.Total':'课程总数',
    'MursionPortal.Monitoring.Sessions.Displayed':'ML3课程（已显示）',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'不是ML3课程',
    'MursionPortal.Monitoring.Card.StartTime':'开始时间',
    'MursionPortal.Monitoring.Notification.NewSession':'新课程已添加。',
    'MursionPortal.ClickRefresh':'单击“刷新”查看更改。',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'起草场景上标{statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'已完稿场景上标{statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'已开始场景上标{statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'已完成场景上标{statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'已存档场景上标{statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'将活动安排在{thisDate}',
    'Calendar.Button.CantScheduleON':'无法将活动安排在{thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton':'选择文件按钮',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'文件已上传',
    'MursionPortal.VisibilityHidden.EntityAdded':'实体已添加',
    'MursionPortal.VisibilityHidden.EntityRemoved':'实体已删除',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'站类型（办公室、家庭）',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'小时数（全职、兼职）',
    'MursionPortal.AriaLabel.ShowTechSupport':'显示技术支持',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'剩余时间',
    'MursionPortal.Monitoring.Card.OverTime':'加班',
    'MursionPortal.Title.DemandBasedSessionScheduling':'基于需求的课程安排',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'按需求安排',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'项目时区',
    'MursionPortal.CheckboxLabel.DemandTime':'需求时间',
    'MursionPortal.Label.DemandTimeStart':'需求时间开始',
    'MursionPortal.Label.DemandTimeEnd':'需求时间结束',
    'MursionPortal.Label.DemandWindow':'需求窗口',
    'MursionPortal.Project.Days':'天数',
    'MursionPortal.MyProfile.Hover.Profile':'个人资料{userName}',
    'Mursion.Portal.SessionUserStatus.Connected':'已连接',
    'Mursion.Portal.SessionUserStatus.Late':'迟到',
    'Mursion.Portal.SessionUserStatus.Immersing':'沉浸',
    'Mursion.Portal.SessionUserStatus.Left':'离开',
    'Mursion.Portal.SessionUserStatus.Declined':'已拒绝',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'谢谢！',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'我们正在努力根据您提供的时段完成您的模拟。敬请期待我们的电子邮件，为您提供更多详细信息。再见！',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'前往“我的仪表板”',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'安排一个课程',
    'MursionPortal.DemandBasedScheduling.Details':'详细信息',
    'MursionPortal.DemandBasedScheduling.Slot':'时段{count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'选择要设定的日期+时间',
    'MursionPortal.DemandBasedScheduling.Button.Set':'设定',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'清除',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'一旦您进行选择，就会加载场景详细信息',
    'MursionPortal.AriaDescribedby.StartDate':'开始日期：按问号键获取更改日期的键盘快捷方式。',
    'MursionPortal.AriaDescribedby.EndDate':'结束日期：按问号键获取更改日期的键盘快捷方式。',
    'MursionPortal.VisibilityHidden.Footer':'页脚',
    'MursionPortal.VisibilityHidden.Header':'页眉',
    'MursionPortal.AriaLabel.FullScreen':'全屏',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'显示在日历中的客户{clientName}活动',
    'MursionPortal.AriaLabel.SideNavigationToggle':'侧导航切换',
    'MursionPortal.AriaLabel.AddTags':'添加标签',
    'MursionPortal.VisibilityHidden.Navigation':'导航',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'按降序排序按钮',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'按升序排序按钮',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'已选定“项目”选项卡',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'已选定“团队”选项卡',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'已选定“课程”选项卡',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'已选定“用户”选项卡',
    'MursionPortal.Error.PageNotFound':'404找不到页面',
    'MursionPortal.Text.PageNotFound':'找不到页面。如果链接正确，请检查您是否拥有页面查看权限。',
    'MursionPortal.Text.Success':'成功！',
    'MursionPortal.Text.YouAreNowSignedIn':'您现在已登录Mursion模拟软件。',
    'MursionPortal.Text.PleaseReturnToDesktop':'请单击计算机任务栏(Windows)或程序坞(Mac)中“M”图标上的{linebreak}，以便返回桌面应用程序。',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'基于需求',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'收到的时段',
    'MursionPortal.Label.Past':'过去' ,
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'准备 ',
    'MursionPortal.Monitoring.SessionsSummary.Normal':'正常' ,
    'MursionPortal.Monitoring.SessionsSummary.Error':'错误' ,
    'MursionPortal.Monitoring.SessionsSummary.Issue':'问题' ,
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'未登录' ,
    'MursionPortal.Monitoring.Legend.Label':'图例' ,
    'MursionPortal.Monitoring.Legend.Dashboard':'仪表板' ,
    'MursionPortal.Label.SimSpecialist':'Sim专家' ,
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'已连接到对等网' ,
    'Requests.Table.CertifiedScenarios':'已认证场景',
    'Requests.Table.UncertifiedScenarios':'未认证场景',
    'Requests.Table.NoUpComingDemands':'您没有符合上述标准的按需请求。',
    'Requests.Table.DeclineReason':'拒绝 - 选择原因',
    'Requests.Table.UnAvailable':'没空',
    'Requests.Table.NotPrepared':'未准备',
    'Requests.Table.Other':'其他',
    'Requests.Table.Availability':'选择空闲时间',
    'Requests.Table.Declined':'已拒绝',
    'Requests.Table.SuccessfullySent':'请求已成功发送',
    'Requests.Table.RequestCertificate':'请求认证',
    'Requests.Table.DeclineReasonText':'拒绝原因',
    'Requests.Table.AvailabilitySlot':'空闲时段',
    'Requests.Table.DemandBased':'按需',
    'MursionPortal.Setting.Integrations':'集成',
    'MursionPortal.Setting.Integrations.Title':'请选择一个集成',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'平台配置',
    'MursionPortal.Button.AddConfigurations':'添加配置',
    'Settings.LTI.PlaceHolder.EnterConfigName':'输入配置名称',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'部署ID',
    'Settings.LTI.Table.ColumnHeader.Version':'版本',
    'Settings.LTI.Table.Caption':'LTI配置列表',
    'Settings.LTI.Table.Hover.ViewEditLti':'查看/编辑LTI',
    'Settings.LTI.Table.NoLtiConfigFound':'找不到LTI配置',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'删除配置',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'保存配置',
    'MursionPortal.Lti.NewLTIConfig':'新建LTI配置',
    'MursionPortal.Lti.CreateLTIConfig':'创建LTI配置',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'平台发行方标识符',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'公共密钥集端点',
    'Settings.LTI.Modal.DeploymentId':'部署ID',
    'Settings.LTI.Modal.RolesMapping':'角色映射',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'门户角色',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'LTI角色',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'必须指定LTI Id。',
    'MursionPortal.Setting.Integrations.LTIVersion':'LTI版本',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'LTI配置已成功创建。',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'时间段不可用 - 保留用于其他安排的模拟。',
    'Requests.Table.ViewAvailability':'查看空闲时间',
    'Settings.LTI.Modal.DeletionConfirmationText':'确实要删除该角色映射吗？',
    'Settings.ScenarioBank.Modal.InactiveCertification':'无效认证' ,
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'没有无效的Sim专家' ,
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'编辑无效认证' ,
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'没有无效认证' ,
    'MursionPortal.RequestStatus.All':'请求状态：全部',
    'MursionPortal.RequestStatus.Accepted':'请求状态：已接受',
    'MursionPortal.RequestStatus.Declined':'请求状态：已拒绝',
    'MursionPortal.RequestStatus.Pending':'请求状态：待处理',
    'Requests.Table.RequestCertification':'请求认证',
    'Requests.Table.RequestTime':'请求时间',
    'Requests.Table.ScheduleType':'计划类型',
    'Requests.Table.AcceptTime':'接受时间',
    'Requests.Table.Scheduling':'计划',
    'Requests.Table.FulfilledIn':'完成时间',
    'MursionPortal.DemandBasedScheduling.Timezone':'时区',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'前往您的个人资料设置以更改时区。',
    'MursionPortal.Results.Found':'找到{count}个结果',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'客户端用户',
    'MursonPortal.SchedulingType.Auto':'自动',
    'MursonPortal.SchedulingType.Manual':'手动',
    'MursionPortal.ScheduleTypeSelector.All':'计划：全部',
    'MursionPortal.ScheduleTypeSelector.Auto':'计划：自动',
    'MursionPortal.ScheduleTypeSelector.Manual':'计划：手动',
    'MursionPortal.ScheduleTypeSelector.NA':'计划：不适用',
    'Integrations.LTI.AppId':'应用程序Id',
    'Integrations.LTI.ConfigurationId':'配置Id',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'编辑LTI配置',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'确实要删除该LTI配置吗？',
    'MursionPortal.Rescheduling.SubmitPage.Message':'我们稍后会向您发送一封包含更多详细信息的电子邮件。再见！',
    'MursionPortal.Rescheduling.RescheduleSimulation':'重新安排模拟',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'抱歉，您无法进行模拟。选择适合您的日期和时间！',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'找到{count}个结果',
    'MursionPortal.Project.Rescheduling':'重新安排',
    'Session.Edit.Modal.MissedSession':'错过的课程',
    'Session.Edit.Modal.NoLongerRequired':'不再需要',
    'Session.Edit.Modal.TechDifficulties':'有技术上的困难',
    'Session.Edit.Modal.Other':'其他',
    'Session.Edit.Modal.CancelBecause':'是，取消课程，因为',
    'Session.Edit.Modal.CancelSimulation':'取消模拟',
    'MursionPortal.UserConsent.Heading':'录像权限',
    'MursionPortal.UserConsent.Title':'您想为自己的学习旅程录制此模拟课程吗？',
    'MursionPortal.UserConsent.Description':'如果您说“我同意”，Mursion软件将处理您的实时模拟，并对您的模拟课程进行录像，供您在Mursion软件门户中查看。 请注意，根据代表您授予Mursion软件许可的客户的要求，您录制的模拟课程的副本将与客户选择的教练、导师或审阅者共享。 除非您接受不同的隐私条款，否则所有审阅者都有合同义务遵守<code>Mursion的隐私政策</code>。除非适用法律要求，否则Mursion不会在未经您许可的情况下分发或披露您的录像。Mursion将使用您使用其软件时提供的匿名数据来改进其模拟技术。',
    'MursionPortal.UserConsent.IConsent':'我同意',
    'MursionPortal.UserConsent.IDoNotConsent':'我不同意',
    'MursionPortal.RecordingLinkPermisssion.Title':'录像权限',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'您是否要接收该课程的录像？',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'如果是这样，请检查您的电子邮件收件箱并验证您的电子邮件地址。如果有的话，明天您会收到一个链接。',
    'MursionPortal.PublicSession.PageTitle':'输入您的详细信息以加入模拟',
    'MursionPortal.PublicSession.Form.label.FirstName':'名字',
    'MursionPortal.PublicSession.Form.label.LastName':'姓氏' ,
    'MursionPortal.PublicSession.Form.label.Email':'电子邮件（可选）',
    'PublicSession.Form.Button.Join':'加入',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'您的名字',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'您的姓氏' ,
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'已经有一个帐户？',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'在此处登录',
    'Session.Edit.Modal.ToolTip.Google':'将活动添加到您的Google日历。 如果需要，请确认授权',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'单击以将活动下载到您的桌面并添加到您的日历应用程序',
    'Session.Edit.Modal.SimSpecialistAssigned':'分配的Sim专家',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'添加SIM专家',
    'Session.Edit.Modal.DemandBasedTimeSlots':'基于需求的时段',
    'Session.Edit.Modal.Reschedule':'重新安排',
    'Session.Edit.Modal.AddLearner':'添加学员',
    'MursionPortal.Label.SearchOrSelectLearners':'搜索或选择学员',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'重新安排',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'重新安排错过的课程',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'工作中的其他优先事项',
    'MursionPortal.Modal.Header.Sorry':'抱歉！',
    'MursionPortal.Modal.UnschedulableSessionMsg':'课程无法安排。',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'所选时间不再可用，请选择其他选项。',
    'MursionPortal.EmailVerificationPage.Success':'电子邮件验证成功！',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'您的电子邮件已验证成功',
    'MursionPortal.EmailVerificationPage.Error':'电子邮件验证失败！',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'出问题了，请重试以验证您的电子邮件',
    'Settings.Config.Heading.SplashPageAttendance':'考勤',
    // 'Clients.Modal.Label.ShowSplashPage':'未注册用户考勤/录像同意书',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'在ML3z/Meet模拟中收集未注册用户的考勤和录像同意书。',
    // 'Settings.Config.Invitation.ShowSplashPage':'在ML3z/Meet模拟中收集未注册用户的考勤和录像同意书。',
    'Settings.Config.External.Users':'（可以在客户配置中禁用）',
    'Settings.Config.Invitation.ExternalUser':'对于外部用户考勤',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'课程详情',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'选择一个项目',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'选择一个场景',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'搜索或选择SIM专家',
    'MursionPortal.Button.Exclamation.GotIt':'知道了！',
    'MursionPortal.Modal.UnavailableSlotMsg':'您选择的时间现在不可用。请选择一个新的时段。',
    'MursionPortal.SimAttendance.NoLongerAvailable':'您再也无法编辑此模拟的考勤。',
    'MursionPortal.SimAttendance.Midnight':'您必须在每天午夜前完成考勤。此模拟的考勤将默认为系统状态。如果发生任何异常情况，请联系支持人员。',
    'MursionPortal.SimAttendance.Instructions':'说明',
    'MursionPortal.SimAttendance.Attendee':'参加者',
    'MursionPortal.SimAttendance.Tooltip.Missed':'学员从未连接到模拟。',
    'MursionPortal.SimAttendance.Tooltip.Declined':'学员连接到模拟但拒绝参与，并在完成至少50%的模拟之前退出。',
    'MursionPortal.SimAttendance.Add.Attendee':'添加参加者',
    'Session.Edit.Modal.EditAttendance':'编辑考勤',
    'Session.Edit.Modal.CompleteAttendance':'完成考勤',
    'MursionPortal.SimAttendance.Late':'迟到',
    'MursionPortal.SimAttendance.Left':'离开',
    'MursionPortal.Instructions.First':'为每位参加者选择考勤状态（将鼠标悬停在状态上以了解更多信息）。如果未列出学员，则单击',
    'MursionPortal.Instructions.Second':'左下角的功能。',
    'MursionPortal.TableColumn.SimsAction':'Sim的操作',
    'Session.Edit.Modal.Label.SelectTrainer':'搜索或选择培训师',
    'MursionPortal.Project.DemandSlots':'需求时段',
    'MursionPortal.CompanyCard.InviteOperations':'邀请操作',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'新操作',
    'Settings.Config.Invitation.ShowContractFeature':'允许添加合同',
    'Settings.Config.Contract.Description':'（用于跟踪合同进度）',
    'MursionPortal.Contract.Edit':'编辑',
    'MursionPortal.Contract.ContractName':'合同名称',
    'MursionPortal.Contract.ContractID':'合同ID',
    'MursionPortal.Contract.ContractID.PlaceHolder':'输入Hubspot的合同ID',
    'MursionPortal.Contract.StartAndEndDates':'合同开始日期和结束日期',
    'MursionPortal.Contract.Notes.PlaceHolder':'输入仅向CSM和BDD显示的其他信息。',
    'MursionPortal.Contract.LineItem.Add':'添加合同行项',
    'MursionPortal.Contract.LineItem.Add.Instruction':'您可以添加多个模拟行项。 所有其他类型只能有一个行项。',
    'MursionPortal.Contract.LineItemType':'行项类型',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'选择行项类型',
    'MursionPortal.Contract.Quantity':'数量',
    'MursionPortal.Contract.Quantity.PlaceHolder':'输入数量',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'选择交付方式',
    'MursionPortal.Contract.SaveContract':'保存合同详细信息',
    'MursionPortal.Contract.Length.PlaceHolder':'选择长度',
    'MursionPortal.Contract.SowName.PlaceHolder':'输入合同名称',
    'MursionPortal.Client.Tab.Contracts':'合同',
    'MursionPortal.Client.Contracts':'客户合同',
    'MursionPortal.Client.Create.Contracts':'创建合同',
    'MursionPortal.Client.Contract.ID':'合同ID',
    'MursionPortal.NewUser.Optional':'（可选）',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'例如Google帐户或其他SSO提供者',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'请确认您的选择。',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'确定吗？ 要继续而不录像，请单击“确认”按钮。要更改您的录像同意书，请单击“取消”按钮。',
    'Settings.Config.Invitation.ShowSowFeature':'允许添加SOW',
    'Settings.Config.SOW.Description':'（用于跟踪SOW进度）',
    'MursionPortal.ScenarioBank.StandardizedScenario':'标准化场景',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'仅显示标准化场景',
    'Settings.Config.Invitation.SimWorkforceManagement':'SIM劳动力管理',
    'Settings.Config.SimWorkforceManagement.Description':'启用意味着报告在仪表板页面中可用，禁用意味着报告根本不存在。',
    'MursionPortal.ScenarioBank.BundleScenario':'绑定场景',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'选择此时段以自动确认您的场景预订！',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'SIM员工管理',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'计划',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'计划',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'计划数据',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'客户名单',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'客户名单',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'客户名单数据',
    'MursionPortal.Table.ColumnHeader.Time':'时间',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'所有安排的（课程）',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'空闲的SIM（人员）',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'空闲时间（小时）',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'所有按需请求（总计 - 所有3个请求）',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'时段1请求',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'时段2请求',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'时段3请求',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'待处理请求（课程）',
    'MursionPortal.Table.ColumnHeader.Auto':'自动',
    'MursionPortal.Table.ColumnHeader.Manual':'手动',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'能力指标',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'尚无sim员工管理数据',
    'MursionPortal.Table.ColumnHeader.SimCertified':'通过SIM认证',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'已安排（小时）',
    'MursionPortal.Table.ColumnHeader.Sessions':'课程',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'平均完成时间',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'时段1完成情况',
    'MursionPortal.Table.ColumnHeader.Cancelled':'已取消',
    'MursionPortal.Table.ColumnHeader.Missed':'已错过',
    'MursionPortal.Table.ColumnHeader.Reschedules':'重新安排',
    'MursionPortal.Table.ColumnHeader.Error':'错误',
    'MursionPortal.Table.ColumnHeader.Completed':'已完成',
    'MursionPortal.Table.ColumnHeader.Other':'其他',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'计划窗口',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'请求（需求）数',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'自动完成',
    'MursionPortal.Table.ColumnHeader.Pending':'待处理',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'时段2完成情况',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'时段3完成情况',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'未使用的空闲时间（小时）',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'输入的总空闲时间',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'平均小时/周数',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'空闲时间利用率',
    'MursionPortal.SupplyManagement.Above':'高于',
    'MursionPortal.SupplyManagement.Below':'低于',
    'MursionPortal.SupplyManagement.At':'为',
    'MyProfile.ProfServiceRole.Tooltiptext':'具有最高访问级别的用户',
    'MyProfile.AccManager.Tooltiptext':'创建场景、团队、添加用户并将团队分配给模拟',
    'MyProfile.SimSpecialist.Tooltiptext':'也称为“Sim”，此角色为学员执行模拟',
    'MyProfile.Buyer.Tooltiptext':'为组织管理Mursion项目的用户',
    'MyProfile.Facilitator.Tooltiptext':'协助实施模拟的模拟参与者',
    'MyProfile.Learner.Tooltiptext':'模拟参与者',
    'MyProfile.Operations.Tooltiptext':'能够添加和编辑合同的Mursion员工',
    'MyProfile.SimDesigner.Tooltiptext':'能够创建和编辑收藏场景的用户',
    'MursionPortal.Label.AssignedUserRoles':'已分配的用户角色',
    'MursionPortal.Label.AssignedUserRole':'已分配的用户角色',
    'Clients.Modal.Projects.Button.CreateFromBank':'从银行创建',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'创建新的”重复“客户，从收藏场景创建按钮',
    'MursionPortal.Label.ArtBundle':'艺术包',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'这是将在模拟中使用的环境和头像的3D艺术内容。此文件夹的内容显示在下面名为“艺术包”的部分中。',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'该场景没有分配的艺术包。',
    'MursionPortal.Label.ArtProject':'艺术项目',
    'MursionPortal.Label.UpperCase.ArtProject':'艺术项目',
    'Settings.Config.SimulationContent.ArtProjectFolder':'艺术项目S3文件夹',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'该场景没有分配的艺术项目',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'选择使用的艺术项目',
    'Projects.Message.NoArtProjectFound':'找不到艺术项目',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'已从该银行场景创建有效子场景。必须单独归档每一个场景。',
    'Settings.StandardizedScenarioBank.EditConfirmation':'确定要更新从该场景创建的所有现有场景吗？',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'附件更新将更改从该场景创建的所有现有场景。',
    'MursionPortal.Contract.Ending':'结束',
    'MursionPortal.ProjectContracts.DuplicateError':'您选择的合同与添加到此项目的另一个合同重合。请选择另一个合同或联系业务部以请求更改订单',
    'MursionPortal.ProjectContracts.DateErrorHeading':'没有这些项目日期的合同',
    'MursionPortal.ProjectContracts.DateError':'没有支持这些项目开始和结束日期的合同。如果没有有效的合同，则不得创建任何项目。要继续吗？',
    'MursionPortal.Contract.ViewDetails':'查看详情',
    'MursionPortal.Contract.PleaseAddContractToProject':'请将合同添加到项目',
    'MursionPortal.Contract.AddContractToProject':'将合同添加到项目',
    'MursionPortal.Contract.SelectContractToProject':'选择您希望添加到此项目的合同 - 请一次添加一个合同',
    'MursionPortal.Contract.AddSelectedContract':'添加选定的合同',
    'MursionPortal.Contract.AddContract':'添加合同',
    'MursionPortal.Contract.LineItemList':'行项列表',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'即时预订',
    'MursionPortal.DemandBasedScheduling.AllSlots':'提交时段',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'我们将在您选择的某个时段安排您的场景。',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'选择其中一个时段进行即时预订。',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'如果这些时段不起作用，请选择“提交时段”以提交适合您的时间。',
    'MursionPortal.AltText.CrossButton':'十字按钮图像',
    'MursionPortal.Title.BankScenario':'银行场景',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Sim名单',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Sim名单',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Sim名单数据',
    'MursionPortal.Table.ColumnHeader.SimFullName':'SIM全名',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'提供的语言（取得语言认证）',
    'MursionPortal.Table.ColumnHeader.Tier':'等级',
    'MursionPortal.Table.ColumnHeader.Timezone':'时区',
    'MursionPortal.Table.ColumnHeader.Scheduled':'已安排',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'预计总小时/周数',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'有效客户（计费）认证',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'无效认证',
    'MursionPortal.Table.ColumnHeader.Declined':'已拒绝',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'平均手动完成时间',
    'MursionPortal.Table.ColumnHeader.Queue':'队列',
    'MursionPortal.Table.ColumnHeader.Availability':'空闲时间',
    'MursionPortal.Filters.Tier':'等级',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'搜索等级',
    'MursionPortal.TierOptions.Tier1':'等级1',
    'MursionPortal.TierOptions.Tier2':'等级2',
    'MursionPortal.TierOptions.Tier3':'等级3',
    'MursionPortal.TierOptions.Tier4':'等级4',
    'MursionPortal.TierOptions.LeadSimulation':'首席模拟专家',
    'MursionPortal.Contract.BackToCLient':'返回到客户',
    'MursionPortal.DeliveryMode.Workshop':'研讨会',
    'MursionPortal.DeliveryMode.PrivatePractice':'私人事务所',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'请选择适合您的日期和时段。',
    'Dashboard.LeftPane.CategoryHeader.Performance':'利用率',
    'Dashboard.LeftPane.Performance.ContractOverview':'概述',
    'Dashboard.LeftPane.Performance.ContractSummaries':'合同摘要',
    'Dashboard.LeftPane.Performance.ContractProgress':'合同进度',
    'Dashboard.LeftPane.CategoryHeader.Learners':'学员',
    'Dashboard.LeftPane.Learners.LearnerActivity':'学员活动',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'学员模拟',
    'Dashboard.Total':'总项数',
    'Filters.SimulationStatus':'模拟状态',
    'Filters.SimulationStatusPlaceholder':'搜索模拟状态...',
    'Filters.LearnerStatus':'学员状态',
    'Filters.LearnerStatusPlaceHolderHint':'搜索学员状态...',
    'Filters.SimulationRecorded':'模拟已录像',
    'Filters.Standardized':'标准化',
    'Filters.Boolean.Yes':'是',
    'Filters.Boolean.No':'否',
    'Dashboard.UserTable.Column.Roles':'角色',
    'Dashboard.ContractSummaries.MainHeader':'合同摘要',
    'Dashboard.ContractOverview.MainHeader':'概述',
    'Dashboard.ContractProgress.MainHeader':'合同进度',
    'Dashboard.ContractProgress.Navigation.ContractItems':'合同项',
    'Dashboard.ContractProgress.Navigation.Projects':'项目',
    'Dashboard.ContractProgress.Navigation.Scenarios':'场景',
    'Dashboard.ContractProgress.Column.Item':'项',
    'Dashboard.ContractProgress.Column.Purchased':'已购买',
    'Dashboard.ContractProgress.Column.Remaining':'剩余',
    'Dashboard.ContractProgress.Column.Completed':'已完成',
    'Dashboard.ContractProgress.Column.Scheduled':'已安排',
    'Dashboard.ContractProgress.Column.LateCancellations':'延迟取消',
    'Dashboard.ContractProgress.Column.ProjectName':'项目名称',
    'Dashboard.ContractProgress.Column.StartDate':'开始日期',
    'Dashboard.ContractProgress.Column.EndDate':'结束日期',
    'Dashboard.ContractProgress.Column.Error':'错误',
    'Dashboard.ContractProgress.Column.ScenarioName':'场景名称',
    'Dashboard.ContractProgress.Column.Standardized':'标准化',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'分配的学员',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'完成的学员',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'%学员已完成',
    'Dashboard.ContractProgress.Column.Client':'客户',
    'Dashboard.ContractProgress.Column.ContractName':'合同名称',
    'Dashboard.ContractProgress.Column.ContractId':'合同ID',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'复制ID',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'%完成',
    'Dashboard.ContractProgress.Column.ErrorLearner':'错误（学员）',
    'Dashboard.ContractProgress.Column.LearnersInvited':'受邀学员',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'%学员已安排',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'客户名称',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'合同名称',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'合同完成天数百分比',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'完成的模拟占已购买模拟的百分比',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'完成和安排的模拟占已购买模拟的百分比',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'学员错过的模拟。',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'在连接的学员端以错误结束的模拟',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'受邀安排模拟的学员',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'已安排至少一次模拟的学员占受邀安排模拟学员的百分比',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'合同中约定的项',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'作为合同的一部分购买的模拟总数',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'已成功完成的模拟',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'已安排但尚未完成的即将进行的模拟（包括正在运行的模拟）',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'学员错过的模拟',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'学员、辅导员或帐户所有者延迟取消的模拟（在取消截止日期之后）或学员离开或拒绝的模拟',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'在连接的学员端以错误结束的模拟',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'项目名称',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'合同中规定的合同开始日期 ',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'合同中规定的合同结束日期。',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'场景名称',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'这是一个标准化场景',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'已分配场景的学员人数',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'已完成至少一次模拟的学员占受邀安排模拟学员的百分比',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'完成场景的各学员的百分比',
    'Dashboard.Learners.Column.Provider':'提供者',
    'Dashboard.Learners.Column.Client':'客户',
    'Dashboard.Learners.Column.LearnerName':'学员姓名',
    'Dashboard.Learners.Column.DateInvited':'邀请日期',
    'Dashboard.Learners.Column.LastLogin':'上次登录',
    'Dashboard.Learners.Column.NoAssignedTeams':'无分配的团队',
    'Dashboard.Learners.Column.NoAssignedScenarios':'无分配的场景',
    'Dashboard.Learners.Column.NoCompletedScenarios':'无分配的场景',
    'Dashboard.Learners.Column.Completed':'已完成',
    'Dashboard.Learners.Column.Scheduled':'已安排',
    'Dashboard.Learners.Column.LateCancelled':'延迟取消',
    'Dashboard.Learners.Column.EndedInError':'以错误结束',
    'Dashboard.Learners.Column.ProjectName':'项目名称',
    'Dashboard.Learners.Column.ScenarioName':'场景名称',
    'Dashboard.Learners.Column.ScheduledStartDate':'计划开始日期',
    'Dashboard.Learners.Column.ScheduledStartTime':'计划开始时间',
    'Dashboard.Learners.Column.ScheduledEndTime':'计划结束时间',
    'Dashboard.Learners.Column.SimulationStatus':'模拟状态',
    'Dashboard.Learners.Column.LearnerStatus':'学员状态',
    'Dashboard.Learners.Column.LearnerTimeSpent':'学员花费的时间',
    'Dashboard.Learners.Column.SimSpecialistName':'Sim专家姓名',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Sim专家状态',
    'Dashboard.Learners.Column.Report':'报告',
    'Dashboard.Learners.ColumnTooltip.Provider':'提供者/被许可人的姓名',
    'Dashboard.Learners.ColumnTooltip.Client':'客户名称', 
    'Dashboard.Learners.ColumnTooltip.LearnerName':'用户的姓氏和名字',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'首次邀请学员安排场景的日期',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'用户上次登录门户的日期',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'分配给学员的团队数量',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'分配给学员的不同场景的数量',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'学员完成的不同场景的数量',
    'Dashboard.Learners.ColumnTooltip.Completed':'已成功完成的模拟',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'已安排但尚未完成的即将进行的模拟（包括正在运行的模拟）',
    'Dashboard.Learners.ColumnTooltip.Missed':'学员错过的模拟',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'学员、辅导员或帐户所有者延迟取消的模拟（在取消截止日期之后）或模拟。',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'在连接的学员端以错误结束的模拟',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'项目名称',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'场景名称',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'此模拟计划开始的日期',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'此模拟计划开始的时间',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'此模拟计划结束的时间',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'模拟的最终状态',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'模拟的最终学员状态',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'学员在模拟中花费的分钟数。对于Zoom和Meet学员，这将根据SIM专家进行估计（如果已完成）',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'为提供模拟分配的模拟专家',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'模拟的最终SIM专家状态',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'模拟的唯一标识符',
    'Dashboard.Learners.ColumnTooltip.Report':'链接到模拟报告以查看（如果有）模拟的录像和分析',
    'Dashboard.SessionLearners.TableHeader':'课程学员数据',
    'MursionPortal.FilterHeading.Filter':'筛选',
    'MursionPortal.FilterHeading.Graph':'图表',
    'MursionPortal.Table.NoRecordsMessage':'找不到结果！',
    'MursionPortal.Filters.DeliveryMode':'交付方式',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'搜索交付方式...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'合同行项使用的总数。包括完成、错过或取消/重新安排的模拟（在截止日期之后）。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'已成功完成的模拟。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'学员错过的模拟。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'在连接的学员端以错误结束的模拟。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'学员、辅导员或帐户所有者延迟取消的模拟（在取消截止日期之后）或学员离开或拒绝的模拟。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'学员、辅导员或帐户所有者提前取消的模拟（在取消截止日期之前）。',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'已完成',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'错误',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'延迟取消',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'提前取消',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30m私人事务所',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60m私人事务所',
    'MursionPortal.Dashboard.Chart.Workshop1':'60m研讨会',
    'MursionPortal.Dashboard.Chart.Workshop2':'90m研讨会',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'已利用',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'模拟细分',
    'MursionPortal.SessionRecording':'课程录像',
    'MursionPortal.VoiceAnalytics':'语音分析',
    'MursionPortal.VideoTab':'视频',
    'MursionPortal.SessionInformation':'课程信息',
    'MursionPortal.SmartMatrix':'智能指标',
    'MursionPortal.DeliveryDetails':'交付详情',
    'MursionPortal.ConversationalFlow':'对话流',
    'MursionPortal.ConversationalFlowTooltip':'此图表描述您在对话期间的效率。 它由轮流输入组成，这些输入显示您参与建设性对话的能力。',
    'MursionPortal.BalancedTalkTime':'对话',
    'MursionPortal.BalancedFeedback':'均衡的反馈',
    'MursionPortal.ConversationalDisruptions':'中断',
    'MursionPortal.DisruptionsFeedback':'中断反馈',
    'MursionPortal.Heading.SessionSmartMetrics':'课程语音分析：',
    'Clients.Modal.Label.SmartMetrics':'语音分析：',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'课程语音分析：',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':'启用语音分析',
    'Filters.Contract':'合同',
    'Filters.Session': '课次',
    'Clients.Modal.Label.ShowSowReporting':'显示SOW报告：',
    'Clients.Modal.Label.ShowSurveyEnabled':'发送模拟后学员调查：',
    'Dashboard.SimulationTable.Column.Provider':'提供者',
    'Dashboard.SimulationTable.Column.Client':'客户',
    'Dashboard.SimulationTable.Column.Project':'项目',
    'Dashboard.SimulationTable.Column.ScenarioName':'场景名称',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'计划开始日期',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'计划开始时间',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'计划结束时间',
    'Dashboard.SimulationTable.Column.LearnersNames':'学员姓名',
    'Dashboard.SimulationTable.Column.LearnersEmails':'学员电子邮件',
    'Dashboard.SimulationTable.Column.LearnersStatus':'学员状态',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'学员花费的时间',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Sim专家姓名',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Sim专家状态',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'SIM专家花费的时间',
    'Dashboard.SimulationTable.Column.ScheduledBy':'安排者',
    'Dashboard.SimulationTable.Column.ScheduledAt':'安排的时间',
    'Dashboard.SimulationTable.Column.Recorded':'已录像',
    'Dashboard.SimulationTable.Column.ClientId':'客户 Id',
    'Dashboard.SimulationTable.Column.LearnerName':'学员姓名',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'复制学员电子邮件',
    'Dashboard.SimulationTable.Column.LearnerEmail':'学员电子邮件',
    'Dashboard.SimulationTable.Column.LearnerStatus':'学员状态',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'学员花费的时间',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'提供者/被许可人的姓名',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'客户名称',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'项目名称',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'场景名称',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'此模拟计划开始的日期',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'此模拟计划开始的时间',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'此模拟计划结束的时间',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'模拟的最终状态',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'模拟的注册和未注册学员姓名',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'模拟的注册和未注册学员电子邮件',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'模拟的最终学员状态',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'参加模拟的学员连接到模拟花费的时间',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Sim专家姓名',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'模拟的最终SIM专家状态',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'此模拟的实际时长（分钟）',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'安排模拟的用户的姓名',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'安排此模拟的日期和时间',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'表示模拟已录像还是已禁止。',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'模拟的注册和未注册学员姓名',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'模拟的最终学员状态',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'学员在模拟中花费的分钟数。对于Zoom和Meet学员，这将根据SIM专家进行估计（如果已完成）',
    'MursionPortal.Label.VideoTimeLineSlice':'视频时间轴片段',
    'MursionPortal.Label.SpeakingMoreThanSixty':'说话超过 60%',
    'MursionPortal.Label.SpeakingBetweenSixty':'说话介于 40% 和 60% 之间',
    'MursionPortal.Label.InterruptionMoreThanSixty':'中断超过 60%',
    'MursionPortal.Label.InterruptionBetweenSixty':'中断介于 40% 和 60% 之间',
    'MursionPortal.Label.SpeakingLessThanFourty':'说话不到 40%',
    'MursionPortal.Label.InterruptionLessThanFourty':'中断不到 40%',
    'MursionPortal.Label.Interruption.ByAvatar':'通过头像',
    'MursionPortal.Label.Interruption.ByLearner':'通过学员',
    'MursionPortal.Projects.NoContractsFound':'找不到任何合同。',
    'MursionPortal.Projects.ProjectContracts':'项目合同',
    'MursionPortal.Tooltip.AvatarInterrupt':'头像试图成功打断说话者。',
    'MursionPortal.Tooltip.LearnerInterrupt':'学员试图成功打断说话者。',
    'MursionPortal.Tooltip.Pauses':'停顿。长于0.2秒且短于0.75秒',
    'MursionPortal.Tooltip.Silence':'说话中断超过 10 秒',
    'MursionPortal.Tooltip.AvatarSpeaking':'头像试图成功说话。',
    'MursionPortal.Tooltip.LearnerSpeaking':'学员试图成功说话。',
    // 'MursionPortal.label.LearnerInterrupts':'学员打断',
    // 'MursionPortal.label.AvatarInterrupts':'头像打断',
    'MursionPortal.label.Pause':'停顿',
    'MursionPortal.Label.Silence':'无声',
    'MursionPortal.label.AvatarSpeaking':'头像说话',
    'MursionPortal.label.LearnerSpeaking':'学员说话',
    'MursionPortal.Contract.DuplicateLineItem':'此模拟长度和交付方式的行项已存在',
    'MursionPortal.Contract.DeleteLineItemIcon':'删除行项图标',
    'SessionReports.Label.ShareVideoLink':'分享视频链接',
    'MursionPortal.Title.Silence':'说话中断超过 10 秒',
    'Dashboard.LeftPane.User.UserActivity':'用户活动',
    'Dashboard.LeftPane.Performance.SelfService':'自助服务利用率',
    'MursionPortal.Placeholder.ClientValue':'选择一个客户',
    'Filters.Date.FromDate':'开始日期',
    'Filters.Date.ToDate':'结束日期',
    'Dashboard.UserActivity.MainHeader':'用户活动',
    'Dashboard.SelfService.MainHeader':'自助服务利用率',
    'Dashboard.SelfService.Column.SelfServiceProvider':'提供者',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'使用的总小时数',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'完成小时数',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'错过的小时数',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'出错的小时数',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'模拟完成',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'模拟已错过',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'模拟出错',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'提供者',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'已成功完成的模拟',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'完成小时数',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'学员错过的模拟',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'在连接的学员端以错误结束的模拟',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'模拟完成',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'模拟已错过',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'模拟出错',
    'Dashboard.Learners.Column.AssignedProjects':'分配的项目',
    'Dashboard.Learners.Column.AssignedScenarios':'分配的场景',
    'Dashboard.Learners.Column.ScheduledScenarios':'安排的场景',
    'Dashboard.Learners.Column.CompletedScenarios':'完成的场景',
    'Settings.Config.SessionReport.ShowSmartMetrics':'语音分析',
    'Settings.SSO.CopySsoDeepLink':'复制SSO深层链接',
    'Settings.SSO.CopySsoDeepLinkNotification':'您的SSO链接已复制到剪贴板',
    'MursionPortal.Dashboard.Chart.Assigned':'已分配',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'模拟按每个状态细分。',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'无可用数据',
    'Projects.ProjectInfo.Label.SurveyControl':'项目调查',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'启用模拟前学员调查',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'启用模拟后学员调查',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'启用的调查',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'模拟前学员调查',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'模拟后学员调查',
    'Settings.SSO.ModalBody.SelectDestination':'选择链接目标',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'“课程”选项卡',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'“日历”选项卡',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'“报告”选项卡',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'“仪表板”选项卡',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'场景卡',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'先选项目再选场景',
    'Settings.SSO.Button.CreateDeepLink':'创建SSO深层链接',
    'Settings.SSO.ModalHeader.DeepLink':'复制SSO深层链接',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'分配的场景数量',
    'Clients.Modal.Label.MursionSocialAttendance':'Mursion Social考勤：',
    'MursionPortal.CodeOfConduct.Text':'通过选择安排您的Mursion会议，您同意维护和遵守Mursion<code>行为准则</code>。',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'已完成所有可用场景的学员',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'尚无可供安排的场景的学员',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'有可用场景但尚未安排模拟的学员',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'已安排一个或多个模拟但尚未完成的学员',
    'MursionPortal.DateRangeSelector.Label.DateRange':'日期范围 ',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'已选择 - 请选择另一个时间段。',
    'MursionPortal.EndDate':'结束日期',
    'MursionPortal.Label.FilterSIMsBy':'SIM的筛选条件',
    'MursionPortal.LearnerSimSelector.Learner':'学员',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'选定的学员',
    'Dashboard.Filters.SimulationID':'模拟Id',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'Sim专家',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'已认证，但不可用',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'搜索或选择学员',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'搜索或选择SIM专家',
    'MursionPortal.Placeholder.ProvidersValue':'选择提供者',
    'MursionPortal.ProjectContract.Confirmation':'确定要从此项目中删除合同吗？',
    'MursionPortal.Scheduling.Label.DateTimeTab':'日期+时间',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'经认证的SIM不存在空闲时间。',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'未提供经认证的SIM',
    'MursionPortal.SessionFlow.Label.AreYouSure':'确定吗？',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'删除前，请确保：',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'您已确认用户请求有权删除（注意小组模拟和学员同意书）。',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'您创建了一个支持案例，说明谁在请求删除，并且您已在必要时与帐户所有者进行了核对。',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'删除录像',
    'MursionPortal.SessionFlow.Label.ProviderName':'提供者姓名',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'提醒：此操作无法撤消！',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'场景详情',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'课程开始和结束日期/时间',
    'MursionPortal.SessionFlow.Label.SupportCase':'支持案例#*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'您将要删除录像',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':'“检查您的音频和视频设置”',
    'MursionPortal.SessionNotStarted.DescriptionOne':'“等待时，您可以：”',
    'MursionPortal.SessionNotStarted.DescriptionTwo':'“此弹出窗口将在模拟前<code>{value}</code>分钟消失。如果当时页面未自动更新，请单击‘刷新。”',
    'MursionPortal.SessionNotStarted.Heading':'“有点早”',
    'MursionPortal.SessionNotStarted.PreSimTips':'"查看这些<code>模拟前提示</code>"',
    'MursionPortal.SessionNotStarted.ScenarioDetails':'"查看<code>场景详情</code>"',
    'MursionPortal.StartDate':'开始日期',
    'MursionPortal.Survey.Thankyou.Msg':'感谢您花时间参与本次调查。您的回复已被记录。',
    'MursionPortal.Title.Hours':'小时',
    'MursionPortal.Title.Minutes':'分钟',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner':'请说明您是否同意继续并录制您的模拟。如果您不同意，您可能无法继续模拟课程，软件将退出。如果您同意，您的模拟课程将被录像，每个录像将被安全存储12个月，届时将被存档。',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'确定吗？ 要继续而不录像，请单击“确认”按钮。要更改您的录像同意书，请单击“取消”按钮。',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'您可以在模拟开始前<code>{minutesBeforeJoin}</code>分钟加入。',
    'Session.Edit.Modal.LastEmailTrigger':'上次发送给学员的电子邮件：{sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'发送者：{userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg':'向学员发送重新安排电子邮件提醒！',
    'Settings.Config.Invitation.MursionSocialAttendance':'收集Mursion Social考勤。',
    'Settings.LTI.Modal.SendScore':'发送分数',
    'Dashboard.ContractProgress.Column.Provider':'提供者',
    'Settings.SSO.CopyDeepLinkNotification':'您的链接已复制到剪贴板',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'选择后将加载详细信息',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'首先选择正确的选项',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'先选择一个项目',
    'MursionPortal.Label.LearnerListening':'学员听讲',
    'MursionPortal.Label.InterruptionCount':'时区',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'视频时间轴',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'经认证且可用',
    'MursionPortal.Button.View':'查看',
    'MursionPortal.Dashboard.NewSimulationAssigned':'已分配的新模拟',
    'MursionPortal.Dashboard.MakeUpSimulation':'补充模拟',
    'MursionPortal.Dashboard.MissedSimulation':'错过的研讨会',
    'MursionPortal.Dashboard.CompletedSimulations':'完成的模拟',
    'MursionPortal.Dashboard.BackToDashboard':'返回仪表板',
    'MursionPortal.Dashboard.CancelledSimulation':'错过的研讨会',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'您在此期间安排了定期空闲时间。 您还想更新您的定期空闲时间吗？ ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'我只想更新{startDate}的空闲时间',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'我想从{date}开始更新我的定期空闲时间',
    'Mursion.Portal.Status.Connected':'已连接',
    'Mursion.Portal.Status.Late':'迟到',
    'Mursion.Portal.Status.Immersing':'投入',
    'Mursion.Portal.Status.Left':'离开',
    'Mursion.Portal.Status.Declined':'已拒绝',
    'Login.EditFile': '编辑{file}并保存以重新加载。',
    'Login.Hello': '您好',
    'Login.Title': '欢迎使用Mursion门户',
    'Login.Description': '设计、安排和管理您的虚拟现实模拟',
    'Login.PrivacyPolicy': '隐私政策',
    'Login.PrivacyDataInfo': '隐私数据信息',
    'Login.TechnicalSupport': '技术支持',
    'Login.SignIn': '登录',
    'Login.Next': '下一个',
    'Login.Email': '电子邮箱',
    'Login.Password': '密码',
    'Login.ForgotPassword': '忘记密码',
    'Login.Login': '登录',
    'Login.EmailHint': '输入你的电子邮箱',
    'Login.PasswordHint': '输入您的密码',
    'Login.NewPasswordHint': '输入新密码',
    'Login.RepeatPasswordHint': '再次输入新密码',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': '筛选条件：',
    'Dashboard.LeftPaneHeader': '仪表板',
    'Dashboard.LeftPane.CategoryHeader.Users': '用户',
    'Dashboard.LeftPane.CategoryHeader.Projects': '项目',
    'Dashboard.LeftPane.Project.ProjectActivity': '项目活动',
    'Dashboard.LeftPane.Project.ProjectUtilization': '项目利用率',
    'Dashboard.LeftPane.CategoryHeader.Sessions': '课次',
    'Dashboard.LeftPane.Session.SessionLearner': '课次学员',
    'Dashboard.LeftPane.CategoryHeader.Legacy': '旧版',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': '报告很快将通过{email}发送',
    'Dashboard.Button.ExportToEmail.HoverTitle': '导出数据',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': '导出不可用',
    'Dashboard.Next': '下一个',
    'Dashboard.Last': '最后一个',
    'Dashboard.Help': '帮助',
    'Dashboard.First': '第一个',
    'Dashboard.Back': '返回',
    'FiltersHeading': '筛选条件：',
    'Filters.Client': '客户',
    'Filters.ClientPlaceHolderHint': '搜索客户...',
    'Filters.Industries': '行业',
    'Filters.IndustriesPlaceHolderHint': '搜索行业...',
    'Filters.IndustriesDisabledTitle': '已禁用',
    'Filters.Project': '项目',
    'Filters.ProjectPlaceHolderHint': '搜索项目...',
    'Filters.ProjectDisabledTitle': '首先选择客户',
    'Filters.RoleDisabledTitle': '已禁用角色选择',
    'Filters.Scenario': '场景',
    'Filters.ScenarioPlaceHolderHint': '搜索场景...',
    'Filters.ScenarioDisabledTitle': '首先选择客户和项目',
    'Filters.Team': '团队',
    'Filters.TeamPlaceHolderHint': '搜索团队...',
    'Filters.TeamDisabledTitle': '首先选择客户和项目',
    'Filters.SessionStatus': '课次状态',
    'Filters.SessionStatusPlaceHolderHint': '搜索课次状态...',
    'Filters.RegistrationStatus': '注册状态',
    'Filters.RegistrationStatus.ShowAllRegistration': '注册状态：全部',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': '已接受注册',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': '未接受注册',
    'Filters.Date.LastMonth': '上个月',
    'Filters.Date.Yesterday': '昨天',
    'Filters.Date.Today': '今天',
    'Filters.Date.Tomorrow': '明天',
    'Filters.Date.NextMonth': '下个月',
    'Filters.Date.From': '自',
    'Filters.Date.To': '至',
    'Dashboard.UserActivityTable.HoverTitle': '查看用户摘要',
    'Dashboard.Export.ErrMsg': '导出条目的最大限制为{limit}。请修改您的请求，然后重试。',
    'MursionPortal.Close': '关闭',
    'Dashboard.UserTable.Column.Provider': '提供者',
    'Dashboard.UserTable.Column.Client': '客户',
    'Dashboard.UserTable.Column.ProfileId': '个人资料ID',
    'Dashboard.UserTable.Column.UserEmail': '用户电子邮箱',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': '复制电子邮箱',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': '重新发送邀请',
    'Dashboard.UserTable.Column.Scenarios': '场景',
    'Dashboard.UserTable.Column.Teams': '团队',
    'Dashboard.UserTable.Column.Industry': '行业',
    'Dashboard.UserTable.Column.Accepted': '已接受',
    'Session.Tab.Scheduled': '已安排',
    'Session.Tab.Active': '活动',
    'Session.Tab.Past': '过去',
    'Session.Tab.Cancelled': '已取消',
    'Session.Search': '搜索',
    'Session.Table.Column.Time': '时间',
    'Session.Table.Column.Client': '客户',
    'Session.Table.Column.Project': '项目',
    'Session.Table.Column.SoftwareVersion': '软件版本',
    'Session.Table.Column.Scenario': '场景',
    'Session.Table.Column.Trainees': '受训人员',
    'Session.Table.Column.Training': '培训',
    'Session.Table.Column.Session': '课次',
    'Session.Token.Label.Clients': '客户',
    'Session.Token.Label.Projects': '项目',
    'Session.Token.Label.Scenarios': '场景',
    'Session.Token.Label.Teams': '团队',
    'Session.Token.Label.SessionStatuses': '课次状态',
    'Session.Button.More': '更多',
    'Session.Time.NotRunning': '尚未运行',
    'Session.Time.Finished': '已完成',
    'Session.Time.Running': '现在正在运行',
    'Session.Table.Column.Learners': '学员',
    'Session.Table.Column.Team': '团队',
    'Session.Table.Column.Trainer': '培训师',
    'Session.Table.Column.SimSpecialist': '模拟专家',
    'Session.Table.Column.TimeUntil': '截止时间',
    'Session.Table.Column.Created': '已创建',
    'Session.Table.Column.Cancelled': '已取消',
    'Session.Table.Caption.SessionDetailsList': '课次详情列表',
    'Session.Export': '导出',
    'Session.Export.Message': '导出内容筛选条件',
    'Session.CalendarDate.Start': '自：',
    'Session.CalendarDate.End': '至：',
    'Session.Cancel': '取消',
    'Session.Confirm': '确认',
    'Session.Total': '总计',
    'Session.Filter.SessionNotFoundMessage': '找不到课次。',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': '您没有已安排的近期将进行的课次。',
    'Session.Filter.UpcomingSessionNotFoundMessage': '没有已安排的近期将进行的课次。',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': '您没有活动课次。',
    'Session.Filter.ActiveSessionNotFoundMessage': '无活动课次。',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': '您还未完成任何课次。',
    'Session.Filter.SessionReportNotFoundMessage': '无可用课次报告。',
    'Session.Filter.CompletedSessionNotFoundMessage': '未完成过任何课次。',
    'Session.Filter.CancelledSessionNotFoundMessage': '未取消过任何课次。',
    'Session.Edit.Modal.Table.Column.DateTime': '日期/时间',
    'Session.Edit.Modal.Table.Column.UserRole': '用户（角色）',
    'Session.Edit.Modal.Table.Column.Change': '更改',
    'Session.Edit.Modal.Table.Column.SessionStatus': '课次状态',
    'Session.Edit.Modal.Table.Column.Reason': '原因',
    'Session.Edit.Modal.AssetIndustry': '资产行业',
    'Session.Edit.Modal.SimulationEnvironment': '模拟环境',
    'Session.Edit.Modal.AvatarInSimulation': '模拟中的虚拟形象',
    'Session.Edit.Modal.Date': '日期',
    'Session.Edit.Modal.Time': '时间',
    'Session.Edit.Modal.DeliveryMode': '交付模式',
    'Session.Edit.Modal.Duration': '持续时间',
    'Session.Edit.Modal.Status': '状态',
    'Session.Edit.Modal.LearnersAttended': '出勤学员',
    'Session.Edit.Modal.ReasonForCancellation': '取消原因',
    'Session.Edit.Modal.MoreInfo': '更多信息...',
    'Session.Edit.Modal.HideAdditionalInfo': '隐藏更多信息...',
    'Session.Edit.Modal.AddToCalendar': '添加到日历',
    'Session.Edit.Modal.AttendSession': '出勤课次',
    'Session.Edit.Modal.PleaseWait': '请稍候...',
    'Session.Edit.Modal.CopySessionLink': '复制课次链接',
    'Session.Edit.Modal.SessionLink': '课次链接',
    'Session.Edit.Modal.SubmitReview': '提交评审',
    'Session.Edit.Modal.SessionRequestPending': '课次请求待处理',
    'Session.Edit.Modal.Back': '返回',
    'Session.Edit.Modal.Learners': '学员：',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': '只有未经验证的用户将会加入',
    'Session.Edit.Modal.Trainees': '受训人员：',
    'Session.Edit.Modal.Trainer': '培训师：',
    'Session.Edit.Modal.SimSpecialist': '模拟专家：',
    'Session.Edit.Modal.Notes': '注释：',
    'Session.Edit.Modal.ClientNotes': '客户备注：',
    'Session.Edit.Modal.Notes.EmptyMessage': '目前没有备注',
    'Session.Edit.Modal.Attendance': '考勤：',
    'Session.Edit.Modal.ShowHistory': '显示历史...',
    'Session.Edit.Modal.HideHistory': '隐藏历史...',
    'Session.Edit.Modal.LearnersAttendedLabel': '出勤学员：',
    'Session.Edit.Modal.SimulationContent': '模拟内容',
    'Session.Edit.Modal.SimulationContent.Name': '姓名：',
    'Session.Edit.Modal.SimulationContent.Description': '描述:',
    'Session.Edit.Modal.SimulationContent.Image': '图片：',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': '是否确定要还原此课次？',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': '是否确定要取消此培训？',
    'Session.Edit.Modal.CancelSession.ConfirmationText': '是否确定要取消此课次？',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': '是否确定要接受互换此课次？',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': '是否确定要接受此课次请求？',
    'Session.Edit.Modal.AssignSS.ConfirmationText': '是否确定要分配新的模拟专家？',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': '是否要更换模拟专家？',
    'Session.Edit.Modal.Button.RestoreSession': '还原课次',
    'Session.Edit.Modal.Button.CancelTraining': '取消培训',
    'Session.Edit.Modal.Button.CancelSession': '取消课次',
    'Session.Edit.Modal.Button.AcceptSwap': '接受互换',
    'Session.Edit.Modal.Button.Participate': '参与',
    'Session.Edit.Modal.Button.Swap': '互换',
    'Session.Edit.Modal.Button.UpdateSession': '更新课次',
    'Session.Edit.Modal.Button.UpdateTraining': '更新培训',
    'Session.Edit.Modal.Button.AcceptRequest': '接受申请',
    'Session.Edit.Modal.Button.AssignSS': '分配SS',
    'Session.Edit.Modal.Button.ChangeSS': '更改SS',
    'Session.Attendance.Table.Column.UserName': '用户名',
    'Session.Attendance.Table.Column.Time': '时间',
    'Session.Attendance.Table.Column.Status': '状态',
    'Session.ViewSessionReport': '查看课次报告',
    'Session.ViewEdit': '查看/编辑课次',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': '尚无历史',
    'Session.Export.ErrMsg': '导出条目的最大限制为{limit}。请修改您的请求，然后重试。',
    'Session.Table.Column.Action': '操作',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': '没有可供选择的培训师',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': '没有可供选择的模拟专家',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': '未选择培训师',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': '未选择模拟专家',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': '编辑',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': '搜索',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': '接受',
    'ConfirmationDialogue.AddReasonForChange': '请添加更改原因',
    'ConfirmationDialogue.Button.Submit': '提交',
    'ConfirmationDialogue.Button.Cancel': '取消',
    'ConfirmationDialogue.Button.Ok': '确定',
    'ConfirmationDialogue.ReasonForChange': '更改原因',
    'ConfirmButton.CancelSession.ConfirmCancellation': '确认取消',
    'ConfirmButton.CancelSession': '取消课次',
    'ConfirmButton.CancelSession.No': '否',
    'Pagination.Button.First': '第一个',
    'Pagination.Button.Back': '返回',
    'MursionPortal.UserName': '用户名',
    'MursionPortal.Email': '电子邮箱',
    'ClientUserTable.Column.Roles': '角色',
    'MursionPortal.Department': '部门',
    'MursionPortal.Location': '位置',
    'ClientUserTable.Column.Projects': '项目',
    'ClientUserTable.Column.Teams': '团队',
    'ClientUserTable.Column.Accepted': '已接受',
    'ClientUserTable.HoverTitle': '查看/编辑用户',
    'ClientUserTable.NoUserError': '还没有用户',
    'MursionPortal.FirstName': '名',
    'MursionPortal.LastName': '姓',
    'MursionPortal.Phone': '电话',
    'Users.TableModal.EmailPlaceHolder': 'example@domain.com',
    'Users.TableModal.Title': '职务',
    'Users.TableModal.LocationPlaceHolder': '城市，国家',
    'Users.TableModal.DepartmentPlaceHolder': '部门名称',
    'Users.TableModal.TitlePlaceHolder': '职称',
    'Users.Table.Modal.SettingsHeader': '辅导员/学员设置',
    'Users.Table.ModalHeader': '用户信息',
    'Users.Table.ModalButton.Cancel': '取消',
    'Users.Table.ModalButton.Delete': '删除',
    'Users.Table.ModalButton.Save': '保存',
    'Users.Table.ModalButton.SendInvite': '发送邀请',
    'Users.Table.ModalButton.Restore': '还原',
    'Users.Table.Modal.Teams.Text': '尚未添加任何团队',
    'Users.Table.Modal.Teams': '团队',
    'Users.Table.Modal.EmployeeIdentification': '员工标识',
    'Users.Table.Modal.Timezone': '时区',
    'Users.Table.Modal.Roles': '角色',
    'Users.Table.Modal.Settings.LearnerButton': '作为学员',
    'Users.Table.Modal.Settings.FacilitatorButton': '作为辅导员',
    'Users.Button.CreateUserButton': '创建用户',
    'Users.Button.Cancel': '取消',
    'Users.Button.ImportUsers': '导入用户',
    'Users.Button.Export': '导出',
    'Users.heading.ExportContentFilteredBy': '导出内容筛选条件',
    'Users.Button.Active': '活跃',
    'Users.Button.Archived': '已存档',
    'Users.Button.All': '全部',
    'Users.Text.Total': '总计',
    'Users.Heading.NewUsers': '新用户',
    'Users.Placeholder': 'example@domain.com',
    'Users.Button.AddUser': '添加用户',
    'Users.Button.CreateUser': '创建用户',
    'Users.Button.CreateUsers': '创建用户',
    'Users.Heading.ImportNewUsers': '导入新用户',
    'Users.Heading.ImportNewExternalUsers': '导入新的外部用户',
    'Users.Button.ImportUserList': '导入用户列表',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': '没有已分配的预定义团队用户',
    'Users.Text.AddCsv': '添加.csv或.xslx文件，文件中包含放入一列的电子邮件地址列表。',
    'Users.Text.ToImport': '要导入其他字段，请遵循以下准则：',
    'Users.Text.ListUser': '用户电子邮件必须通过逗号、分号、连字符或冒号与用户团队列表分开。',
    'Users.Text.ListTeams': '团队之间必须由逗号分隔。',
    'Users.Text.ListFields': '字段和每个团队必须在分开的单元格中指定。',
    'Users.Text.UserRole': '用户角色类型将默认为学员',
    'Users.Text.UnlessOtherwiseIndicated': '除非另有说明',
    'Users.Text.SampleImport': '您可以在此处下载示例导入文件：',
    'Users.DownloadCsvTemplate': '下载CSV模板',
    'Users.DownloadExcelTemplate': '下载Excel模板',
    'Users.Button.Update': '更新',
    'Users.Labels.Clients': '客户',
    'Users.Labels.Projects': '项目',
    'Users.Labels.Scenarios': '场景',
    'Users.Labels.Teams': '团队',
    'Users.Labels.Roles': '角色',
    'Users.Text.OnlyAccepted': '仅显示已接受：',
    'Users.Button.Confirm': '确认',
    'Users.Button.AccOwner': '帐户所有者',
    'Users.Button.Facilitator': '辅导员',
    'Users.Button.Learner': '学员',
    'Users.Button.ProfServices': '专业服务',
    'Users.Button.ProjectManager': '项目经理',
    'Users.Button.SimSpecialist': '模拟专家',
    'Users.Button.ITManager': 'IT经理',
    'Users.Button.BusinessAdmin': '业务管理员',
    'Users.Button.SupportAdmin': '支持管理员',
    'Users.Button.DevAdmin': '开发管理员',
    'Users.Button.ImportExternalUsers': '导入外部用户',
    'Users.Button.AssignLearners': '分配学员',
    'Users.CreateUser.Email.Placeholder': 'example@domain.com',
    'Users.Text.Instructions': '添加.csv或.xslx文件，其中包含具有以下字段的用户列表：电子邮箱、ssoid、名、姓、角色、团队。除团队外，所有字段均为必填字段。',
    'Users.Text.Guidelines': '请遵循以下准则：',
    'Users.Button.CreateUser.PencilIcon': '编辑用户详细信息',
    'Users.Button.CreateUser.TrashIcon': '删除用户',
    'Users.ExportButton.TextYes': '是',
    'Users.ExportButton.TextNo': '否',
    'Users.Button.OK': '确定',
    'Users.Button.AddProject': '添加项目',
    'Users.CreateUser.Label.AddToProjects': '添加到项目',
    'Users.UserInfo.Label.AddTeams': '添加团队',
    'Users.UserInfo.Label. SelectATeam': '选择一个团队',
    'Users.UserInfo.Label.AssignToTeam': '分配至团队：',
    'Users.CreateUser.Label.NoProjectsSpecified': '未指定项目',
    'Users.Option.SelectClient': '选择客户',
    'Users.Label.Client': '客户：',
    'Users.Label.RemoveTeam': '删除团队',
    'Users.Label.ChooseATeam': '选择团队成员',
    'Users.Project.Warning': '该客户没有项目。请选择另外一个。',
    'Users.Project.Warning.UnknownProject': '未知项目',
    'Users.ProjectContainer.Warning': '至少应添加一个项目',
    'Users.CompanyUsers.Button.Ok': '确定',
    'Users.CompanyUsers.Button.Accept': '接受',
    'Users.CreateUser.Label.Tier': '级别',
    'Users.CreateUser.Label.HireDate': '聘用日期',
    'Users.CreateUser.Label.StationType': '车站类型',
    'Users.CreateUser.ToggleButton.Home': '家庭',
    'Users.CreateUser.ToggleButton.Office': '办公室',
    'Users.CreateUser.Label.Hours': '小时',
    'Users.CreateUser.ToggleButton.FullTime': '全职',
    'Users.CreateUser.ToggleButton.PartTime': '兼职',
    'Users.CreateUser.Label.ZoomID': 'Zoom ID',
    'Users.CreateUser.ZoomID.Placeholder': 'Zoom ID',
    'Users.CreateUser.ZoomID.Hover': '请输入Zoom ID',
    'Users.CreateUser.Label.UserRoles': '用户角色',
    'Users.CreateUser.Label.ProjectManagerSettings': '项目经理设置',
    'Users.CreateUser.Label.SimSpecialistSettings': '模拟专家设置',
    'Users.CreateUser.Label.Certificates': '证书',
    'Users.CreateUser.Label.TurnOnEditCertificates': '打开证书编辑',
    'Users.CreateUser.Label.TurnOffEditCertificates': '关闭证书编辑',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': '尚未添加证书',
    'Users.CreateUser.Label.AddCertificate': '添加证书',
    'Users.CreateUser.Label.CertificateOwner': '证书所有者',
    'Users.CreateUser.Button.RemoveCertificate': '删除证书',
    'Users.TableHeading.Name': '名称',
    'Users.TableHeading.Email': '电子邮箱',
    'Users.TableHeading.Roles': '角色',
    'Users.TableHeading.Location': '位置',
    'Users.TableHeading.Accepted': '已接受',
    'Users.Table.Hover.ViewEditUser': '查看/编辑用户',
    'Users.Table.Hover.NoUsersYet': '还没有用户',
    'Users.CreateUser.Button.Client': '客户',
    'Users.CreateUser.Label.ChooseAClient': '选择客户',
    'Users.CreateUser.Label.SelectAClient': '选择客户',
    'Users.CreateUser.Label.ChooseACertificate': '选择证书',
    'Users.CreateUser.Label.SelectACertificate': '选择证书',
    'Users.CreateUser.Label.NoCertificatesToSelect': '没有可供选择的证书',
    'Users.CreateUser.Button.AddCertificate': '添加证书',
    'Users.CreateUser.PlaceHolder.SelectTier': '选择级别',
    'Users.RequiredField.Warning': '请检查是否正确填写了所有字段',
    'Users.RequiredField.ApplyChanges': '应用更改',
    'Users.Heading.NewUserInfo': '新用户信息',
    'Users.SearchPlaceHolderText': '输入用户姓名',
    'Users.SearchNoUserFoundError': '没有找到客户。',
    'Users.Table.Modal.Settings.AddTeamHeader': '添加团队',
    'Users.Table.Modal.Settings.ChooseTeamHeader': '选择团队成员',
    'Users.Table.Modal.Settings.AssignTeamHeader': '分配至团队：',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': '选择团队',
    'Users.Table.Modal.Settings.TeamLearner': '（学员）',
    'Users.Table.Modal.Settings.TeamFacilitator': '（辅导员）',
    'Users.Table.Modal.Settings.RemoveTeamOption': '删除团队',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': '打开团队编辑',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': '关闭团队编辑',
    'Users.Total': '总计',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': '确认存档用户',
    'Users.Table.Modal.ArchiveText': '是否确定要存档此用户？',
    'Users.Table.Modal.ArchiveUserButton': '存档用户',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': '确认还原用户',
    'Users.Table.Modal.RestoreText': '是否确定要还原此用户？',
    'Users.Table.Modal.RestoreUserButton': '还原用户',
    'Users.Table.Modal.CancelButton': '取消',
    'Users.Table.Modal.ArchiveButton': '存档',
    'Users.Table.Modal.RestoreButton': '还原',
    'Users.Table.Modal.SendInviteButton': '发送邀请',
    'Users.CreateUserButton.Modal.CreateUserButton': '创建用户',
    'Users.Table.Modal.UpdateUserButton': '更新用户',
    'Users.ViewUserOptions': '查看{companyName}用户',
    'Users.NewUserOptions': '新建{companyName}用户',
    'Users.EditUserOptions': '编辑{companyName}用户',
    'Reports.SessionListHeader.Completed': '已完成',
    'Reports.SessionListHeader.TotalSessionTime': '总课次时间,',
    'Reports.SessionListHeader.TotalActualTime': '实际总时间，',
    'Reports.SessionListHeader.SimulationCompletionTime': '模拟完成时间',
    'Reports.SessionListHeader.CantOpenReport': '请稍候，我们的分析引擎正在处理您的结果。您可能需要在几分钟后刷新此页面。',
    'Reports.SessionListHeader.NoReportsFound': '找不到报告。',
    'Reports.ReportCard.Button.ReviewSimulation': '评审模拟',
    'Reports.ReportCard.Strategies': '策略',
    'Reports.ReportCard.NotChecked': '未检查',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': '排序方式：',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': '没有找到作品项目。',
    'Dashboard.SchedulingInfoTable.Modal.Teams': '团队',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': '输入团队名称',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': '找不到团队',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': '团队名称：',
    'Dashboard.SchedulingInfoTable.Modal.Learners': '学员：',
    'Dashboard.SchedulingInfoTable.Modal.Projects': '项目：',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': '搜索行业...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': '已禁用',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': '使用的软件版本...',
    'Dashboard.SchedulingInfoTable.Modal.Total': '总计',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': '场景名称',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': '课次日期',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': '项目名称',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': '软件版本',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': '交付模式',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': '课次状态',
    'Dashboard.SchedulingInfoTable.Modal.Name': '名称',
    'Dashboard.SchedulingInfoTable.Modal.Status': '状态',
    'Dashboard.SchedulingInfoTable.Modal.Recording': '录制转到',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': '团队名称',
    'Dashboard.SchedulingInfoTable.Modal.Learner': '学员',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': '完成的课次',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': '交付模式',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': '项目名称',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': '软件版本',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': '时间表',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': '安排课次',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': '混合课次',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '“稍后”取消',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': '花费时间',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': '取消人',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': '取消原因',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': '已安排日期',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': '取消日期',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': '团队',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': '输入团队名称',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': '找不到团队',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': '团队名称',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': '场景',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': '输入场景名称',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': '找不到场景',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': '场景名称',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': '项目名称',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': '软件版本',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': '场景状态',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': '交付模式',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': '已安排课次',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': '找不到课次',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': '课次日期/时间',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': '已完成课次',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': '已错过课次',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '“稍后”取消',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': '取消人',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': '课次错误',
    'RestorePassword.EnterEmailAddress': '输入您的电子邮件地址，然后单击“还原”按钮——将会向您发送一封包含重置密码链接的电子邮件。',
    'RestorePassword.Email': '电子邮箱',
    'RestorePassword.Button.Cancel': '取消',
    'RestorePassword.Button.Restore': '还原',
    'RestorePassword.Modal.IfValidEmailAddressEntered': '如果输入了有效的电子邮件地址，则将带有重置说明的电子邮件发送到关联的帐户。如果问题仍然存在，请联系技术支持部门。',
    'RestorePassword.Modal.Button.GotIt': '知道了',
    'Trainings.Heading.AllTrainingSessions': '培训课次',
    'Requests.Table.Swap': '互换',
    'Requests.Table.SchedulingRequests': '正在安排请求',
    'Requests.Total': '总计',
    'Requests.Table.NoSessionsFound': '找不到课次。',
    'Requests.Table.NoUpcomingSwapSessions': '没有近期将互换的课次。',
    'Requests.Table.NoUpcomingRequests': '您没有近期将提出的请求。',
    'GetTimeToEvents.Day': '天',
    'GetTimeToEvents.Days': '天',
    'GetTimeToEvents.Minute': '分钟',
    'GetTimeToEvents.Minutes': '分钟',
    'GetTimeToEvents.Hour': '小时',
    'GetTimeToEvents.Hours': '小时',
    'GetTimeToEvents.LessThanOneMinute': '不到1分钟',
    'SessionReports.SessionInformation': '课次信息',
    'SessionReports.SessionInformation.Hover.Information': '信息',
    'SessionReports.SmartMetrics': '智能指标',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': '分享视频链接',
    'SessionReports.Button.BackToReports': '返回报告',
    'SessionReports.Button.BackToTrainings': '返回培训',
    'SessionReports.Button.HideInfo': '隐藏信息',
    'SessionReports.Button.ShowInfo': '显示信息',
    'SessionReports.Button.SendVideoLink': '发送视频链接',
    'SessionReports.TryAgain': '请重试',
    'SessionReports.Mode': '模式：',
    'SessionReports.SessionLength': '课次长度：',
    'SessionReports.LearnersAttended': '出勤学员：',
    'SessionReports.ScenarioTimeline': '场景时间表：',
    'SessionReports.ActualSessionTime': '实际课次时间：',
    'SessionReports.ActualSimulationTime': '实际模拟时间：',
    'SessionReports.LastUserStatus': '上此用户状态：',
    'SessionReports.SessionStatus': '课次状态：',
    'SessionReports.Delivery': '交付',
    'SessionReports.SendVideoLink.Modal.AddEmails': '添加电子邮箱',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': '添加电子邮箱 ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'example@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': '取消',
    'SessionReports.SendVideoLink.Modal.Button.Send': '发送',
    'SessionReports.FactSessionInfo': '实况课次信息',
    'SessionReports.Text.NoTraineeParticipatedInSession': '没有受训人员参与课次',
    'SessionReports.Text.NoLearnerParticipatedInSession': '没有学员参与课次',
    'SessionReports.ToolTip.TrackingYours': '最重要的是您对结果或策略项的感知要与虚拟形象保持一致。这表明您正在跟踪自己对虚拟形象的影响，从而帮助提高社交效率。此信息包含在下表中。',
    'SessionReports.ToolTip.TrackingLearners': '最重要的是学员对结果或策略项的感知要与虚拟形象保持一致。这表明学员正在跟踪自己对虚拟形象的影响，从而帮助提高社交效率。此信息包含在下表中。',
    'SessionReports.ToolTip.AvatarsHeaderYours': '此列表明您模拟性能的虚拟形象悟性。对勾表明虚拟形象感觉您已成功实现结果，而叉号则表示有改进的余地。',
    'SessionReports.ToolTip.AvatarsHeaderLearners': '此列表明虚拟人物对学员模拟表现的感知。对勾表明虚拟形象感觉学员已成功实现结果，而叉号则表示有改进的余地。',
    'SessionReports.ToolTip.SelfHeaderYours': '此列表明您对自己模拟表现的评估。对勾表明您感觉自己很成功，而叉号则表明有改进的余地。',
    'SessionReports.ToolTip.SelfHeaderLearners': '此列表明学员对自己模拟表现的评估。对勾表明表明学员感觉自己很成功，而叉号则表示有改进的余地。',
    'SessionReports.ToolTip.VideoLink': '您可以与同学分享模拟表现视频的链接。只需在弹出框中输入他们的电子邮箱即可。请注意，将不会和您的同学分享您的分析结果。',
    'SessionReports.ToolTip.SmartMetrics': '智能指标是通过使用高级算法生成的。观察这些计算机生成的指标，以查看它们在各种模拟过程中是否对您的总体跟踪有任何影响。',
    'SessionReports.ToolTip.MiScore': 'Mursion指数（MI）评分是您在此次对话过程中社交效果的指标。它使用各种数据进行计算，包括您的谈话交替方式及其对虚拟形象的影响。尝试评估您对虚拟形象的影响，并以不同的方式进行对话，以了解可以如何对您的MI得分产生积极影响。',
    'SessionReports.ToolTip.ListeningSpeakingTime': '这是一个显示您在对话中花费的讲话时间与倾听时间之比的指标。',
    'SessionReports.ToolTip.Emotions': '情绪（测试版）：我们目前正在研究可让我们解释您的声音模式以确定整体情绪的算法。观察这些数据，了解您的人声模式随着时间的推移是否具有正面、负面或中性的含义。更重要的是，观察您对虚拟形象人声模式的影响。',
    'SessionReports.ToolTip.TurnTracking': '交替谈话是阐明您与虚拟形象之间协作性质/社交有效性的一组指标。',
    'SessionReports.ToolTip.Pauses': '暂停是您谈话过程中流畅度的间接指标。',
    'SessionReports.ToolTip.ArticulationRate': '这是对您传递连续信息集的速度的一种度量。',
    'SessionReports.TableHeader.Outcome': '结果',
    'SessionReports.TableHeader.Self': '自助',
    'SessionReports.TableHeader.Avatars': '虚拟形象',
    'SessionReports.TableHeader.Strategies': '策略',
    'MursionPortal.Button.Cancel': '取消',
    'Settings.Config.Heading.Industries': '行业',
    'Settings.Config.Button.New': '新建',
    'Settings.Config.Note': '注意：',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': '配置设置是全局的。更改将应用于所有客户和用户。',
    'Settings.Config.Heading.SimSpecialist': '模拟专家级别',
    'Settings.Config.Heading.Scheduling': '正在安排',
    'Settings.Config.Heading.Scheduling.Description': '（模拟专家）',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': '全职模拟专家的最大时数',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': '兼职模拟专家的最大时数',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': '模拟专家的最大连续时数',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': '模拟专家所需休息的时间',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': '模拟专家的未来可用性窗口',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': '模拟专家轮班时间',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': '每天可用时段最大值',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': '每天可用时段最小值',
    'Settings.Config.Scheduling.AvailabilityTimeStep': '可用时间步长',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'SS时间表应提前多长时间确定',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': '何时发出“请求未完成”提醒',
    'Settings.Config.Scheduling.FirstEmailReminder': '何时发送第一封电子邮件提醒',
    'Settings.Config.Scheduling.SecondEmailReminder': '何时发送第二封电子邮件提醒',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': '何时通知没有指定学员的预定课次',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': '何时发出有关未完成的课次互换请求的提醒',
    'Settings.Config.Scheduling.NextSessionReminder': '何时发送下一个课次提醒，取决于课次创建和已安排课次开始时间之间的时间',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': '保留的可用性过期时',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': '将课次视为“已错过”时的开始时间后时间量',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': '将课次视为“已错过”时的结束时间后时间量',
    'Settings.Config.Scheduling.RejoinSessionAfterError': '发生错误后参与者必须重新加入课次的时间',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': '课次时间安排禁用的时间量',
    'Settings.Config.Scheduling.SessionTimeStep': '课次时间步长',
    'Settings.Config.Heading.ProjectAndScenario': '项目和场景',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': '项目时间表的最长持续时间',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': '项目时间表持续时间最小值',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': '场景时间表持续时间最大值',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': '场景时间表持续时间最小值',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': '课次长度最小值',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': '课次长度最大值',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': '模拟长度最小值',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': '课次长度步长',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': '远程课次学员数最小值',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': '远程课次学员数最大值',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': '本地鱼缸课次学员数最小值',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': '本地鱼缸课次学员数最大值',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': '小组课次中学员数最小值',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': '小组课次学员数最大值',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': '允许使用的场景版本',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': '默认场景版本',
    'Settings.Config.Heading.CompanyEvent': '公司活动',
    'Settings.Config.CompanyEvent.MaximumEventDuration': '活动持续时间最大值',
    'Settings.Config.CompanyEvent.MinimumEventDuration': '活动持续时间最小值',
    'Settings.Config.CompanyEvent.EventTimeStep': '活动时间步长',
    'Settings.Config.Heading.SessionReport': '课次报告',
    'Settings.Config.SessionReport.ShowSessionReport': '显示课次报告',
    'Settings.Config.Heading.FeedbackProvider': '反馈提供者',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'ZenDesk代码段密钥',
    'Settings.Config.Heading.SimulationContent': '模拟内容',
    'Settings.Config.SimulationContent.SimulationContentFolder': '模拟内容S3文件夹',
    'Settings.Config.Heading.Invitation': '邀请函',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': '用户注册邀请函何时过期',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': '何时提醒未注册用户接受注册邀请函',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': '注册邀请函过期日期',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': '编辑行业',
    'Settings.Config.NewButton.Modal.NewIndustry': '新行业',
    'Settings.Modal.Placeholder.IndustryName': '行业名称',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': '更新行业',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': '创建行业',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': '还原行业',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': '存档行业',
    'MursionPortal.Modal.Heading.Name': '名称',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': '是否确定要还原此行业？',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': '是否确定要存档此行业？',
    'Settings.Config.Description.PerWeek': '（每周）',
    'Settings.Config.Description.PerDay': '（每天）',
    'Settings.Config.Description.BeforeBreak': '（休息前）',
    'Settings.Config.Description.Minutes': '（分钟）',
    'Settings.Config.Description.Days': '（天）',
    'Settings.Config.Description.Hours': '（小时）',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': '更新层次',
    'Settings.Config.TierButton.Modal.Heading.EditTier': '编辑层次',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': '层次名称',
    'MursionPortal.Button.Archive': '存档',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': '确认存档',
    'Settings.Config': '配置',
    'Settings.ScenarioBank': '场景库',
    'Settings.Environments': '环境（ML2）',
    'Settings.Avatars': '虚拟形象（ML2）',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': '没有可用的设置',
    'MursionPortal.Error.SomethingWentWrong': '抱歉，出錯了！',
    'MursionPortal.Error.ReloadingPage': '重新加载页面。错误已报告。',
    'MursionPortal.Error.WorkingHard': '我们正在努力尽快修复它。',
    'MursionPortal.Error.SupportAvailable': '周一至周五提供支持',
    'MursionPortal.Error.TechnicalAssistance': '如果您需要技术帮助，请联系Mursion支持',
    'MursionPortal.At': '在',
    'MursionPortal.Or': '或',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': '环境名称',
    'Settings.Environment.Modal.Description': '描述',
    'Settings.Environment.Modal.Industry': '行业',
    'Settings.Environment.Modal.Picture': '图片',
    'MursionPortal.Button.Delete': '删除',
    'Settings.Environment.Modal.Button.UploadFile': '上传文件',
    'Settings.Environment.Button.CreateEnvironment': '创建环境',
    'Settings.Environment.ModalHeader.NewEnvironment': '新环境',
    'Settings.Environment.ModalHeader.Environment': '环境',
    'Settings.Environment.ModalHeader.Archived': '（已存档）',
    'Settings.Environment.ModalHeader.EditEnvironment': '编辑环境',
    'Settings.Environment.Modal.Button.CreateEnvironment': '创建环境',
    'Settings.Environment.Modal.Button.UpdateEnvironment': '更新环境',
    'Settings.Environment.Modal.ArchiveEnvironmentText': '是否确定要存档此环境？',
    'Settings.Environment.Modal.RestoreEnvironmentText': '是否确定要还原此环境？',
    'MursionPortal.Button.Restore': '还原',
    'MursionPortal.Total': '总计',
    'MursionPortal.Text.Total': '总计',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': '导出数据',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': '导出不可用',
    'MursionPortal.Button.ExportToEmail': '导出到电子邮件',
    'MursionPortal.WarningMessage.RequestNotImplemented': '数据请求尚未实施',
    'MursionPortal.Table.ColumnHeader.Client': '客户',
    'MursionPortal.Table.ColumnHeader.Provider': '提供者',
    'MursionPortal.Table.ColumnHeader.Project': '项目',
    'MursionPortal.Table.ColumnHeader.Scenario': '场景',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': '软件版本',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': '交付模式',
    'MursionPortal.Table.ColumnHeader.SessionStart': '课次开始',
    'MursionPortal.Table.ColumnHeader.SessionEnd': '课次结束',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': '学员状态',
    'MursionPortal.Table.ColumnHeader.SessionStatus': '课次状态',
    'MursionPortal.Table.ColumnHeader.LearnerName': '学员名称',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': '学员电子邮箱',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': '复制电子邮箱',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': '用户电子邮箱',
    'MursionPortal.Table.ColumnHeader.SessionId': '课次ID',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': '复制课次ID',
    'MursionPortal.Table.ColumnHeader.SessionReport': '课次报告',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': '模拟专家名称',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': '学员所花时间',
    'MursionPortal.Table.HoverTitle.SessionSummary': '课次摘要',
    'MursionPortal.EmptyMessage.NoSessionsYet': '尚无课次',
    'MursionPortal.Button.ViewReport': '查看报告',
    'Mursion.Portal.Status.Requested': '已请求',
    'Mursion.Portal.Status.Pending': '待处理',
    'Mursion.Portal.Status.PendingSwap': '待处理（互换）',
    'Mursion.Portal.Status.PreBooked': '预先预定',
    'Mursion.Portal.Status.Booked': '已预订',
    'Mursion.Portal.Status.Active': '活动',
    'Mursion.Portal.Status.Complete': '完成',
    'Mursion.Portal.Status.Error': '错误',
    'Mursion.Portal.Status.Missed': '已错过',
    'Mursion.Portal.Status.NeedsReview': '需要评审',
    'Mursion.Portal.Status.Cancelled': '已取消',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': '搜索...',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': '名称',
    'MursionPortal.DeliveryMode': '交付模式',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': '课次规模',
    'MursionPortal.SessionLength': '课次长度',
    'Settings.ScenarioBank.Table.ViewEditScenario': '查看/编辑场景',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': '尚无场景',
    'MursionPortal.Capitalized.Total': '总计',
    'Settings.ScenarioBank.Form.NewScenario': '新场景',
    'Settings.ScenarioBank.Form.Scenario': '场景',
    'Settings.ScenarioBank.Form.EditScenario': '编辑场景',
    'Settings.ScenarioBank.Form.Save': '保存',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': '是否确定要存档此场景？',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': '是否确定要还原此场景？',
    'Settings.ScenarioBank.Button.CreateScenario': '创建场景 ',
    'MursionPortal.ShortLabel.Minutes': '分钟',
    'Settings.ScenarioBank.Modal.Scenarios': '场景',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': '首先完成项目信息',
    'Settings.ScenarioBank.Modal.ScenarioName': '场景名称',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': '输入场景名称',
    'Settings.ScenarioBank.Modal.Outcome': '结果',
    'Settings.ScenarioBank.Modal.Strategies': '策略',
    'Settings.ScenarioBank.Modal.Attachments': '附件',
    'Settings.ScenarioBank.Modal.SoftwareVersion': '软件版本',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': '我们已有针对此场景的课次',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': '选择交付模式',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': '至少应该{minutes}分钟',
    'Settings.ScenarioBank.Modal.SelectSessionLength': '选择课次长度',
    'Settings.ScenarioBank.Modal.SessionSize': '课次规模',
    'Settings.ScenarioBank.Modal.Learner': '学员',
    'Settings.ScenarioBank.Modal.Learners': '学员',
    'Settings.ScenarioBank.Modal.SelectSessionSize': '选择课次规模',
    'MursionPortal.SimulationContent': '模拟内容',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': '这是将在模拟中使用的环境和虚拟形象的3D美术内容。此文件夹的内容显示在下面标题为“内容包”的部分中。',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': '此场景没有分配的模拟内容',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': '选择要使用的模拟内容',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': '场景结果',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': '输入结果',
    'Settings.ScenarioBank.Modal.Description': '描述',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': '未指定描述',
    'Settings.ScenarioBank.Modal.ScenarioDescription': '场景描述',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': '超出了允许的字符数限制（{maxLength}个字符）',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': '场景策略',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': '输入一个项目',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': '将文件拖放到此处，或单击以选择文件',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': '您上传的文件已达到最大可上传数量{maxFiles}。',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': '已上传的文件，',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': '已认证的模拟专家',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': '未认证的模拟专家',
    'Settings.ScenarioBank.Modal.Button.Add': '添加 ',
    'Settings.ScenarioBank.Modal.Edit': '编辑',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': '向场景添加附件',
    'MursionPortal.Cancel': '取消',
    'MursionPortal.OK': '确定',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': '支持以下文件格式：',
    'Settings.ScenarioBank.Modal.Images': '图像',
    'Settings.ScenarioBank.Modal.Videos': '视频',
    'Settings.ScenarioBank.Modal.Audios': '音频',
    'Settings.ScenarioBank.Modal.Documents': '文档',
    'Settings.ScenarioBank.Modal.New': '（新）',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': '档案大小不得超过{size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': '验证模拟专家',
    'MursionPortal.Confirm': '确认',
    'MursionPortal.Delete': '删除',
    'Settings.ScenarioBank.Modal.Archived': '（已存档）',
    'Settings.ScenarioBank.Modal.Restore': '还原',
    'Settings.ScenarioBank.Modal.ConfirmArchive': '确认存档',
    'Settings.ScenarioBank.Modal.Archive': '存档',
    'Settings.ScenarioBank.Modal.ConfirmRestore': '确认还原用户',
    'MursionPortal.PleaseWait': '请稍候...',
    'MursionPortal.Loading': '加载中...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': '不支持{extensions}文件类型',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': '不支持{extensions}文件类型',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': '文件{fileNames}超出了大小限制',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': '文件{fileNames}超出了大小限制',
    'MursionPortal.Label.Total': '总计',
    'MursionPortal.Button.HoverTitle.ExportData': '导出数据',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': '导出不可用',
    'MursionPortal.Heading.Filters': '筛选条件：',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': '仅可计费',
    'MursionPortal.Filters.SoftwareVersion': '软件版本',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': '搜索版本...',
    'MursionPortal.Filters.Status': '状态',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': '搜索状态…',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': '提供者',
    'Dashboard.ProjectsTable.ColumnHeader.Status': '状态',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': '开始于',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': '结束于',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': '可计费',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': '取消窗口',
    'MursionPortal.Table.ColumnHeader.Scenarios': '场景',
    'MursionPortal.Table.ColumnHeader.Teams': '团队',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': '已安排课次',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': '已完成课次',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': '已错过课次',
    'MursionPortal.Table.ColumnHeader.LateCancellations': '稍后取消',
    'MursionPortal.Table.ColumnHeader.Learners': '学员',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': '课次已被录制',
    'MursionPortal.Table.ColumnHeader.SessionsError': '课次错误',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': '签约时间',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': '使用时间',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': '剩余时间',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': '完成时间',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': '错过时间',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': '查看/编辑项目',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': '还没有项目',
    'MursionPortal.Button.Clear': '清除',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': '数据请求尚未实施',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': '已选择{formattedTotal}个中的{formattedSelectedCount}个',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': '团队',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': '输入团队名称',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': '找不到团队',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': '学员数',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': '场景',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': '输入场景名称',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': '找不到场景',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': '名称',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': '状态',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': '交付模式',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': '学员',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': '输入学员名称',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': '找不到学员',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': '已安排课次',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': '找不到课次',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': '场景名称',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': '课次日期/时间',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': '已完成课次',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': '课次错误',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': '课次已被录制',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': '已错过课次',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': '“稍后”取消',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': '取消人',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': '已安排日期',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': '取消日期',
    'MursionPortal.ProjectStatus.Active': '活动',
    'MursionPortal.ProjectStatus.Completed': '已完成',
    'MursionPortal.ProjectStatus.Archived': '已存档',
    'Settings.Avatars.Modal.Name': '名称',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': '虚拟形象名称',
    'Settings.Avatars.Modal.Description': '描述',
    'MursionPortal.Name': '名称',
    'MursionPortal.Description': '描述',
    'MursionPortal.Environments': '环境',
    'Settings.Avatars.TableHover.ViewEditAvatar': '查看/编辑虚拟形象',
    'Settings.Avatars.NoAvatarText': '还没有虚拟形象',
    'Settings.Avatars.ModalHeader.NewAvatar': '新虚拟形象',
    'Settings.Avatars.ModalHeader.Avatar': '虚拟形象',
    'Settings.Avatars.ModalHeader.EditAvatar': '编辑虚拟形象',
    'Settings.Avatars.Modal.Button.CreateAvatar': '创建虚拟形象',
    'Settings.Avatars.Modal.Button.UpdateAvatar': '更新虚拟形象',
    'Settings.Avatars.Modal.ArchiveAvatarText': '是否确定要存档此虚拟形象？',
    'Settings.Avatars.Modal.RestoreAvatarText': '是否确定要还原此虚拟形象？',
    'Settings.Avatars.Modal.Button.Edit': '编辑',
    'Settings.Avatars.Modal.EditButton.Accept': '接受',
    'Settings.Avatars.Modal.NoSelectedEnvironments': '没有选定的环境',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': '没有为所选行业设置任何环境。',
    'Settings.Avatars.Button.CreateAvatar': '创建虚拟形象',
    'MursionPortal.Dashboard': '仪表板',
    'MursionPortal.Tutorial': '教程',
    'MursionPortal.Eula': '最终用户许可协议',
    'MursionPortal.Companies': '公司',
    'MursionPortal.SessionFlow': '课次流程',
    'MursionPortal.SessionReport': '课次报告',
    'MursionPortal.AssetSync': '资产同步',
    'MursionPortal.Users': '用户',
    'MursionPortal.AppDownload': '下载应用',
    'MursionPortal.Calendar': '日历',
    'MursionPortal.Clients': '客户',
    'MursionPortal.Sessions': '课次',
    'MursionPortal.Trainings': '培训',
    'MursionPortal.Requests': '请求',
    'MursionPortal.Settings': '设置',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': '项目',
    'MursionPortal.Teams': '团队',
    'MursionPortal.Reports': '报告',
    'MursionPortal.Downloads': '下载',
    'MursionPortal.Upcoming': '近期将进行',
    'MursionPortal.Auth': 'Oauth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': '最多可以选择{max}个项目',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': '最多可以选择{max}个项目',
    'MursionPortal.Industry': '行业',
    'Settings.Environment.TableHover.ViewEditEnvironment': '查看/编辑环境',
    'Settings.Environment.NoEnvironmentText': '还没有环境',
    'Projects.Button.CreateProject': '创建项目',
    'Projects.ListHeader.CreationDate': '创建日期',
    'Projects.ListHeader.ProjectManagers': '项目经理',
    'MursionPortal.Capitalized.Active': '活跃',
    'MursionPortal.Capitalized.Completed': '已完成',
    'MursionPortal.Capitalized.Archived': '已存档',
    'MursionPortal.Capitalized.All': '全部',
    'Projects.ProjectCard.BackToClient': '返回客户',
    'Projects.ProjectCard.NoProjectsFound': '找不到项目',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': '输入项目名称',
    'Projects.ProjectCard.ProjectsList': '项目列表',
    'MursionPortal.Capitalized.Project': '项目',
    'MursionPortal.Scenarios': '场景',
    'Projects.ScenarioList.ThereAreNoScenarios': '还没有场景。',
    'Projects.ScenarioList.NoScenariosFound': '找不到场景。',
    'Projects.ScenarioList.Button.Draft': '草稿',
    'MursionPortal.Finalized': '已定稿',
    'MursionPortal.Started': '已开始',
    'MursionPortal.Finished': '已完成',
    'MursionPortal.Archived': '已存档',
    'MursionPortal.EnterScenarioName': '输入场景名称',
    'Projects.AllProjects': '所有项目',
    'MursionPortal.Label.LowerCase.Archived': '已存档',
    'MursionPortal.Label.Active': '活动',
    'MursionPortal.Label.Completed': '已完成',
    'Projects.Tab.Basic': '基本',
    'MursionPortal.Heading.ProjectName': '项目名称',
    'MursionPortal.Label.Created': '已创建',
    'MursionPortal.Label.Status': '状态',
    'MursionPortal.Label.Learners': '学员',
    'MursionPortal.Label.LearnersCount': '{count}名学员',
    'Projects.Tab.NoLearners': '没有学员',
    'MursionPortal.Label.SoftwareVersion': '软件版本',
    'Projects.ProjectInfo.Label.Non-Billable': '非计费',
    'Projects.ProjectInfo.Label.Contracted': '已签约',
    'Projects.ProjectInfo.Label.CancellationWindow': '取消窗口',
    'MursionPortal.Header.Recording': '正在录制',
    'Projects.ProjectInfo.Checkbox.AllowRecording': '允许录制',
    'MursionPortal.Label.VideoRecording': '录像',
    'MursionPortal.Label.Allowed': '允许',
    'MursionPortal.Label.Disallowed': '不允许',
    'MursionPortal.Label.RecordedVideosGoBackTo': '录制的视频返回到：',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': '录制权限取决于学员',
    'MursionPortal.Label.LiesWithLearner': '欺骗学员',
    'Projects.ProjectInfo.Label.Enabled': '已启用',
    'Projects.ProjectInfo.Label.Disabled': '已禁用',
    'MursionPortal.Heading.ProjectManagers': '项目经理',
    'MursionPortal.Heading.Tags': '标签',
    'MursionPortal.Button.AreYouSureEditProject': '是否确定要编辑此项目？',
    'MursionPortal.Button.SaveProjectInfo': '保存项目信息',
    'Projects.Tab.Utilization': '利用率',
    'MursionPortal.Label.Loading': '加载中...',
    'Projects.ProjectInfo.Label.NoProjectManagers': '无项目经理',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': '未选择项目经理',
    'MursionPortal.Label.ProjectManagers': '项目经理',
    'MursionPortal.Label.RecordingPermission': '录制权限：',
    'Projects.Utilization.Label.UtilizedSessions': '利用的课次：',
    'Projects.Utilization.Label.UtilizedMissedSessions': '利用的已错过课次：',
    'Projects.Utilization.Label.UtilizedCancelledSessions': '利用的已取消课次：',
    'Projects.Utilization.Label.UtilizedDuration': '利用持续时间：',
    'Projects.Utilization.Label.RemainingDuration': '剩余持续时间：',
    'MursionPortal.ScenarioListItem.Label.Timeline': '时间表',
    'MursionPortal.ScenarioListItem.Label.SessionLength': '课次长度',
    'MursionPortal.ScenarioListItem.Label.SessionSize': '课次规模',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': '交付模式',
    'MursionPortal.ScenarioCard.BackToProject': '返回项目',
    'MursionPortal.ScenarioCard.NoScenariosFound': '找不到场景',
    'MursionPortal.Placeholder.EnterScenarioName': '输入场景名称',
    'MursionPortal.ScenarioCard.Label.ScenariosList': '场景',
    'MursionPortal.ScenarioCard.Header.Scenario': '场景',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': '选择待安排活动类型',
    'MursionPortal.Label.Session': '课次',
    'MursionPortal.Label.Training': '培训',
    'MursionPortal.Button.Confirm': '确认',
    'MursionPortal.Button.Continue': '继续',
    'MursionPortal.Button.Schedule': '时间表',
    'MursionPortal.Label.Draft': '草稿',
    'MursionPortal.Label.Finalized': '已定稿',
    'MursionPortal.Label.Started': '已开始',
    'MursionPortal.Label.Finished': '已完成',
    'MursionPortal.Label.Archived': '已存档',
    'MursionPortal.Label.Cancel': '取消',
    'MursionPortal.Label.Edit': '编辑',
    'MursionPortal.Label.CancelEdit': '取消编辑',
    'MursionPortal.Label.EditScenario': '编辑场景',
    'MursionPortal.Label.EditScenarioName': '编辑场景名称',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': '应用名称变更',
    'MursionPortal.ScenarioCard.Label.Apply': '应用',
    'MursionPortal.ScenarioCard.Tab.Info': '信息',
    'MursionPortal.ScenarioCard.Tab.Learners': '学员',
    'MursionPortal.ScenarioCard.Tab.Sessions': '课次',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': '是否确定要存档此场景？所有相关数据也将被存档。',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': '存档场景',
    'MursionPortal.Label.Delivery': '交付',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': '已存档场景',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': '如果您确定了场景，则只能编辑团队和时间表',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': '确定场景',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': '保存场景',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': '创建计划',
    'MursionPortal.ScenarioInfo.Label.SimLength': '模拟长度',
    'MursionPortal.Header.Outcome': '结果',
    'MursionPortal.Header.Strategies': '策略',
    'MursionPortal.Header.Attachments': '附件',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': '保存场景',
    'MursionPortal.Label.ContentBundle': '内容包',
    'MursionPortal.ScenarioInfo.Button.ShowLess': '显示更少',
    'MursionPortal.ScenarioInfo.Button.ShowMore': '显示更多',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': '当前场景没有资产设置。',
    'MursionPortal.Teams.Label.NoSelectedTeams': '没有已选择的团队',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': '客户没有分配的团队',
    'MursionPortal.Label.Teams': '团队',
    'MursionPortal.Label.NoTeamsSpecified': '未指定团队',
    'MursionPortal.Label.Capitalized.Draft': '草稿',
    'MursionPortal.Label.Capitalized.Finalized': '已定稿',
    'MursionPortal.Label.Capitalized.Started': '已开始',
    'MursionPortal.Label.Capitalized.Finished': '已完成',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': '场景仍处于开发阶段',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': '场景已部署，但时间表仍为近期将进行',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': '场景处于活动状态，学员可以安排课次时间',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': '场景已完成',
    'MursionPortal.Button.Add': '添加 ',
    'MursionPortal.Label.Warning': '警告',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': '无法为所选{failedParam}创建课次。',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': '请按“继续”以寻找另一个<code>{failedParam}</code>。',
    'MursionPortal.SessionWizard.Label.ScheduleSession': '安排场景',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': '安排培训',
    'MursionPortal.SessionWizard.Label.AddNotes': '添加备注',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': '选择要使用的模拟内容',
    'MursionPortal.SessionWizard.Label.SelectStep': '选择{step}',
    'MursionPortal.SessionWizard.Label.Expired': '已过期',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': '您确定要创建紧急课次吗？',
    'MursionPortal.SessionWizard.Button.Create': '创建',
    'MursionPortal.Button.Next': '下一个',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': '剩余时间可在保留到期之前预订此时间段。',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': '时间段保留已到期。',
    'MursionPortal.Label.Date': '日期',
    'MursionPortal.Label.Time': '时间',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': '那个时间不再可用，您想选择其他选项吗？返回以选择可用选项，或单击提交请求现有选项。',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': '那个时间不再可用，您想选择其他选项吗？返回以选择一个可用选项。',
    'MursionPortal.SessionWizard.Button.GoBack': '返回',
    'MursionPortal.Button.Submit': '提交',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': '无可用客户',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': '无可用项目',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': '无可用场景',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': '请先选择场景',
    'MursionPortal.SessionWizard.Label.NoImage': '没有图片',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': '没有可用的模拟专家',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': '此场景没有分配的内容包。',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': '选择资产',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': '在所选择的时间没有可用的模拟专家。将根据请求分配课次。',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': '您最多只能为这种类型的课次添加{maxLearners}名受训人员。',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': '您最多只能为这种类型的课次添加{maxLearners}名受训人员。',
    'MursionPortal.EmptyMessage.NoUsersSelected': '未选择用户',
    'MursionPortal.Placeholder.Search': '搜索',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': '没有要选择的受训人员',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': '您最多只能为这种类型的课次添加{maxLearners}个学员。',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': '您最多只能为这种类型的课次添加{maxLearners}个学员。',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': '未选择学员',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': '未选择用户',
    'MursionPortal.LearnersStep.Option.AllTeams': '所有团队信息',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': '添加整个团队',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': '允许的课次规模{maxLearners}。选择特定学员',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': '只有未经验证的用户将会加入',
    'MursionPortal.NotesStep.SessionNotes': '课次备注',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': '超出了允许的字符数限制（{limit}个字符）',
    'MursionPortal.Button.LoadMore': '加载更多',
    'MursionPortal.Label.Trainer': '培训师',
    'MursionPortal.Label.Description': '描述',
    'MursionPortal.Label.Outcome': '结果',
    'MursionPortal.Label.Strategies': '策略',
    'MursionPortal.Label.DeliveryMode': '交付模式',
    'MursionPortal.Label.SessionLength': '课次长度',
    'MursionPortal.Label.NoDescriptionProvided': '未提供描述',
    'MursionPortal.Label.NoOutcomeProvided': '未提供结果',
    'MursionPortal.Label.NoStrategiesProvided': '未提供策略',
    'MursionPortal.Placeholder.Dotted.Search': '搜索...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': '没有可用的时间段',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': '所选范围内没有可用的时间段',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': '通过选择此时间段，您将请求当前不可用的课次时间。如果您继续进行操作，我们将尽可能满足您的要求；您也可以将下面的开关“关闭”滑动到仅从已确认的可用时间段中进行选择。',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': '滑动切换开关“关闭”以仅从可用时间段中进行选择。',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': '滑动切换开关“开启”以请求其他时间段。',
    'MursionPortal.Label.LowerCase.Client': '客户',
    'MursionPortal.Label.Project': '项目',
    'MursionPortal.Label.Scenario': '场景',
    'MursionPortal.Projects.NoProjectsFound': '找不到项目。',
    'MursionPortal.Projects.YourTeamsNotAssigned': '您的团队尚未分配到任何项目。',
    'MursionPortal.Projects.NoProjectsCreated': '尚未创建任何项目。',
    'MursionPortal.Teams.NoTeamsFound': '找不到团队。',
    'MursionPortal.Teams.YouHaveNotBeen': '您还未被添加到任何团队。',
    'MursionPortal.Teams.YouHaveNoTeams': '您还没有团队。',
    'MursionPortal.Teams.NoTeamsCreated': '尚未创建任何团队。',
    'Settings.SSO.Modal.ConfigurationName': '配置名称',
    'Settings.SSO.Modal.AuthorizationEndpoint': '授权端点',
    'Settings.SSO.Modal.LogoutEndpoint': '注销端点',
    'Settings.SSO.Modal.ClientID': '客户ID',
    'Settings.SSO.Modal.ClientSecret': '客户密钥',
    'Settings.SSO.Modal.Scope': '范围',
    'Settings.SSO.Modal.UserInfoEndpoint': '用户信息端点',
    'Settings.SSO.Modal.TokenEndpoint': '令牌端点',
    'Settings.SSO.Modal.TokenHTTPMethod': '令牌HTTP方法',
    'Settings.SSO.Modal.UserIDClaimName': '用户ID声明名称',
    'Settings.SSO.Modal.EmailClaimName': '电子邮件声明名称',
    'Settings.SSO.Modal.FirstNameClaimName': '名声明名称',
    'Settings.SSO.Modal.LastNameClaimName': '姓声明名称',
    'Settings.SSO.Modal.LinkAllowed': '已允许链接',
    'Settings.SSO.Modal.UnlinkAllowed': '已允许取消链接',
    'Settings.SSO.Modal.SingleLogoutEnabled': '单点注销已启用',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': '单点登录服务端点',
    'Settings.SSO.Modal.EntityID': '实体ID',
    'Settings.SSO.Modal.X509Certificate': 'X.509 证书',
    'Settings.SSO.Button.CreateSsoConfiguration': '创建SSO配置',
    'Settings.SSO.Modal.Protocol': '协议 ：',
    'Settings.SSO.Modal.Type': '类型 ：',
    'Settings.SSO.Modal.ID': 'ID ：',
    'Settings.SSO.Modal.AssignedClients': '已分配的客户',
    'Settings.SSO.Modal.Button.DeleteSso': '删除SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': '编辑SSO配置',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': '新建SSO配置',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': '创建SSO配置',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': '保存SSO配置',
    'Settings.SSO.Modal.DeletionConfirmationText': '是否确定要删除此SSO配置？',
    'Settings.SSO.Modal.AddButton.GroupClaimName': '组声明名称',
    'Settings.SSO.Modal.AddButton.Team': '团队',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': '组声明名称',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'SP发起的SSO URL ：',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': '下载SP元数据',
    'Settings.SSO.Modal.Button.Public': '公共',
    'Settings.SSO.Modal.Button.Private': '私密',
    'Settings.SSO.Modal.ConfigurationIcon': '配置图标 ：',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': '输入团队声明名称',
    'Settings.SSO.Modal.TeamClaimName': '团队声明名称',
    'Settings.SSO.Modal.TeamMapping': '团队映射 ：',
    'Settings.SSO.Modal.Button.Change': '更改',
    'MursionPortal.PlaceHolder.SelectAClient': '选择客户',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': '选择团队',
    'MursionPortal.Label.Client': '客户',
    'Settings.SSO.Modal.ConfirmDelete': '确认删除',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': '配置名称',
    'Settings.SSO.Table.ColumnHeader.Type': '类别',
    'Settings.SSO.Table.ColumnHeader.Protocol': '协议',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': '客户数',
    'Settings.SSO.PlaceHolder.EnterSsoName': '输入SSO名称',
    'Settings.SSO.Table.NoSsoSchemasFound': '找不到SSO架构。',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': '查看/编辑SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'SP发起的SSO URL：',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'SP发起的SSO URL：',
    'MursionPortal.CopyText.CopiedToClipboard': '链接已复制到剪贴板！',
    'Settings.SSO.Text.Public': '公共',
    'Settings.SSO.Text.Private': '私密',
    'Settings.SSO.Modal.NoClientsAssigned': '未分配客户',
    'MursionPortal.Label.NotAvailable': '不适用',
    'Teams.Modal.Header.NewTeam': '新团队',
    'MursionPortal.Button.More': '更多',
    'Teams.Modal.Header.AllTeams': '所有团队',
    'MursionPortal.Label.Name': '姓名：',
    'MursionPortal.Label.Location': '显示当前页面位置',
    'MursionPortal.Label.Tags': '标签：',
    'MursionPortal.Header.Facilitators': '辅导员：',
    'MursionPortal.Header.Scenarios': '场景：',
    'MursionPortal.Button.SaveTeamInfo': '保存团队信息',
    'MursionPortal.IconButton.Cancel': '取消',
    'MursionPortal.IconButton.Edit': '编辑',
    'MursionPortal.ButtonTitle.CancelEdit': '取消编辑',
    'MursionPortal.ButtonTitle.EditTeam': '编辑团队',
    'MursionPortal.ConfirmationText.RestoreTeam': '是否确定要还原此团队？',
    'MursionPortal.ButtonTitle.RestoreTeam': '还原团队',
    'MursionPortal.ConfirmationText.ArchiveTeam': '是否确定要存档此团队？',
    'MursionPortal.ButtonTitle.ArchiveTeam': '存档团队',
    'MursionPortal.EmptyMessage.NoFacilitators': '无辅导员',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': '未选择辅导员',
    'MursionPortal.Title.Facilitators': '辅导员',
    'MursionPortal.Heading.Archived': '（已存档）',
    'Teams.Modal.Header.AllLearners': '全部学员',
    'Teams.Modal.Placeholder.SearchLearners': '搜索学员…',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': '未选择学员',
    'Teams.Modal.Header.TeamLearners': '团队学员',
    'Teams.Modal.Placeholder.SearchTeamLearners': '搜索团队学员...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': '未选择团队学员',
    'Teams.Modal.Header.AssignLearners': '将学员分配给团队“ {teamName}”',
    'Teams.AssignLearners.Modal.Button.SelectAll': '全选',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': '没有选中项目！',
    'MursionPortal.Header.Teams': '团队',
    'Teams.Modal.AlertMessage.Timezone': '如果更改时区，请检查现有活动和可用性。',
    'Teams.SelectorMenu.SelectedOf': '已在{totalNumber}个中选择了{selected}个',
    'Teams.Modal.AlertMessage.ProfileTimezone': '您当前的配置文件时区选择与您计算机的日期和时间设置（{updatedTimezone}）不匹配。点击“保存更改”继续进行操作，或选择其他配置文件时区选项。',
    'MursionPortal.Label.Learner': '学员',
    'Teams.Modal.Button.More': '{overlappedAmount}更多...',
    'MursionPortal.IconButton.Archive': '存档',
    'Teams.ToggleButton.Learners': '学员',
    'Teams.ToggleButton.Sessions': '课次',
    'MursionPortal.Status.RegistrationStatus': '注册状态',
    'MursionPortal.Status.RegistrationAccepted': '已接受注册',
    'MursionPortal.Status.RegistrationNotAccepted': '未接受注册',
    'MursionPortal.SortBy': '排序方式',
    'MursionPortal.Placeholder.SortBy': 'sort-by',
    'Clients.Button.CreateClient': '创建客户 ',
    'Clients.CreateClient.Header.NewClient': '新客户',
    'Clients.CreateClient.Button.CreateClient': '创建客户',
    'Clients.CreateClient.Label.ClientName': '客户名称',
    'Clients.CreateClient.Placeholder.CityCountry': '城市，国家',
    'MursionPortal.Timezone': '时区',
    'Clients.CreateClient.Label.Logo': '徽标',
    'Clients.Text.NoClientsFound': '找不到客户。',
    'MursionPortal.Text.Active': '活动',
    'MursionPortal.Text.Archived': '已存档',
    'Clients.Modal.Button.BackToClientList': '返回客户列表',
    'Clients.Modal.EmptyMessage.NoClientsFound': '找不到客户',
    'Clients.Modal.Placeholder.EnterClientName': '输入客户名称',
    'Clients.Modal.Header.ClientsList': '客户列表',
    'Clients.Modal.Header.Client': '客户',
    'Clients.Modal.Header.AllClients': '所有客户',
    'MursionPortal.Search': '搜索',
    'Clients.Modal.RestoreConfirmText': '是否确定要还原此客户？',
    'Clients.Modal.DeleteConfirmText': '是否确定要存档此客户？',
    'Clients.Modal.RelatedDataText': '所有相关数据也将被存档。',
    'Clients.Modal.DeleteErrorText': '当前客户具备的场景。在存档此客户之前存档项目场景。',
    'Clients.Button.RestoreClient': '还原客户',
    'Clients.IconButton.Cancel': '取消',
    'Clients.IconButton.Edit': '编辑',
    'Clients.IconButton.CancelEdit': '取消编辑',
    'Cleints.IconButton.EditScenarioName': '编辑场景名称',
    'Cleints.IconButton.ApplyNameChange': '应用名称变更',
    'Clients.IconButton.Apply': '应用',
    'Clients.IconButton.EditClient': '编辑客户',
    'Clients.Button.ArchiveClient': '存档客户',
    'Clients.Modal.Heading.Archived': '（已存档）',
    'Clients.Modal.Label.Name': '姓名：',
    'Clients.Modal.Placeholder.ClientName': '客户名称',
    'Clients.Modal.Label.Location': '显示当前页面位置',
    'Clients.Modal.Label.Industry': '行业：',
    'Clients.Modal.Placeholder.SelectIndustry': '选择行业',
    'Clients.Modal.Label.Timezone': '时区：',
    'Clients.Modal.Label.SchedulingByRequest': '按请求计划：',
    'MursionPortal.Enabled': '已启用',
    'MursionPortal.Disabled': '已禁用',
    'Clients.Modal.Label.SchedulingWindow': '排程窗口',
    'Clients.Modal.Label.HowFarInAdvance': '客户应提前多久安排',
    'MursionPortal.Days': '天',
    'MursionPortal.Day': '天',
    'Clients.Modal.Label.AvailableSharedSSOTitle': '可用于登录的可用共享SSO列表',
    'Clients.Modal.Label.AvailableSharedSSOLabel': '可用的共享SSO:',
    'Clients.Modal.EmptySelectedMessage': '未选择SSO',
    'Clients.Modal.EmptyListMessage': '没有可用的SSO',
    'Clients.Modal.SSOTitle': '选择可用的SSO',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': '可用于登录的可用私有SSO列表',
    'Clients.Modal.Label.AccountOwners': '帐户所有者：',
    'Clients.Modal.AccountOwner.EmptyListMessage': '没有帐户所有者',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': '未选择账户所有者',
    'Clients.Modal.AccountOwnersTitle': '帐户所有者',
    'MursionPortal.Message.Updating': '正在更新......',
    'MursionPortal.Button.SaveClientInfo': '保存客户信息',
    'MursionPortal.Add': '添加 ',
    'Clients.Modal.Label.EditSSO': '编辑SSO',
    'Clients.Modal.Label.Edit': '编辑',
    'MursionPortal.NumberOfFacilitators': '辅导员人数',
    'MursionPortal.NumberOfLearners': '学员人数',
    'Clients.Modal.Button.CreateTeam': '创建团队',
    'MursionPortal.NewTeam': '新团队',
    'MursionPortal.NoTags': '无标签',
    'MursionPortal.Header.Tags': '标签',
    'MursionPortal.AddTag': '添加标签',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': '未选择辅导员',
    'Clients.Modal.Teams.LearnersEmptyListMessage': '未选择学员',
    'MursionPortal.Learners': '学员',
    'MursionPortal.Facilitator': '辅导员',
    'MursionPortal.CreateTeam': '创建团队',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': '是否确定要还原此团队？',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': '还原团队',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': '是否确定要存档此团队？',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': '存档团队',
    'Clients.Modal.Projects.Header.NewProject': '新建项目',
    'MursionPortal.ProjectName': '项目名称',
    'MursionPortal.Contracted': '已签约',
    'MursionPortal.CancellationWindow': '取消窗口',
    'MursionPortal.SoftwareVersion': '软件版本',
    'MursionPortal.Billing': '计费',
    'Clients.Modal.Projects.Checkbox.NonBillable': '非计费',
    'MursionPortal.Recording': '正在录制',
    'Clients.Modal.Projects.Checkbox.AllowRecording': '允许录制',
    'Clients.Modal.Projects.Label.RecordedVideos': '录制的视频返回到：',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': '录制权限取决于学员',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': '保存项目信息',
    'Clients.Modal.Projects.Header.ProjectManager': '项目经理',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': '未选择项目经理',
    'MursionPortal.Header.Bracketed.Scenarios': '场景',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': '首先完成项目信息',
    'MursionPortal.Header.CreateScenario': '创建场景',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': '是否确定要还原此项目？',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': '还原项目',
    'Clients.Modal.Projects.ButtonTitle.Edit': '编辑项目',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': '取消编辑',
    'Clients.Modal.Label.Cancel': '取消',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': '是否确定要存档此项目？',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': '存档项目',
    'Clients.Modal.Projects.ArchiveErrorMessage': '当前项目具有活动场景。在存档此项目之前存档项目场景。',
    'Clients.Modal.Projects.ButtonTitle.EditTags': '编辑标签',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': '已利用的已错过的课次，',
    'Clients.Modal.Projects.Button.CreateNew': '新建',
    'Clients.Modal.Projects.Button.RepeatClient': '重复客户',
    'Clients.Modal.Projects.Button.RepeatBank': '重复银行',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': '创建新场景',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': '重复客户场景',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': '从场景库创建场景',
    'Clients.Modal.Button.Schedule': '时间表',
    'MursionPortal.Button.ActivateScenario': '激活场景',
    'MursionPortal.Button.ChangeScenario': '更改场景',
    'Clients.IconButton.Confirm': '确认',
    'Clients.IconButton.ConfirmEdit': '确认修改',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': '选择要重复的场景',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': '从场景库中选择场景',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': '输入场景名称',
    'MursionPortal.IconButton.ResetToDefault': '重置为默认名称',
    'MursionPortal.IconButton.Reset': '重置',
    'Clients.Modal.Header.CreatePlanning': '为新场景创建计划',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': '找不到客户场景。',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': '找不到库场景。',
    'Clients.Modal.Message.NoTeamsSpecified': '未指定团队',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': '没有已选择的团队',
    'Clients.Modal.Message.NoAssignedTeams': '客户没有分配的团队',
    'Clients.Modal.Label.Certificate': '证书',
    'Clients.Modal.Label.DeliveryMode': '交付模式',
    'Clients.Modal.Label.SessionSize': '课次规模',
    'Clients.Modal.Label.SessionLength': '课次长度',
    'Clients.Modal.Label.Outcome': '结果',
    'Clients.Modal.Label.Strategies': '策略',
    'Clients.Modal.Label.Description': '描述',
    'MursionPortal.Button.MoreInfo': '更多信息...',
    'MursionPortal.Button.HideAdditionalInfo': '隐藏更多信息...',
    'MursionPortal.Header.Timeline': '时间表',
    'MursionPortal.Header.Projects': '项目 ',
    'MursionPortal.Header.Sessions': '课次',
    'MursionPortal.Header.Users': '用户',
    'MursionPortal.Text.Yes': '是',
    'MursionPortal.Text.No': '否',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook、iCal其他',
    'MursionPortal.Heading.GoogleCalendar': 'Google 日历 ',
    'MursionPortal.Heading.AddToCalendar': '添加到日历 ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': '如果您还没有准备好，将提醒您<code>验证</code><code>{companyName}门户</code>来向您的日历中添加活动。',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': '将把活动<code>添加到您的日历</code>，仍旧可以在{companyName}门户上访问。',
    'MursionPortal.Heading.OtherCalendars': '其他日历',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': '将为<code>{companyName}</code><code>下载</code>您的活动',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>右键单击</code>，选择 <code>“打开方式”</code>，然后选择您的日历应用程序。',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '（您也可以在日历中选择“导入”，然后找到下载的文件）',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': '将把活动<code>添加到您的日历</code>，仍旧可以在{companyName}门户上访问。',
    'MursionPortal.Text.NoLearnersAddedYet': '尚未添加任何学员',
    'MursionPortal.Heading.Schedule': '时间表',
    'MursionPortal.Heading.Users': '用户',
    'MursionPortal.Heading.ProfServices': '专业服务',
    'MursionPortal.Heading.SimSpecialist': '模拟专家',
    'MursionPortal.Heading.Clients': '客户',
    'MursionPortal.Heading.Sessions': '课次',
    'MursionPortal.Heading.Projects': '项目',
    'MursionPortal.Heading.Teams': '团队',
    'MyMessages.Button.ArchiveAll': '全部存档',
    'MyMessages.Button.ArchivedMessages': '已存档邮件',
    'MyMessages.Button.ShowMore': '显示更多…',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code>条新消息',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code>条新消息',
    'MyMessages.HoverTitle.InboxButton': '收件箱按钮',
    'MyMessages.Text.InboxButton': '收件箱按钮',
    'MyMessages.Text.HaveNewMessages': '您共有{total}条新消息',
    'MyMessages.Text.HaveNewMessage': '您有一条新消息',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Cookie政策',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion仅使用您的安全性和我们的Web应用程序运行所必需的必要cookie。我们不使用cookie收集任何个人数据或其他信息，例如IP地址或网站使用情况。',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' <code>有关更多信息，请参见隐私政策。</code>',
    'MursionPortal.Button.Ok': '确定',
    'MyProfile.Button.SaveChanges': '保存更改',
    'MyProfile.Label.ExternalProfiles': '外部资料',
    'MyProfile.Label.UserRoles': '用户角色',
    'MyProfile.Label.UserRole': '用户角色',
    'MyProfile.Heading.ReviewAccountInformation': '请查看您的帐户信息',
    'MyProfile.HoverTitle.UnlinkProfile': '取消链接您的{linkName}个人资料',
    'MyProfile.HoverTitle.LinkProfile': '链接{linkName}个人资料',
    'MyProfile.Button.Unlink': '取消链接',
    'MyProfile.Text.UnlinkConfirmationText': '您确定要取消链接您的{name}个人资料吗？请注意，您还将从{name}中注销。',
    'MyProfile.Title.ProfileUnlink': '{name}个人资料取消链接',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': '您已成功取消链接{name}个人资料',
    'MyProfile.Text.TimeZoneIsSetAutomatically': '您的时区会自动设置为{getTimeZone}。单击“确认”接受此选择，或单击“取消”进行另一选择。',
    'MyProfile.Text.TimeZoneChange': '如果更改时区，请检查现有活动和可用性。',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': '您当前的配置文件时区选择与您计算机的日期和时间设置（{timeZone}）不匹配。点击“保存更改”继续进行操作，或选择其他配置文件时区选项。',
    'MursionPortal.Button.MyProfile': '我的个人资料',
    'MursionPortal.Button.LogOut': '注销',
    'MyProfile.Label.Language': '语言',
    'MursionPortal.Label.Roles': '角色',
    'MursionPortal.Label.Role': '角色',
    'MursionPortal.Projects.Header.ScenarioType': '场景类别',
    'MursionPortal.Projects.Header.Client': '客户',
    'MursionPortal.Projects.Header.Project': '项目',
    'MursionPortal.Projects.Header.Scenario': '场景',
    'MursionPortal.Projects.Header.SimulationContent': '模拟内容',
    'MursionPortal.Projects.Header.Date': '日期',
    'MursionPortal.Projects.Header.Time': '时间',
    'MursionPortal.Projects.Header.Notes': '注释',
    'Filters.Date.Week': '周',
    'Users.Table.Modal.SignLanguageInterpreter': '手语翻译',
    'Calendar.Button.Schedule': '计划',
    'Calendar.Button.Today': '今天',
    'MursionPortal.Availability': '可用性',
    'Calendar.Button.Schedule.QualityAssurance': '质量保证',
    'MursionPortal.Training': '培训',
    'Calendar.Button.Schedule.EmergencySession': '紧急课次',
    'Calendar.Button.Schedule.Session': '课次',
    'Calendar.Button.Schedule.BreakPrep': '休息/预习',
    'MursionPortal.Month': '月',
    'MursionPortal.Week': '周',
    'Calendar.Heading.Calendars': '日历',
    'Calendar.Tip.SelectACalendar': '选择日历以查看空闲时间',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': '当前视图中所有计划课次的总和',
    'MursionPortal.ShortLabel.Hours': '小时',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': '当前视图中所有计划培训课次的总和',
    'Calendar.ScheduledHours.Trainings': '培训',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': '当前视图中所有剩余计划课次可用时数总和',
    'Calendar.ScheduledHours.Available': '可用',
    'MursionPortal.Scheduled': '已计划',
    'Calendar.Label.Display': '显示',
    'MursionPortal.Client': '客户',
    'Calendar.Filter.Company': '公司',
    'Calendar.Filter.SwapRequests': '互换请求',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': '筛选项目',
    'Calendar.SearchFilter.FilterClients': '筛选客户',
    'Calendar.SearchFilter.FilterSimSpecialists': '筛选模拟专家',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': '选择一个行业',
    'Calendar.IndustrySelector.Options.AllIndustries': '全部行业',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': '没有要选择的行业',
    'MursionPortal.SelectAClient': '选择证书',
    'Calendar.ClientSelector.Options.AllClients': '全部客户',
    'Calendar.ClientSelector.Options.NoClientToSelect': '没有要选择的客户',
    'MursionPortal.SelectACertificate': '选择证书',
    'Calendar.CertificateSelector.Options.AllCertificates': '全部证书',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': '没有要选择的证书',
    'Calendar.ClientsList.NoClientsFound': '找不到客户。',
    'MursionPortal.UncheckAll': '取消全选',
    'MursionPortal.CheckAll': '全选',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': '找不到模拟专家。',
    'Calendar.ProjectsList.CloseProjectOverview': '关闭项目概览',
    'Calendar.ProjectsList.AllProjects': '全部项目',
    'MursionPortal.Bracketed.Scenarios': '场景',
    'Calendar.ProjectsList.Overview': '概览',
    'Calendar.ProjectsList.CloseClientOverview': '关闭客户概览',
    'MursionPortal.Scenario': '场景',
    'Calendar.ScenariosList.NoScenarios': '没有场景',
    'MursionPortal.Delivery': '交付',
    'MursionPortal.Mode': '模式',
    'MursionPortal.Timeline': '时间轴',
    'Calendar.ScenariosList.Description': '描述',
    'MursionPortal.Outcome': '结果',
    'MursionPortal.Strategies': '战略',
    'MursionPortal.ContentBundle': '内容捆绑包',
    'MursionPortal.SimulationEnvironments': '模拟环境',
    'MursionPortal.AvatarsInSimulations': '模拟中的虚拟形象',
    'Calendar.LearnersList.SessionNotScheduled': '未安排课次',
    'Calendar.LearnersList.SessionScheduled': '已安排课次',
    'Calendar.LearnersList.SessionComplete': '已完成课次',
    'Calendar.LearnersList.Learner': '{learnersCount}名学员',
    'Calendar.LearnersList.Learners': '{learnersCount}名学员',
    'Calendar.Button.CantSchedule': '无法安排',
    'Calendar.Button.ScheduleEvent': '安排活动',
    'Calendar.MainPanel.ConfirmText.AreYouSure': '您是否确定要取消此活动？',
    'Calendar.MainPanel.CancelEvent': '取消活动',
    'Calendar.MainPanel.UpdateEvent': '更新活动',
    'MursionPortal.Submit': '提交',
    'Calendar.MainPanel.NewEvent': '新活动',
    'MursionPortal.Project': '项目',
    'MursionPortal.Button.Reload': '重新加载',
    'Clients.Modal.Label.FacilitatorVisibility': '辅导员视图:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': '选择辅导员视图',
    'Clients.Modal.Projects.ConfirmationText.EditProject': '您是否确定您想编辑此项目？',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': '场景状态标签',
    'Projects.ScenarioList.Legend.CreateNew': '新建、重复客户、重复银行场景按钮',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': '查看模式标签',
    'MursionPortal.SearchPlaceHolder.SearchClient': '搜索客户...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': '搜索团队...',
    'Settings.SSO.Modal.Button.ChangeLabel': '更改{label}',
    'MursionPortal.Label.Yes': '是',
    'MursionPortal.Label.No': '否',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': '无选项',
    'Settings.SSO.Modal.ClientName': '客户名称',
    'MursionPortal.Modal.Button.Add': '添加',
    'MursionPortal.Modal.Button.Remove': '删除',
    'Users.Table.Hover.ViewEditAcceptedUsers': '查看/编辑已接受的用户',
    'Users.Table.Hover.ViewEditUnacceptedUsers': '查看/编辑未接受的用户',
    'MursionPortal.Label.Industry': '行业',
    'MursionPortal.Label.Timezone': '时区',
    'MursionPortal.Header.Client': '客户',
    'MursionPortal.FilterByCertificate': '按证书筛选',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': '创建QA活动',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': '时间块',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': '参与者',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': '提交新活动',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': '时间块超出了可用范围',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': '时间块早于当前时间',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': '开始',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': '结束',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': '时长',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': '所选的当天没有可用的时间块',
    'Calendar.Modal.Button.Client': '客户',
    'Calendar.Modal.Button.Project': '项目',
    'Calendar.Modal.Button.Scenario': '场景',
    'Calendar.Modal.Header.CompanyEvents': '公司活动',
    'MursionPortal.Header.Session': '课次',
    'MursionPortal.Header.Events': '活动',
    'MursionPortal.Header.Event': '活动',
    'Calendar.Modal.Label.Time': '时间',
    'Calendar.Label.SelectTimeZone': '选择时区',
    'Calendar.Text.Training': ' 培训',
    'Calendar.Text.QA': ' QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': '确认删除时间块',
    'Calendar.Button.NewTimeBlock': '新时间块',
    'Calendar.Modal.Label.Capitalized.Time': '时间',
    'Calendar.Modal.Label.Participants': '参与者',
    'Calendar.Modal.Text.NoParticipants': '没有参与者',
    'Calendar.Modal.Label.SimulationSpecialist': '模拟专家',
    'Calendar.Modal.Label.MeetingOrganizer': '会议组织者',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix}必须介于{duration1}和{duration2}之间。',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': '选择的时间块与现有时间块重叠。',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': '选择的时间块与不可用的时间间隔重叠。',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': '所选时间块不是活动间隔（{duration}）的倍数)',
    'Calendar.Text.AvailabilityUser': '可用性{user}',
    'Calendar.Text.AvailabilityTime': '可用性{time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration}可用性{time}',
    'Calendar.Text.TrainingsTime': '{eventsCount}培训{time}',
    'Calendar.Text.SessionsTime': '{eventsCount}课次{time}',
    'Calendar.Text.AvailabilityName': '可用性{name}',
    'Calendar.Label.Learners': '学员',
    'Calendar.Text.FullAvailability': '完全可用性',
    'Calendar.Text.YourAvailability': '您的可用性',
    'Calendar.Text.Event': '活动',
    'Calendar.Header.Trainings': '培训',
    'Calendar.Header.CompanyEvents': '公司活动',
    'Calendar.Header.Session': '课次',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': '时间块超出了可用范围',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': '时间块早于当前时间',
    'Calendar.Label.Break': '休息',
    'Calendar.Label.Prep': '预习',
    'Calendar.Modal.Header.CreateBreakPrepEvent': '创建休息/预习活动',
    'Calendar.Modal.Label.EventType': 'EventType',
    'Calendar.Modal.Label.TimeBlocks': '时间块',
    'Calendar.Text.NoTimeBlocksInSelectedRange': '所选范围中没有可用的时间块',
    'Calendar.Text.NoAvailableTimeBlocks': '没有可用的时间块',
    'Calendar.Text.SelectingThisTimeBlock': '选择此时间块，您将请求当前不可用的课次时间。如果您继续进行操作，我们将尽可能满足您的要求；您也可以将下面的切换开关滑动到“关”，仅从已确认的可用时间块中进行选择。',
    'Calendar.ToolTip.SlideToggleOff': '将切换开关滑动到“关”，仅从可用时间块中选择。',
    'Calendar.ToolTip.SlideToggleOn': '将切换开关滑动到“开”以请求其他时间块。',
    'Calendar.Label.SelectAnyTimeBlock': '选择任何时间块',
    'Users.CreateUser.Label.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetID.Hover': '请输入Google Meet ID',
    'Setting.Edit.Modal.Error.UnableToCreateSession': '未能创建所选时间段{formattedStartTime} - {formattedEndTime}的课次',
    'Session.Header.Session': '课次',
    'Session.Header.AcceptSessionRequest': '请确认您是否想接受此课次请求并将其添加到您的日程表中。',
    'CommonComponent.RecurrenceForm.Label.UntilDate': '截止日期',
    'CommonComponent.RecurrenceForm.Label.Period': '时间',
    'CommonComponent.RecurrenceForm.Option.Daily': '每天',
    'CommonComponent.RecurrenceForm.Option.Weekly': '每周',
    'CommonComponent.RecurrenceForm.Label.Weekday': '工作日',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': '未选择工作日',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': '请选择工作日',
    'Calendar.EventType.Availability': '可用性',
    'Calendar.EventType.Session': '课次',
    'Calendar.EventType.Training': '培训',
    'Calendar.EventType.CompanyEvent': '公司活动',
    'Calendar.EventType.NewEvent': '新活动',
    'Calendar.Text.NewEventTime': '新活动{time}',
    'Calendar.Header.Training': ' 培训',
    'Calendar.Modal.Text.Sessions': '课次',
    'Calendar.Modal.Text.Trainings': '培训',
    'Calendar.Modal.Text.Events': '活动',
    'Calendar.Modal.Text.PendingNoSS': '- 待处理（非计划课次）。',
    'Calendar.Modal.Text.PendingSwap': '- 待处理（互换）。',
    'Calendar.Modal.Text.PreBooked': '- 已预先预订。',
    'Calendar.Modal.Text.NeedsReview': '- 需要评审。',
    'Calendar.Modal.Text.JoinSession': '- 您可以加入此课次。',
    'Calendar.Modal.Text.NoLearnersInSession': '- 本课次中没有学员。',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- 课次在预订时间之后没有学员。',
    'Calendar.Modal.Header.Events': '活动',
    'Calendar.Modal.Header.Session': '课次',
    'Calendar.Modal.Header.Training': '培训',
    'Calendar.Modal.Header.Availability': '可用性',
    'Calendar.Modal.Filter.Client': '客户',
    'Calendar.Modal.Filter.Project': '项目',
    'Calendar.Modal.Filter.Time': '时间',
    'Calendar.Modal.Label.Recurrence': '定期',
    'Calendar.Modal.Header.CreateAvailability': '创建可用性',
    'Calendar.Modal.Text.Recurring': '定期',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': '已计划',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '（此期间）',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': '课次',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': '小时',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': '培训',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': '培训时数',
    'Calendar.SimSpecialistButton.Modal.Label.Available': '可用',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': '认证',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': '无证书',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': '模拟专家概览面板',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': '关闭模拟专家概览',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': '远程',
    'MursionPortal.DeliveryMode.OneToManyLocal': '本地（Fishbowl）',
    'MursionPortal.DeliveryMode.Group': '小组交付',
    'MursionPortal.Projects.Header.Trainees': '学员',
    'Projects.Placeholder.SimContentName': '输入模拟内容名称',
    'Projects.Message.NoSimulationContentFound': '找不到模拟内容',
    'MursionPortal.Button.UncheckAll': '取消全选',
    'MursionPortal.Button.CheckAll': '全选',
    'Projects.Header.NewScenario': '项目{projectName}',
    'MursionPortal.Button.SaveAsDraft': '保存为草稿',
    'MursionPortal.Button.Save': '保存',
    'Projects.Message.SelectEnvironmentFirst': '请先选择环境',
    'Projects.Message.LoadingAvatarsList': '正在加载虚拟形象列表...',
    'Projects.Message.NoAssignedAvatars': '所选环境没有已分配的虚拟形象',
    'Projetcs.EmptyMessage.NoSelectedAvatars': '没有已选中的虚拟形象',
    'Projects.Avatars.NoAvatarsSpecified': '没有已指定的虚拟形象',
    'Projects.Message.SelectIndustryFirst': '请先选择行业',
    'Projects.Message.LoadingEnvironmentsList': '正在加载环境列表...',
    'Projects.Message.NoAssignedEnvironments': '所选行业没有已分配的环境',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': '此场景没有已分配的环境',
    'Projects.AltText.EnvironmentImage': '环境图片',
    'Projects.Placeholder.SelectAnEnviroment': '选择环境',
    'Projects.Message.UnnamedIndustry': '未命名行业',
    'Projects.Placeholder.SelectAnIndustry': '选择一个行业',
    'Projects.Header.NewProject': '新项目',
    'Projects.AltText.ClientLogo': '客户Logo',
    'Projects.Message.SelectAnyTimeBlock': '选择任何时间块',
    'Projects.Message.SelectScenarioFirst': '请先选择场景',
    'MursionPortal.Text.Asset': '资源V2',
    'MursionPortal.Button.Accept': '接受',
    'Projects.Modal.EmptyMessage.NoLearner': '没有学员',
    'MursionPortal.Label.SelectAll': '全选',
    'Projects.Modal.Placeholder.ChooseATeam': '选择团队',
    'MursionPortal.Placeholder.SearchTeams': '搜索团队...',
    'Projects.Modal.Text.SessionSize': '允许的课次规模等于{maxLearners}。选择特定学员',
    'Projects.Modal.Placeholder.SearchLearners': '搜索学员',
    'MursionPortal.ErrorMessage.MaxLearners': '您最多只能为这种类型的课次添加{maxLearners}个学员。',
    'MursionPortal.ErrorMessage.MaxLearner': '您最多只能为这种类型的课次添加{maxLearners}个学员。',
    'MursionPortal.Label.ChooseMembers': '选择成员',
    'MursionPortal.Message.NoMembersToSelect': '没有可供选择的成员',
    'MursionPortal.Message.PleaseSelectATeamFirst': '请先选择团队',
    'AssetSync.Header.AssetSync': '资源同步',
    'AssetSync.Text.AssetsSyncedSuccessfully': '已成功同步资源',
    'AssetSync.Button.Sync': '同步',
    'AssetSync.Button.TryAgain': '请重试',
    'AssetSync.SyncButton.Text.Syncing': '同步中',
    'SessionFlow.Label.SessionExport': '课次导出',
    'MursionPortal.Button.Export': '导出',
    'SessionFlow.Label.SessionFlowData': '课次流数据',
    'SessionFlow.Button.LoadSessionData': '加载课次数据',
    'SessionFlow.Button.StartSessionAnalytics': '开始课次分析',
    'SessionFlow.Label.Checklist': '检查清单',
    'SessionFlow.Label.RecordingSettings': '记录设置',
    'SessionFlow.Label.SessionVideoFact': '课次视频实况',
    'SessionFlow.Label.UserStatus': '用户状态',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': '已允许视频',
    'MursionPortal.Table.ColumnHeader.UserName': '用户名',
    'MursionPortal.Table.ColumnHeader.Email': '电子邮箱',
    'MursionPortal.Table.ColumnHeader.Status': '状态',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': '启动分析流程的活动已成功发布',
    'SessionFlow.RecordingSettings.Label.VideoRecording': '视频录制',
    'SessionFlow.RecordingSettings.Text.Allowed': '已允许',
    'SessionFlow.RecordingSettings.Text.Disallowed': '不允许',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': '录制权限',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': '取决于学员',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': '将已录制视频返回至',
    'SessionFlow.Error.CannotFindSession': '无法找到所提供的ID的课次',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': '类型',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': '日期',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': '错误',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': '负载',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': '课次ID',
    'SessionFlow.Button.HideDetailedEventLog': '隐藏详细活动记录...',
    'SessionFlow.Button.ShowDetailedEventLog': '显示详细活动记录...',
    'SessionFlow.Button.HideSessionHistory': '隐藏课次历史...',
    'SessionFlow.Button.ShowSessionHistory': '显示课次历史...',
    'SessionFlow.Text.NoEvents': '没有活动',
    'SessionFlow.SessionVideoFact.Label.VideoExists': '视频存在',
    'SessionFlow.Text.NoLearners': '没有学员',
    'SessionFlow.Checklist.DataRecording': '数据录制',
    'SessionFlow.Checklist.DataUploading': '数据上传',
    'SessionFlow.Checklist.Analytics': '分析',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': '是否确定要存档此公司？',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': '存档公司',
    'Companies.Label.CompanyInfo': '公司信息',
    'Companies.CompanyInfo.Archived': '（已存档）',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': '取消编辑',
    'Companies.EditIconButton.HoverTitle.EditCompany': '编辑公司',
    'Companies.Button.InviteProfService': '邀请专业服务',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': '新专业服务',
    'Companies.Label.Config': '配置',
    'Companies.EditIconButton.HoverTitle.EditConfig': '编辑配置',
    'Companies.Config.Button.Test': '测试',
    'Companies.Config.Button.SaveCompanyConfig': '保存公司配置',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': '保存公司信息',
    'Companies.ValidationError': '出现验证错误',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': '未能保存配置。请修复错误。',
    'Companies.Config.Text.TestPassedSuccessfully': '测试已成功通过',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': '公司名称',
    'Companies.Button.CreateCompany': '创建公司 ',
    'Companies.Error.NoCompanies': '无公司',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': '专业服务',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': '添加用户',
    'Companies.CreateUserButton.Modal.Header.NewCompany': '新公司',
    'Companies.Header.AllCompanies': '全部公司',
    'MursionPortal.Roles': '角色',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': '存档全局用户',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': '编辑全局用户',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': '新全局用户',
    'Users.NoGlobalUserFoundError': '找不到全局用户。',
    'Users.Table.ColumnHeader.Department': '部门',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': '显示反馈',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': '隐藏反馈',
    'MursionPortal.TechSupport': '技术支持',
    'MursionPortal.TechSupport.Header.ContactSupport': '联系支持',
    'MursionPortal.TechSupport.Label.Phone': '电话',
    'MursionPortal.TechSupport.Label.Email': '电子邮箱',
    'MursionPortal.TechSupport.Label.Hours': '小时',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Mursion支持',
    'Downloads.Text.ClickDownloadButton': '单击下方下载按钮以获得安装程序的最新版本。可执行文件将被保存在您的默认浏览器下载位置（例如：“下载”文件夹）。',
    'Downloads.Text.OnceDownloadIsComplete': '下载完成后，请单击已下载的可执行文件并遵照屏幕提示以完成安装。',
    'Downloads.Text.AppInstalled': '应用安装后，您以后可以在需要时双击Mursion图标来启动它。',
    'Downloads.Button.Download': '下载',
    'Downloads.Text.PlatformNotCurrentlySupported': '抱歉，目前暂不支持您的平台。',
    'Downloads.Text.FollowOnScreenInstructions': '单击{info}并遵照屏幕说明安装应用程序',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': '安装完成后，将在您的桌面上创建Mursion快捷方式。{lineBreak}在您需要启动应用程序以体验已安排的的模拟时，可以双击{image}快捷方式。',
    'Filters.Date.LastWeek': '上周',
    'MursionPortal.Text.Mins': '分钟',
    'MursionPortal.Text.Min': '分钟',
    'Tutorial.Header.ManageYourTeams': '管理您的团队',
    'MursionPortal.Button.Awesome': '好极了',
    'Tutorial.Header.ReviewYourProjects': '查看您的项目',
    'MursionPortal.Button.LooksGood': '看起来不错',
    'Tutorial.Header.LearnerScheduling': '学员安排',
    'MursionPortal.Button.AllDone': '全部就绪！',
    'Tutorial.Header.InviteYourUsers': '邀请您的用户',
    'MursionPortal.Button.GotIt': '明白',
    'Tutorial.Header.CreateATeam': '创建团队',
    'Tutorial.Header.ManageYourProjects': '管理您的项目',
    'Tutorial.Header.YourTeams': '您的团队',
    'Tutorial.Header.YourProjects': '您的项目',
    'Tutorial.Header.SessionCalendar': '课程日历',
    'Tutorial.Tooltip.TeamsOrganize': '团队将组织您的学员并将添加至场景',
    'Tutorial.Text.ViewSessions': '查看团队<code>即将开始以及已完成的</code>课次',
    'Tutorial.Tooltip.ContactManager': '或联系您的{companyName}项目经理',
    'Tutorial.Tooltip.YourPointOfContact': '您所有学习内容的联络点',
    'Tutorial.Text.TeamAdded': '团队将被<code>添加至场景</code>以开始计划',
    'Tutorial.Text.ViewLearners': '查看学员 <code>安排活动</code>及<code>状态</code>',
    'Tutorial.Text.EnterEmail': '输入每个新{companyName}门户用户的<code>电子邮箱地址</code>',
    'Tutorial.Text.RegistrationEmail': '<code>注册信息电子邮件</code>将被发送至其收件箱',
    'Tutorial.Text.TeamsOrganize': '团队<code>组织您的学员</code>并由讲师管理',
    'Tutorial.Text.UniqueName': '为您的团队起个<code>唯一名称</code>并输入位置',
    'Tutorial.Text.SessionDelivery': '稍后，团队将被<code>添加至场景</code>用于<code>课次交付</code>',
    'Tutorial.Tooltip.Description': '描述、结果和战略',
    'Tutorial.Text.BeginScheduling': '必须将团队<code>添加至场景</code>才能开始计划',
    'Tutorial.Text.MonitorSessions': '监控每个项目<code>即将开始的课次</code>及<code>可用性</code>',
    'Tutorial.Text.LearnersWithoutSessions': '查看<code>尚未安排</code>课次的学员',
    'Tutorial.Tooltip.LearnersSchedule': '学员也可以自行安排',
    'Tutorial.Text.AddedToLearners': '您将被添加至<code>1个或多个</code>学员团队',
    'Tutorial.Tooltip.TeamAdmin': '您的团队管理员和联络点',
    'Tutorial.Text.TeamAddedBeforeProject': '您的团队将在项目开始前被<code>添加至场景</code>',
    'Tutorial.Text.AddRemoveLearners': '在您的<tooltip>团队</tooltip>中<code>添加或删除</code>学员',
    'Tutorial.Text.AddedByCompany': '您将被您的公司<tooltip>账户所有者</tooltip>添加至场景/项目',
    'Tutorial.Text.ViewYourHours': '查看您的时间、场景或联系您的<tooltip><code>项目经理</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': '场景包含推升您的<code>{companyName}体验</code>的<tooltip>详细信息</tooltip>',
    'Tutorial.Text.SelectTimeSlot': '选择开放时段来为课次<tooltip><code>安排学员</code></tooltip>',
    'Tutorial.Text.ChooseRole': '为每名新用户选择角色（<tooltip>账户所有者</tooltip> <secondTooltip>讲师</secondTooltip>或<thirdTooltip>学员</thirdTooltip>）',
    'Tutorial.Tooltip.AdminManages': '管理用户和项目的管理员',
    'Tutorial.Tooltip.ManagesScheduling': '管理日程安排和课次交付',
    'Tutorial.Tooltip.SchedulesSessions': '日程安排和参加课次',
    'Tutorial.Text.ScenariosContain': '场景包含推升您的<code>{companyName}体验</code>的<tooltip>详细信息</tooltip>',
    'Tutorial.Text.SelectOpenTimeSlot': '选择开放时段来<tooltip><code>安排学员</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': '<tooltip>讲师</tooltip>是您团队的管理员和联络点',
    'Tutorial.Text.ProjectsContain': '项目包含<code>场景</code>，其中有推升您<code>学习体验</code>的<tooltip>详细信息</tooltip>',
    'Tutorial.Text.ViewAvailability': '查看可用性并<code>安排课次</code>',
    'Tutorial.Text.TrackSessions': '跟踪每个项目<code>即将开始的课次</code>。必要时取消/重新安排',
    'Tutorial.Text.ReviewPerformance': '在您的课次结束后查看您的<code>成绩</code>及<code>战略完成情况</code>',
    'Tutorial.Header.Complete': '已完成',
    'Tutorial.Text.RevisitThisGuide': '您可以<code>随时</code>在<code>知识库</code>中重温这个指南及其他{lineBreak}有用信息',
    'Tutorial.Button.TakeMeToThePortal': '带我到门户',
    'Tutorial.Header.DownloadInstallMursionSimulator': '下载并安装{lineBreak}Mursion Simulator',
    'Tutorial.Text.PlanningToUseMursionOnComputer': '如果您打算使用这台电脑运行Mursion模拟，我们强烈建议现在就单击下方“安装Mursion”来安装必要的软件。',
    'Tutorial.Text.InstallSoftwareFromPortal': '您可以选择稍后直接从门户安装。请注意，Mursion沉浸式模拟在第一次使用时可能需要数分钟的安装时间。请在您模拟开始前至少30分钟计划安装本软件，这样就可以有足够长的时间来充分享受Mursion 。',
    'Tutorial.Text.ProvideInstructionsForInstallation': '我们将继续不时通过电子邮件和Mursion门户和模拟器中的教程提供安装和其他工作流程的说明。我们希望您享受体验模拟的过程，就像我们享受将它创造出来的过程一样！',
    'Tutorial.Text.TheMursionTeam': 'Mursion团队。',
    'Tutorial.Button.InstallLater': '稍后安装',
    'Tutorial.Button.InstallMursion': '安装Mursion',
    'Tutorial.Header.LetsGetStarted': '我们开始吧',
    'Tutorial.Text.ShortIntro': '首先，以下是一个<code>简短的介绍</code>，展示了{lineBreak}<code>您的用户角色</code>的一些常见功能。',
    'Tutorial.Button.Proceed': '继续',
    'Tutorial.Label.Step': '步骤',
    'Tutorial.Label.NoSound': '这段视频没有声音。',
    'Tutorial.Button.SkipTutorial': '跳过教程',
    'Tutorial.Button.CloseTutorial': '关闭教程',
    'MursionPortal.Text.On': '开',
    'MursionPortal.Text.Off': '关',
    'MursionPortal.Text.MursionIcon': 'mursion-icon',
    'MursionPortal.AriaLabel.CreateNew': '新建',
    'MursionPortal.Legend.VisibilityHidden.Buttons': '新建、重复客户、重复银行场景按钮',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': '输入电子邮件ID',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': '选择注册状态',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': '客户logo上传',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': '公司用户',
    'MursionPortal.Legend.VisibilityHidden.StationType': '站点类型',
    'MursionPortal.Legend.VisibilityHidden.Hours': '小时',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': '公司用户列表',
    'MursionPortal.AriaLabel.Search': '搜索',
    'MursionPortal.AriaLabel.From': '自',
    'MursionPortal.AriaLabel.To': '至',
    'MursionPortal.AriaLabel.RemoveAccountOwner': '删除账户所有者',
    'MursionPortal.AriaLabel.InboxMessagePanel': '收件箱消息面板',
    'MursionPortal.AriaLabel.Breadcrumb': '面包屑',
    'MursionPortal.Message.NoItemsHaveBeenFound': '找不到项目。',
    'MursionPortal.AriaLabel.Add': '添加',
    'MursionPortal.AriaLabel.SortByDescendingOrder': '按降序排列',
    'MursionPortal.AriaLabel.SortByAscendingOrder': '按升序排列',
    'MursionPortal.AriaLabel.AddButton': '添加按钮',
    'MursionPortal.AriaLabel.Timezone': '时区',
    'MursionPortal.AriaLabel.Combobox': '组合框',
    'MursionPortal.AriaLabel.PreviousMonth': '上月',
    'MursionPortal.AriaLabel.NextMonth': '下月',
    'MursionPortal.AriaLabel.SearchInput': '搜索输入',
    'MursionPortal.AriaLabel.Informational': '信息性',
    'MursionPortal.Text.RedirectToCalendarPage': '重定向到日历页面',
    'MursionPortal.AriaLabel.StartDate': '开始日期{startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': ' 结束日期{endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': '创建项目',
    'MursionPortal.AriaLabel.ContentInfo': '内容信息',
    'MursionPortal.AriaLabel.DraftSuperScript': '草稿上标{statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': '已完成上标{statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': '已开始上标{statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': '已完成下标{statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': '已存档上标{statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': '输入场景名称',
    'MursionPortal.AriaLabel.Capitalized.Search': '搜索',
    'MursionPortal.AriaLabel.Close': '关闭',
    'MursionPortal.AriaLabel.CreateTeam': '创建团队',
    'MursionPortal.AriaLabel.CreateClient': '创建客户',
    'MursionPortal.AriaLabel.CreateUser': '创建用户',
    'MursionPortal.AriaLabel.ZoomId': 'Zoom ID',
    'MursionPortal.AriaLabel.PrimaryContent': '主要内容',
    'MursionPortal.AriaLabel.RegistrationStatus': '注册状态',
    'MursionPortal.AriaLabel.EnterTagName': '输入标签名称',
    'MursionPortal.AriaLabel.EnterUserName': '输入用户名',
    'MursionPortal.AriaLabel.SearchProjectManagers': '搜索项目经理',
    'MursionPortal.AriaLabel.SearchSSO': '搜索SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': '搜索账户所有者',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': '超出了允许的字符数限制（{maximumLength}个字符）',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': '检测到无效字符',
    'MursionPortal.Days.Sunday': '星期日',
    'MursionPortal.Days.Monday': '星期一',
    'MursionPortal.Days.Tuesday': '星期二',
    'MursionPortal.Days.Wednesday': '星期三',
    'MursionPortal.Days.Thursday': '星期四',
    'MursionPortal.Days.Friday': '星期五',
    'MursionPortal.Days.Saturday': '星期六',
    'MursionPortal.Button.AddNew': '新增',
    'MursionPortal.Title.EditNotes': '编辑注释',
    'MursionPortal.IconButton.Remove': '删除',
    'MursionPortal.Filter.ProviderAll': '提供者',
    'MursionPortal.Placeholder.SearchProviders': '搜索提供者...',
    'MursionPortal.AriaLabel.InboxButton': '收件箱按钮，您有{messageCount}封新邮件',
    'MursionPortal.AriaLabel.Event': '活动',
    'MursionPortal.Title.SelectedDate': '所选日期',
    'MursionPortal.Title.CurrentDate': '当前日期',
    'MursionPortal.Title.OffRangeDate': '日期超出范围',
    'MursionPortal.Label.ClientLevel': '客户级别',
    'MursionPortal.Label.TeamLevel': '团队级别',
    'MursionPortal.AltText.UserImage': '用户图片',
    'MursionPortal.AltText.KbMenu': 'kb菜单',
    'MursionPortal.AltText.AvatarImage': '虚拟形象图片',
    'MursionPortal.AltText.EnvironmentImage': '环境图片',
    'MursionPortal.AriaLabel.Cancel': '取消',
    'MursionPortal.AriaLabel.Edit': '编辑',
    'MursionPortal.AriaLabel.EditAccountOwner': '编辑账户所有者',
    'MursionPortal.AriaLabel.Remove': '删除',
    'MursionPortal.AriaLabel.SearchFilter': '搜索筛选条件',
    'MursionPortal.AriaLabel.TierCombobox': '等级组合框',
    'MursionPortal.AriaLabel.SortByAscending': '按升序排列',
    'MursionPortal.AriaLabel.SortByDescending': '按降序排列',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': '选择交付模式将会允许设置课次时长和设置课次规模',
    'MursionPortal.AltText.DownloadComputerIcon': 'Mursion下载计算机图标',
    'MursionPortal.AltText.DownloadIcon': 'Mursion下载图标',
    'MursionPortal.AltText.MursionLogo': 'Mursion logo',
    'MursionPortal.AriaLabel.MursionSupport': 'mursion支持',
    'MursionPortal.AltText.MursionDashboard': 'Mursion仪表板',
    'MursionPortal.AltText.BackgroundMaleUser': 'Mursion背景男性用户',
    'MursionPortal.AltText.BackgroundGroupUser': 'Mursion背景群组用户',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Mursion背景女性用户',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': '超出了允许的字符数限制（{maximumLength}个字符）',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': '检测到无效字符',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': '您必须输入一个有效的电话号码。',
    'MursionPortal.Message.InvitationLinkInvalid': '您的邀请链接无效或可能已过期。{lineBreak}如果您还未完成注册，{lineBreak}请让您的经理重新邀请。',
    'MursionPortal.Header.SetYourPassword': '设置你的密码',
    'MursionPortal.Message.PasswordMessage': '您的密码必须包含以下内容：',
    'MursionPortal.Label.NewPassword': '新密码',
    'MursionPortal.Label.RepeatPassword': '重复密码',
    'MursionPortal.Button.SetPassword': '设置密码',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': '出问题了，{lineBreak}请联系支持{lineBreak}部门',
    'MursionPortal.Header.ContactSupport': '联系支持部门',
    'MursionPortal.Label.Phone': '电话',
    'MursionPortal.Label.Email': '电子邮箱',
    'MursionPortal.Label.Hours': '小时',
    'MursionPortal.Message.EnterPasswordToConfirm': '请输入您的密码以确认此更改',
    'MursionPortal.Label.Password': '密码',
    'MursionPortal.Message.BrowserDialog': '如果您没有看到浏览器对话框，请单击下方的<code>打开Mursion</code>，{lineBreak}或使用Mursion桌面快捷方式打开Mursion应用。',
    'MursionPortal.Button.OpenMursion': '打开ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': '打开{appName}并遵循屏幕说明安装应用程序',
    'MursionPortal.Text.PositiveImpact': '当您感到自己有正面影响时，单击<code>{icon}</code>按钮。',
    'MursionPortal.Text.NegativeImpact': '当您感到自己有负面影响时，单击<code>{icon}</code>按钮。',
    'MursionPortal.Text.NeutralImpact': '当您感到自己的影响既不正面也不负面，单击<code>{icon}</code>按钮。',
    'MursionPortal.Title.Next': '下一个',
    'MursionPortal.Title.Start': '开始',
    'MursionPortal.Text.UpdateYourRating': '在您观看视频时，可在认为对虚拟形象有不同影响时随时更新您的评分。',
    'MursionPortal.Text.SeeCurrentRating': '视频下方的图形将会更新，这样您就会看到自己当前的评分，并且可以在您认为影响改变时更改评分。',
    'MursionPortal.Text.ClickStartToBegin': '单击“开始”来开始。您可以在您为整个模拟进行打分后单击“完成”。请注意，视频搜索功能在这段视频的某些部分可能被禁用，以保持数据完整性。',
    'MursionPortal.Header.HowWouldYouProceed': '您想怎样继续下一步？',
    'MursionPoral.Button.SwitchUser': '切换用户',
    'MursionPortal.Button.ContinueAs': '作为<code>{userName}</code>继续',
    'MursionPortal.Text.ToContinue': '要作为<code>{loginData}</code>继续，请单击<code>切换用户</code>。',
    'MursionPortal.Message.YouAreSignedIn': '您当前已作为<code>{userName}</code>（{userProfile}）登录。',
    'MursionPortal.Title.ContinueAs': '继续作为{userName}。',
    'MursionPortal.Message.OpenLink': '打开链接',
    'MursionPortal.Message.Allow': '允许',
    'MursionPortal.Message.Open': '打开',
    'MursionPortal.Message.Yes': '是',
    'MursionPortal.Message.OpenMursionApp': '打开MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': '至少 {PASSWORD_MIN_LENGTH} 人物',
    'MursionPortal.Message.PortalHeading': '设计、安排和管理您的',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': '大写字母 + 小写字母',
    'MursionPortal.PasswordRequirementMessage.Numbers': '数字的任意组合',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': '无效的电子邮箱格式。',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': '电子邮箱不能为空。',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': '请输入有效的电子邮件地址。',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': '密码不能为空',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'Url不能为空',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'Url无效',
    'MursionPortal.ErrorMessage.NoMeetingId': '不包含会议id',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': '文件请求失败',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': '文件超出了5MB的限制',
    'MursionPortal.ValidationErrorMessage.EnterMessage': '请在提交前先输入您的留言',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': '留言太长',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': '文件url无效',
    'MursionPortal.Button.SubmitFeedback': '提交反馈',
    'MursionPortal.Header.Feedback': '反馈',
    'MursionPortal.Text.AdditionalComments': '其他意见或建议',
    'MursionPortal.Header.ThanksForFeedback': '谢谢您的反馈！',
    'MursionPortal.Button.Close': '关闭',
    'MursionPortal.Title.ShowFeedback': '显示反馈',
    'MursionPortal.Title.HideFeedback': '隐藏反馈',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': '文件类型{type}不受支持',
    'MursionPortal.ErrorMessage.ScenarioDuration': '场景持续时间必须在{durationMinimum}和{durationMaximum}之间。',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': '有{sessionsCount}个针对此场景的计划课次。请在存档此场景前先取消这些课次。',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': '有{sessionsCount}个针对此场景的计划课次。请在存档此场景前先取消此课次。',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': '有{sessionsCount}个针对此客户的计划课次。请在存档客户前先取消这些课次。',
    'MursionPortal.ErrorMessage.ClientScheduledSession': '有{sessionsCount}个针对此客户的计划课次。请在存档此客户前先取消此课次。',
    'MursionPortal.EulaAgreement.EndUserAgreement': '本最终用户协议（“协议”）是您（即用户）与Mursion, Inc. （“Mursion”）之间的法律协议，Mursion和Mursion客户实体之间Mursion软件即服务协议的条款和条件约束，您通过该协议使用Mursion软件。为了清楚起见，您承认并同意，作为您使用和访问Mursion软件（“Mursion软件”）的条件，您以及向您提供对Mursion软件访问权限的实体均应遵守并受本协议条款和条件的约束。',
    'MursionPortal.EulaAgreement.TermsAndConditions': '根据本协议和Mursion软件即服务协议的条款和条件，Mursion特此允许给予您有限、非独占、不可转让、不可再许可的Mursion软件访问和使用权利。',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': '您同意并承认，您无没有权利、也不会对Mursion Software或相关材料全部或部分复制、修改、反向工程、反编译、反汇编或创建派生作品。您同意不破坏、禁用或规避与Mursion软件相关的任何保护机制。获悉任何未经授权而对Mursion软件的拥有或使用或访问后，您将立即通知Mursion，并且您进一步同意立即提供每次此类事件的详细信息，以（在可能的程度上）合理地防止其再次发生，并在进行的任何诉讼中给予充分合作，以保护Mursion的权利。',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': '除此处明确规定外，未经Mursion事先书面批准，本协议中的任何内容均不得以默示或其他方式解释为对于Mursion知识产权的任何其他权利。',
    'MursionPortal.EulaAgreement.NonDisclosure': '您同意，不会出于此处明确许可以外的任何目的，向任何人展示或披露Mursion软件或其任何部分。',
    'MursionPortal.EulaAgreement.AgreeAndRelease': '您同意并特此免除、解除Mursion及其员工、管理人员、代理商、分支机构和董事由于您对Mursion软件的使用而产生或以其他方式与之相关的任何已知或未知索赔，或以其他方式使其免受损害。',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': '您了解并确认，Mursion可能会按照此处可以找到的Mursion隐私政策条款，收集、维护、使用、存储和分析从您对Mursion软件的使用中收集到的信息和数据。',
    'MursionPortal.EulaAgreement.AccessRights': 'Mursion授予您的使用权和访问权应在向您提供对Mursion软件访问的Mursion软件即服务协议到期或被终止时立即终止，或者在您违反本协议任何规定时立即终止。',
    'MursionPortal.EulaAgreement.AgreementGoverned': '本协议应受特拉华州法律管辖并据此做出解释，排除法 律 冲突原则。由您使用Mursion软件或本协议引起或与之相关所有争议，均应提请特拉华州具有管辖权的州或联邦法院，您特此认可这些法院的属人管辖权。',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': '如果本协议的任何条款被宣布为无效，则应从本协议中删除这些条款，本协议中的其他条款仍然具有完全的效力。',
    'MursionPortal.EulaAgreement.AgreementConflict': '如果本协议的条款与Mursion软件即服务协议的条款冲突，则以此处包含的条款为准。',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': '您对Mursion软件的使用或访问均构成对本协议和此处所述条款和条件的同意。',
    'MursionPortal.Header.MursionUserAgreement': 'Mursion和用户协议',
    'MursionPortal.Button.Back': '返回',
    'MursionPortal.Button.Skip': '跳过',
    'MursionPortal.Title.BackToSessions': '返回到课次',
    'MursionPortal.Title.Proceed': '继续',
    'MursionPortal.Message.SkipActivity': '如果您选择跳过，则将丢失我们的行为分析引擎可能提供的成绩见解。我们建议您不要跳过此行动。',
    'MursionPortal.HeaderTitle.ConfirmCancellation': '确认取消',
    'MursionPortal.Label.Delete': '删除',
    'MursionPortal.Label.Restore': '还原',
    'MursionPortal.HeaderTitle.ConfirmRestore': '确认还原',
    'MursionPortal.Label.Archive': '存档',
    'MursionPortal.Button.CancelTraining': '取消培训',
    'MursionPortal.Button.No': '否',
    'MursionPortal.Button.CancelEvent': '取消活动',
    'MursionPortal.Label.Update': '更新',
    'MursionPortal.HeaderTitle.ConfirmUpdate': '确认更新',
    'MursionPortal.Button.UpdateSession': '更新课次',
    'MursionPortal.Label.Swap': '互换',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': '确认接受互换',
    'MursionPortal.Button.AcceptSwap': '接受互换',
    'MursionPortal.Label.Reason': '原因',
    'MursionPortal.Placeholder.ReasonForSwapping': '请添加互换原因。',
    'MursionPortal.Label.Request': '请求',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': '确认已接受的请求',
    'MursionPortal.Button.AcceptRequest': '接受请求',
    'MursionPortal.Placeholder.ReasonForAccepting': '请添加接受原因。',
    'MursionPortal.Button.ConfirmProjectChange': '确认项目更改',
    'MursionPortal.HeaderTitle.Confirm': '确认',
    'MursionPortal.Button.ConfirmCreateSession': '确认创建课次',
    'MursionPortal.Placeholder.ReasonForCreation': '请添加创建原因。',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': '确认分配模拟专家',
    'MursionPortal.Placeholder.ReasonForAssigning': '请添加分配原因。',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': '确认更改模拟专家',
    'MursionPortal.Placeholder.ReasonForChanging': '请添加更改原因。',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': '确认时区',
    'MursionPortal.Message.DontHaveMursionInstalled': '未安装ML3 Desktop App？单击<code>下载</code>以安装。',
    'MursionPortal.ErrorMessage.CannotContainCharacter': '场景名称不应包含“_”字符',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName}当前已存在。请输入其他{objectName} {fieldName}。',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': '用户电子邮箱已被使用。请输入其他电子邮箱地址。',
    'MursionPortal.ErrorMessage.NameExists': '已存在有相同{fieldName}的用户。请输入其他{fieldName}。',
    'MursionPortal.ErrorMessage.UnableToRemove': '无法删除{entity}。此{entity}当前已在一项活动的{depEntity}中使用。',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': '选择的时间块与现有时间块重叠。',
    'MursionPortal.ErrorMessage.AccessIsDenied': '访问被拒绝。',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': '必须打破些什么。请稍后重试。',
    'MursionPortal.ErrorMessage.Unauthorized': '未经授权',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': '找不到刷新令牌',
    'MursionPortal.ErrorMessage.CannotRefreshToken': '无法刷新令牌',
    'MursionPortal.QADialog.NoParticipants': '没有参与者',
    'MursionPortal.ErrorMessage.UsersUnavailable': '您的某些用户在所选时间不可用。',
    'Filters.SsoProtocolType': '协议：全部',
    'Filters.SsoProtocolTypePlaceHolderHint': '搜索SSO协议',
    'Filters.SSO.PrivacyAll': '类型全部',
    'Filters.SSO.PrivacyPublic': '类型公开',
    'Filters.SSO.PrivacyPrivate': '类型私人',
    'Filters.SSO.PrivacyAriaLabel': 'sso隐私类型筛选条件',
    'Cleints.Modal.Label.DomainsTitle': '可用于登录的域列表',
    'MursionPortal.NoDomains': '没有域',
    'MursionPortal.Header.Domains': '域',
    'MursionPortal.AddDomain': '添加域',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': '编辑域',
    'Clients.Modal.Projects.DomainField.EnterDomain': '输入域名（例如：example.com）',
    'Clients.Modal.Projects.DomainField.InvalidDomain': '无效域格式',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': '响应存在一些问题。',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': '授权标题为空',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': '无法刷新访问令牌',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': '授权令牌为空',
    'MursionPortal.ErrorMessage.AuthenticationFailed': '验证失败。',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': '不支持的响应格式。',
    'MursionPortal.ErrorMessage.AccountBlocked': '您的账户已被阻止，直至{blockedDate}。请尝试以后登录。',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '还剩{attempts}次尝试，失败后会锁定24小时。如果需要帮助，请重置您的密码。',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': '必须指定客户ID',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': '必须指定项目ID',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': '必须指定课次ID',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': '必须指定SSO ID',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': '必须指定学员ID',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': '必须指定用户ID',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': '必须指定团队ID',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': '必须指定项目ID',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': '必须指定资产设置ID',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': '必须指定场景ID',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': '必须指定场景模板ID',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig：必须指定许可方ID',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers： 必须指定被许可方ID',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': '必须指定全局用户ID',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList：必须指定被许可方ID',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink：必须指定场景ID和附件ID',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario：必须指定场景ID',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': '这不会删除任何当前保存的录制内容。',
    'MursionPortal.AriaLabel.ToggleMenu': '切换菜单',
    'MursionPortal.ClientListItem.RedirectToUserDetails': '重定向到{name}详细信息',
    'MursionPortal.AriaLabel.PreviousWeek': '上一周',
    'MursionPortal.AriaLabel.PreviousDay': '前一天',
    'MursionPortal.AriaLabel.NextWeek': '下周',
    'MursionPortal.AriaLabel.NextDay': '前一天',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': '课次仪表板',
    'MursionPortal.Title.SessionsPage': '课次页面',
    'Clients.heading.Clients': '客户端',
    'MursionPortal.AriaLabel.Client': '客户端',
    'MursionPortal.AriaLabel.SimSpecialist': '模拟专家',
    'MursionPortal.Project.TimeZoneStartDate': '时区开始日期',
    'MursionPortal.Project.TimeZoneEndDate': '时区结束日期',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': '选择时区组合框',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': '请选择参加课次的学员',
    'Dashboard.ProjectsTable.StatusValue.Active': '活动',
    'Dashboard.ProjectsTable.StatusValue.Completed': '已完成',
    'Dashboard.ProjectsTable.StatusValue.Archived': '已存档',
    'MursionPortal.Button.Refresh': '刷新',
    'MursionPortal.Prompt.CalendarUpdated': '日历已更新。',
    'MursionPortal.Prompt.ClickRefresh': '点击“刷新”以查看更改。',
    'MursionPortal.Label.SessionAttendance': '课次出勤',
    'MursionPortal.Label.SessionVideoUrl': '课次视频网址',
    'MursionPortal.Label.LearnerAttendance': '学员出勤',
    'MursionPortal.Text.UserProfileUpdated': '用户个人资料已更新！',
    'MursionPortal.Text.ClientUpdated': '客户端已更新！',
    'MursionPortal.Text.ProjectUpdated': '项目已更新！',
    'MursionPortal.Text.ScenarioUpdated': '场景已更新！',
    'MursionPortal.Text.LicenseeConfigUpdated': '被许可人配置已更新！',
    'MursionPortal.Prompt.TrainingScheduled': '<code>培训</code>已安排！',
    'Settings.SSO.Table.ColumnHeader.ClientName': '客户端名称',
    'MursionPortal.Text.UserCreatedSuccessfully': '已成功创建用户',
    'MursionPortal.Text.UserNotCreated': '尚未创建用户',
    'MursionPortal.Text.AssignedTeams': '分配的团队',
    'MursionPortal.Client.EmailSettings.Invitation': '邀请',
    'MursionPortal.Client.EmailSettings.InvitationReminder': '邀请提醒',
    'MursionPortal.Client.EmailSettings.Confirmation': '确认',
    'MursionPortal.Client.EmailSettings.Cancellation': '取消',
    'MursionPortal.Client.EmailSettings.FirstReminder': '首次提醒',
    'MursionPortal.Client.EmailSettings.SecondReminder': '第二次提醒',
    'MursionPortal.Client.EmailSettings.SessionRecording': '帐户所有者/主持人的课次录制已准备就绪',
    'MursionPortal.Client.EmailSettings.LearnerSession': '学员课次录制已准备就绪',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': '模拟课次视频录制被拒绝',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': '不允许模拟课次视频录制',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': '模拟课次视频录制被某个用户拒绝',
    'MursionPortal.Client.EmailSettings.RequestRecieved': '已收到确认的请求',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': '未完成请求',
    'MursionPortal.Client.EmailSettings.RequestDateTime': '需要更改日期/时间的请求',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': '选择您要发送给学员的电子邮件。Mursion建议您保留所有电子邮件的启用状态。',
    'MursionPortal.Client.EmailSettings.Header.Registration': '注册',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': '培训课次',
    'MursionPortal.Client.EmailSettings.Header.Videos': '视频',
    'MursionPortal.Client.EmailSettings.Header.Requests': '请求',
    'MursionPortal.AriaLabel.ToggleButton': '切换按钮',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': '仅在“录制”启用时',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': '仅在“请求功能”启用时',
    'MursionPortal.SuccessDialog.Title': '成功对话',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': '客户端SSO配置已成功创建。',
    'ITManager.TechRestriction.Table.EmptyMessage': '未找到银行技术限制。',
    'ITManager.TechRestriction.Table.Tag': '标签',
    'ITManager.TechRestriction.Table.StartDate': '开始日期',
    'ITManager.TechRestriction.Table.EndDate': '结束日期',
    'ITManager.TechRestriction.EditDialog.EnterTag': '输入标签名称',
    'ITManager.TechRestriction.Table.HoverTitle': '查看/编辑技术限制',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': '尚无技术限制',
    'MursionPortal.DateTimePicker.StartTime': '开始时间',
    'MursionPortal.DateTimePicker.EndTime': '结束时间',
    'MursionPortal.TechRestrictions': '技术限制',
    'MursionPortal.Caption.ScenarioBankUsersList': '场景库用户列表',
    'MursionPortal.Caption.SsoConfigurationList': 'sso配置列表',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': '请按Enter键与日历交互，然后选择开始日期和结束日期',
    'MursionPortal.Modal.Header.SessionTimeout': '课次超时',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': '待处理（非模拟专家）',
    'MursionPortal.Status.PendingNoL': '待处理（非学员）',
    'MursionPortal.Status.PendingNoLearners': '待处理（非学员）',
    'MursionPortal.Status.PendingNoLSS': '待处理（非学员/模拟专家）',
    'MursionPortal.Status.Empty': ' ',
    // needs translations
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Sim视图',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': '计划视图',
    'MursionPortal.Table.ColumnSelection.Button.Label': '列',
    'Session.Table.Column.SubmitRequest': '提交请求',
    'Filters.SimSpecialistStatus': 'Sim Specialist状态',
    'Filters.SimSpecialistStatusPlaceHolderHint': '搜索Sim Specialist状态筛选条件...',
    'Users.TableModal.SSOUserID': 'SSO用户ID',
    'MursionPortal.UnavailableSlotsError.SorryText': '您好!我很抱歉!',
    'MursionPortal.UnavailableSlotsError.BusyText': '看上去我们正忙着和其他学员一起进行模拟。',
    'MursionPortal.UnavailableSlotsError.TryAgainText': '请明天或改天重试!',
    'MursionPortal.Session.Survey.ClickHere': '单击此处',
    'MursionPortal.Session.SurveyNotLoading': '调查未加载?',
    'Users.Table.Heading.Viewonly': '(只读)',
    'MursionPortal.BookItUI.BusyText': '看上去此时我们正忙着和其他学员一起进行模拟。',
    'MursionPortal.BookItUI.TryLaterText': '很快就会开放更多模拟课程，请今天晚些时候重试。',
    'MursionPortal.BookItUI.MoreSimulationsText': '很快就会开放更多模拟课程。',
    'MursionPortal.BookItUI.ComeBackText': '如果您想在今天晚些时候进行模拟，请记得回来重试。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': '已存档用户',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers}个用户当前已存档。继续操作会将这些用户添加到选定的团队并取消存档用户。',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': '{existingUsers}个上述用户已存在，且{archivedUsers}个用户当前已存档。继续操作会将这些用户添加到选定的团队，并取消存档已存档用户。',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': '不能超过{code}个小时',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': '即将空闲时间',
    'MursionPortal.Table.ColumnHeader.TotalSession': '总课程数',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': '有效证书',
    'MursionPortal.Button.Decline': '拒绝',
    'MursionPortal.Having.Issues.Logging.In': '登录时遇到问题?',
    'MursionPortal.Label.Call': '致电:',
    'MursionPortal.Project.Hours': '小时',
    'Users.Button.ProfService': '专业服务',
    'Users.Button.Operations': '运营服务',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': '选择或输入',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': '学员通信个性化设置',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': '您可以在此处为学员定制特定消息。',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': '已确认您可以参加模拟!我们建议您在开始前几分钟进入。',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners}已确认可以参加模拟!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': '日期和时间',
    'MursionPortal.Personalized.LearnerDashboard.Section': '您希望所有学员在学员仪表板上看到的消息',
    'MursionPortal.Personalized.SchedulingPage.Section': '您希望所有学员在计划页面上看到的消息',
    'MursionPortal.Personalized.Section.Tooltip': '该消息将在所有地方更新',
    'MursionPortal.CheckboxLabel.JoinNow': '立即加入',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': '对于“Sim空闲时间”之后指定的“即将空闲时间”小时数，将向客户角色显示禁用的时段。如果“即将空闲时间”为0，则不会显示禁用的时段，而只会显示“Sim空闲时间”时段。如果“即将空闲时间”为24小时，则“Sim空闲时间”小时数之后的所有可用时段将显示为一天内禁用的时段。',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': '请稍后在贵组织的办公时间内回来处理此场景。',
    'MursionPortal.Setting.Personalization.LastUpdated': '最近更新时间:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': '立即加入请求',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': '我们正在为您匹配一个头像<code>进行实时模拟</code>。请查看以下资料进行准备。',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': '很抱歉!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': '我们所有的头像都忙于与其他学员交谈。请稍后再回来或几分钟后重试。',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': '恭喜!已为您匹配头像!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': '头像正在等您!准备好后单击“加入实时模拟”，否则您的体验将在倒计时后自动开始。',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': '离开',
    'MursionPortal.Personalized.ScenarioPage.Section': '您希望学员在场景页面上看到的消息',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': '为特定场景新建',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': '搜索或选择场景:',
    'MursionPortal.Personalized.Scenario.Selection.Title': '添加新的特定场景',
    'MursionPortal.Personalized.NoMessage': '目前没有设置消息',
    'MursionPortal.Personalized.MetaData.NoHistory': '尚无历史记录。',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code}目前已预订一节课。',
    'Users.Table.SendInvite.TooltipText': '禁用注册电子邮件也会禁用门户中的“点击邀请”按钮',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': '单击“加入实时模拟”以加入或继续课程。',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': '场景',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': '团队和项目活动',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': '尚未计划',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': '尚未完成',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': '已完成',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': '尚未分配',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': '不同数量的学员被分配到至少一个团队，团队被分配到至少一种场景，但学员从未安排过课程。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': '不同数量的学员安排了至少一节课但尚未参加并完成至少一节课。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': '不同数量的学员已完成至少一节课。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': '不同数量的学员未分配到团队或学员来自未分配到任何场景的团队。',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': '学员完成',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': '特殊学员',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(最多{sessionCount}节课)',
    'Dashboard.SequenceOrder.Header.Title': '错过、延迟取消和延迟改期活动',
    'MursionPortal.Integrations': '集成',
    'MursionPortal.Personalized.Message.Delete.Title': '确定要删除消息吗?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': '删除消息',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': '一次只能对一条消息进行更改。请保存或取消已处于编辑模式的消息。',
    'MursionPortal.Error.Time': '美国东部时间上午6点至晚上10点',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': '当启用“立即加入”时，始终启用“由BookIt计划”。',
    'MursionPortal.JoinNow.FinalizedScenario.Message': '场景已完成，请在场景激活后回来。',
    'MursionPortal.CheckboxLabel.sessionMissedTime': '课程错过时间',
    'MursionPortal.Title.Seconds': '秒',
    'MursionPortal.RequestTable.Accept.ErrorMessage': '抱歉，课程已取消。',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': '计划已锁定',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': '计划已解锁',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': '计划锁定',
    'MursionPortal.Table.ColumnHeader.SimAvailable': '可用的Sim',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': '如果没有学员或SIM加入，此后课程将被标记为“错过”的时间。',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': '尚未完成',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': '已完成1节课',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': '已完成2节课以上',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1节课',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2节课',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3节课',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4节课以上',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': '分配给此场景但尚未完成的特殊学员数量',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': '分配给此场景且已完成一次的特殊学员数量。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': '分配给此场景且已完成两次或以上的特殊学员数量。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': '错过、延迟取消或延迟改期一节课的特殊学员数量。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': '错过、延迟取消和/或延迟改期两节课的特殊学员数量。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': '错过、延迟取消和/或延迟改期三节课的特殊学员数量。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': '错过、延迟取消和/或延迟改期四节课或以上的特殊学员数量。',
    'MursionPortal.ScenarioBank.ItemBank.Label': '构成要素',
    'MursionPortal.ScenarioBank.ItemBank.Skills': '技能',
    'MursionPortal.ScenarioBank.ItemBank.Domains': '领域',
    'MursionPortal.ScenarioBank.ItemBank.Events': '活动',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': '途径',
    'MursionPortal.ScenarioBank.ItemBank.Resources': '资源',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': '结果',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': '心态',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': '头像',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': '研究总结',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': '学员指导',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': '等级',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': '领域',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': '翻译',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': '培养技能',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': '请从筛选条件中选择至少一个项目和场景',
    'MursionPortal.Learner.Onboarding': '学员入职',
    'MursionPortal.Tab.Onboarding': '入职',
    'MursionPortal.OrganizationName.Placeholder': '输入贵组织的名称',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': '学员已锁定',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': '由于错过/取消率而锁定计划的学员',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': '计划锁定日期/时间',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': '自动发布之前的日期/时间',
    'MursionPortal.DontHaveEmail.Text': '没有贵组织的电子邮件地址?',
    'MursionPortal.InvalidCompanyName.Error': '贵组织要求用户使用其电子邮件地址登录。请在上方输入。',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': '欢迎',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Mursion视频概述',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': '需要了解的重要事项',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': '我们很高兴欢迎您参加Mursion学习课程!Mursion使用由人机交互技术驱动的沉浸式模拟来提供全面的学习体验，为您提供必要的工具和技术，以便在日新月异的世界中取得成功。',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': '无论您是初学者还是经验丰富的专业人士，我们的课程都能为每个人提供一些有用的技能。我们很高兴您能开始学习。',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': '首先，观看此<strong>关于Mursion的视频概述!</strong>',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>现在您已准备好:</strong>安排您的第一次模拟!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': '接下来我们一起来了解一些重要事项吧!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Mursion方法',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion以研究为基础，并被证明可以推动行为改变。我们的头像模拟方式将帮助您学习在情绪激动的情况下做出适当的反应。',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': '为什么使用头像',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion是比传统或角色扮演方法更有效的工具。研究表明，相比与同事或经理互动，人们在与头像互动时更加充满信任和好奇。',
    'MursionPortal.LearnerOnboardingPage.Human.Text': '人机交互',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': '每一个Mursion模拟都有一个现场Simulation Specialist，在每个模拟中为多达五个虚拟头像提供口语对话和动作。',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': '为什么练习很重要',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': '提高领导技能并不容易。但研究表明，学习结合实践才最有影响力。Mursion通过打造安全、动态且易于接近的场所来优先考虑实践。',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': '创建领域',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': '组织名称',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': '创建途径',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': '创建事件',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': '创建资源',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': '创建成果',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': '创建心态',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': '创建头像名称',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': '创建研究摘要',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': '创建学员指导',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': '返回场景库',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': '在此输入',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': '显示',
    'MursionPortal.ScenarioInfo.CertificationHistory': '认证历史记录',
    'MursionPortal.Session.AlreadyBooked': '抱歉，课程已被另一个SIM接受。',
    'MursionPortal.CustomizeInvitation.Title': '定制您的邀请',
    'MursionPortal.CustomizeInvitation.SubTitle': '要进行自定义，请覆盖下面每个字段的文本或保留默认文本。',
    'MursionPortal.CustomizeInvitation.IntroMessage': '给学员的介绍消息(可用场景将自动填充在此消息下方):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': '发件人姓名(电子邮件地址来自noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': '主题',
    'MursionPortal.InviteLearnersTeam.Section.Title': '您想邀请哪些团队来安排?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': '学员将受邀参加他们有资格安排的该项目中的所有场景',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': '分配的场景',
    'MursionPortal.Confirmation.Modal.Invitations.Text': '您的邀请已安排好!',
    'MursionPortal.ScenarioSetting.ValidationMessage': '必须小于或等于客户和/或项目级别的相同设置。',
    'MursionPortal.ScenarioBank.EventDetail.Details': '详情',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': '字符限制: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': '字符限制: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': '活动模板',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': '激起他人分享工作信息的信心和信任',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': '领域详情',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': '保存这些更改将在所有场景中更新此{type}。请添加更改的简短描述:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': '用户名或电子邮件',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': '已认证',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': '取消认证',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': '未激活',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': '释放学员进行安排',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': '准备练习您的技能!您的Mursion课程可随时安排。',
    'MursionPortal.CustomizeInvitation.Intro.Message': '您已受邀参加Mursion的模拟体验。单击下面安排课程，或访问您的仪表板并探索。',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': '确定要释放{code}个学员进行安排吗?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '释放{code}个学员进行安排。',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': '锁定学员进行安排的日期/时间',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': '学员自动释放的日期/时间',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'NextGen场景',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': '预订',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': '文章',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': '出版商',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': '选择级别',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': '搜索或选择领域:',
    'MursionPortal.Label.CompletionRate.Tooltip': '完成率是指学员在失去计划能力(锁定)之前可以完成模拟的次数',
    'MursionPortal.Label.SchedulingRate.Tooltip': '计划率是学员在失去计划能力(锁定)之前可以安排模拟的次数',
    'MursionPortal.Label.MissCancellationRate.Tooltip': '错过/取消率是学员在失去计划能力(锁定)之前可以错过或取消模拟的次数',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': '计划锁定时间是计划锁定的持续时间',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': '受挫',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': '技能详情',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': '心态详情',
    'MursionPortal.ProjectCreate.DeliveryHours': '授课时间',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': '将向学员显示即时时段的时间范围',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': '授课时间开始',
    'MursionPortal.ProjectCreate.DeliveryHours.End': '授课时间结束',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': '学员指导详情',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': '头像详情',
    'MursionPortal.ScenarioBank.Label.AddLink': '添加链接',
    'MursionPortal.ScenarioBank.Year.placeholderText': '年',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': '资源详情',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': '结果详情',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': '高级',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': '中级',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': '基本',
    'MursionPortal.ScenarioBank.ItemBank.Skill': '技能',
    'MursionPortal.Resources.Search.Text': '搜索或选择资源',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': '研究总结详情',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': '研究摘要',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': '途径详情',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': '途径',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': '确定要存档此{block}吗?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': '确定要取消存档此{block}吗?',
    'MursionPortal.ScenarioBank.Unarchived': '取消存档',
    'MursionPortal.ScenarioBank.ItemBank.Resource': '资源',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': '心态',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': '学员指导',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': '活动详情',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': '头像视角',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': '介绍',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Disc Profile',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': '言语风格',
    'MursionPortal.ScenarioBank.Content': '内容',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': '场景名称',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': '场景封面故事',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': '选择收藏结果',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': '为什么这很重要',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': '头像指南',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': '查看场景',
    'MursionPortal.ScenarioBank.NextGen.Event': '活动图标',
    'MursionPortal.NextOnProject.Card.JoinNow': '加入实时模拟',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': '工作方式',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': '您好!您很快就会与头像匹配，<code>立即加入实时模拟</code>。',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': '与头像匹配后，单击<code>“加入实时模拟”</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': '出现提示时，单击<code>“允许”</code>以访问麦克风/摄像头',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': '单击<code>“开始课程”</code>并等待头像开始模拟',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': '不在现场，优先考虑',
    'MursionPortal.FirstGen.Scenario.Button.Text': '第一Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '第二Gen',
    'MursionPortal.NextGen.Scenario.Assessment': '评估',
    'MursionPortal.NextGen.Scenario.OutOfSite': '不在现场，优先考虑',
    'MursionPortal.NextGen.Scenario.Synopsis': '概要',
    'MursionPortal.NextGen.Scenario.MinimumPoints': '成功结果所需的最低分数',
    'MursionPortal.NextGen.Scenario.Successful': '成功',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': '进一步发展的机会',
    'MursionPortal.NextGen.Scenario.LibraryInfo': '库信息',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': '难度级别',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': '场景封面图',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': '场景缩略图',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': '封面图说明',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': '缩略图描述',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': '字符限制: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': '头像可能的反应',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': '反思指南',
    'MursionPortal.NextGen.Scenario.Scoring': '评分',
    'MursionPortal.ErrorMessage.buildingBlock': '必须指定构成要素ID',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': '将文件拖放到此处或单击以选择文件',
    'MursionPortal.NextGen.Scenario.Publish': '发布',
    'MursionPortal.NextGen.Scenario.Publish.Label': '请填写必填字段(*)以便发布。',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': '最后发布日期: {publishedBy}，发布者: {publishDate}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': '我同意Mursion的模拟授课模式，并确认在我参与的所有模拟中坚持和遵守Mursion行为准则。在<code>此处</code>阅读关于Mursion模拟和行为准则的更多信息。',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': '我现在没有时间做这件事',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': '我不知道这是什么',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': '我稍后会回来做这件事',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': '大多数完成此模拟的人都发现花点时间做这件事是值得的。这将最多需要 30 分钟时间。',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': '这种互动练习将通过视频的方式帮助您在安全和动态的场所中发展能力和技能。',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': '当然!您可以在组织规定的时间内随时回来并参加现场模拟，练习您的技能!',
    'MursionPortal.JoinNow.CancellationText.Heading': '为什么要取消?',
    'MursionPortal.JoinNow.CancellationPage.Title': '使用头像的练习机会!',
    'MursionPortal.JoinNow.CancellationPage.Text': '根据星巴克的政策和指导方针确定解决问题的积极方法，并寻找机会为所有顾客留下最精彩时刻。',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': '继续加入模拟',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': '我们正在为您寻找适合<code>实时模拟的头像!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>恭喜</code>，已为您匹配用于实时模拟的头像!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': '我们正在<code>为您与头像建立连接</code>，实时模拟即将开始!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': '请不要关闭此浏览器窗口!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': '使用策略',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': '使用头像的练习机会!',
    'MursionPortal.Text.Sorry': '抱歉',
    'MursionPortal.Text.Matching': '正在匹配',
    'MursionPortal.Text.Connecting': '正在连接',
    'MursionPortal.JoinNowScenarioInfoCard.Text': '请稍后再回来或几分钟后重试!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': '我们所有的头像都忙于与其他学员交流!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': '取消，然后返回仪表板',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': '至少添加2个',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+添加',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': '选择活动',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': '选择技能',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': '头像行为*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': '预期的学员回复*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': '添加回复',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': '选择头像',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': '头像可能的反应',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': '描述观察到的行动',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': '解释影响',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': '优势评估*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+添加优势评估',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': '优势',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': '机会评估*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+添加机会评估',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': '机会',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': '选择积极的心态',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': '选择受挫心态',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': '活动评分',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': '添加积极和受挫心态*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': '添加更多积极心态',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': '添加更多受挫心态',
    'MursionPortal.Text.Searching': '正在搜索',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': '心态引导',
    'Dashboard.ContractProgress.Column.RemainingUtilization': '剩余使用课时',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': '预计剩余课时',
    'MursionPortal.Label.Completion': '完成率',
    'Dashboard.Report.ReportSessionOption.AllDays': '所有课程',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': '预计剩余课时',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': '剩余的模拟课程和计划的已购买模拟课程数量',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': '课程细分 – 快速查看',
    'MursionPortal.NextGen.SelectorText.Race': '种族',
    'MursionPortal.NextGen.SelectorText.Ethnicity': '族裔',
    'MursionPortal.NextGen.SelectorText.None': '无',
    'MursionPortal.NextGen.SelectorText.Woman': '必须是女性',
    'MursionPortal.NextGen.SelectorText.Man': '必须是男性',
    'MursionPortal.NextGen.SelectorText.Wheelchair': '必须坐在轮椅上',
    'MursionPortal.NextGen.SelectorText.agedText': '年龄必须在55岁以上',
    'MursionPortal.NextGen.Avatar.Restriction': '头像限制',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': '头像/场景构图',
    'MursionPortal.Label.Opening': '正在打开',
    'MursionPortal.Label.Closing': '正在关闭',
    'MursionPortal.Label.Introduction': '介绍',
    'MursionPortal.ReflectionGuide.LearnerAssessment': '学员评估',
    'MursionPortal.ReflectionGuide.HostAssessment': '主持人评估',
    'MursionPortal.ReflectionGuide.HostOpportunity': '主持人机会',
    'MursionPortal.ReflectionGuide.Introduction.Text': '欢迎回来!准备好反思您的经历了吗?(学员同意后再继续)',
    'MursionPortal.Label.Say': '回答',
    'MursionPortal.Label.Ask': '提问',
    'MursionPortal.Label.Bullet': '项目符号',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': '对话进行得怎么样?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': '真实的对话如何对应您的对话计划?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': '发生了什么?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': '头像中是否有任何线索（语言和非语言）暗示您对话将如何进行?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': '头像中是否有任何线索（语言和非语言）暗示您对话将如何进行?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': '这次对话让您了解了一些动态发展的挑战 – Mickey很沮丧。',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': '虽然本意是好的，但我经常看到领导做出以下并不那么有效的反应:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': '我还看到领导做出以下更有成效的反应:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': '这次对话让您了解了一些动态发展的挑战',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': '您认为从何处着手?(跟进:为什么/怎么会/如何得知?):您认为从何处着手?(跟进:为什么/怎么会/如何得知?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': '如果我可以提升您在会议期间表现出的一项优势(选择一项):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': '我的想法与您的想法一致吗?为什么或者为什么不?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': '如果我可以提高我观察到的一个发展机会(选择一项):',
    'MursionPortal.ReflectionGuide.Closing.Say': '这件事有点棘手，感谢您今天使用Mursion练习这些重要技能!',
    'MursionPortal.ReflectionGuide.Closing.Ask': '您从这次经历中学到了哪些下次在现实生活中遇到类似情况时会用到的有用经验?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': '选择研究摘要',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': '选择学员指导',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': '单击此处替换此文件。',
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.ScenarioContent.Label': '添加最小值{min}和最大值{max}',
    'MursionPortal.Search.Text.Skills': '搜索或选择技能',
    'MursionPortal.Search.Text.Avatar': '搜索或选择头像',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': '时间表和计划',
    'MursionPortal.NextGen.Session.Replay': '课程重播',
    'MursionPortal.NextGen.StrengthsAndOpportunities': '优势和机遇',
    'MursionPortal.NextGen.Recommendations': '建议',
    'MursionPortal.NextGen.MoreToDiscover': '了解更多',
    'MursionPortal.NextGen.MissionAccomplished': '任务完成!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': '场景资料',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': '衔接课程',
    'MursionPortal.NextGen.Learner.SkillLevel': '技能水平',
    'MursionPortal.NextGen.Learner.YourMission': '您的任务',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': '仅显示第二个Gen场景',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': '创建新的收藏技能',
    'MursionPortal.NextGen.Learner.StartSimulation': '开始模拟',
    'MursionPortal.SimMaterials.Label.CoverStory': '封面故事',
    'MursionPortal.SimMaterials.Label.LearnerMission': '学员的任务',
    'MursionPortal.SimMaterials.Label.Background': '背景',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': '观点与认同',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'SIM仪表板',
    'MursionPortal.NextGen.Learner.ToStart': '开始',
    'MursionPortal.Project.DeliveryHours.Standard': '标准授课时间',
    'MursionPortal.Project.DeliveryHours.Global': '全球授课时间',
    'MursionPortal.Label.Monday.ShortForm': '星期一',
    'MursionPortal.Label.Tuesday.ShortForm': '星期二',
    'MursionPortal.Label.Wednesday.ShortForm': '星期三',
    'MursionPortal.Label.Thursday.ShortForm': '星期四',
    'MursionPortal.Label.Friday.ShortForm': '星期五',
    'MursionPortal.Label.Saturday.ShortForm': '星期六',
    'MursionPortal.Label.Sunday.ShortForm': '星期日',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': '根据收藏场景定制',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': '此场景根据原始收藏场景定制。对收藏场景进行的任何更新都不会应用到此场景。',
    'MursionPortal.BuildingBlock.Label.PublishedDate': '发布日期',
    'MursionPortal.Label.CopyTeamInviteLink': '复制团队邀请链接',
    'MursionPortal.NextGen.SessionReplayText': '对您来说这次对话是一次练习机会，可以促进您在困难时期为团队成员提供支持。您能否在此取得成功取决于您对Jo是否具有同理心。',
    'MursionPortal.NextGen.TheMission': '任务:',
    'MursionPortal.NextGen.DemonstratedStrength': '展现出的实力',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': '未来增长的机会',
    'MursionPortal.NextGen.CompetenciesFocus': '重点关注的能力包括:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': '在对话汇报期间，发现了观察到的优势和发展机会:',
    'MursionPortal.NextGen.WeObserved': '我们观察到...',
    'MursionPortal.NextGen.WhyThisMatters': '为什么这很重要',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': '如果错过/取消率不为空，则计划锁定时间不能为空。',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': '您已自定义收藏场景，未来对收藏场景的任何更改都不会反映在此场景中。要继续吗?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': '技能要点',
    'MursionPortal.SimMaterials.Label.SimulationEvents': '模拟活动',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': '头像行为',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': '预期的学员回复',
    'MursionPortal.Label.NoTimeSelected': '未选择时间',
    'MursionPortal.Label.StartTime.EndTime.Selected': '必须选择开始时间和结束时间',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': '反思与汇报',
    'MursionPortal.NextGen.Scenario.Planning': '规划',
    'MursionPortal.HowItWorks.Experience.Text': '您的体验将在几秒钟后自动开始!',
    'MursionPortal.HowItWorks.MicCamera': '麦克风/摄像头',
    'MursionPortal.HowItWorks.Prompt.Text': '出现提示时，点击<strong>‘允许’</strong>以访问麦克风/摄像头，以便头像可以看到您并听到您的声音!',
    'MursionPortal.HowItWorks.StartSession.Text': '单击<strong>‘开始会话’</strong>并查看场景描述以做好准备!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': '关键能力',
    'MursionPortal.LearnerMaterials.ResearchMaterials': '研究资料',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': '实践机会',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': '从收藏中创建场景',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': '请选择适合您的时段。我们将在您选择的其中一个时段为您安排场景。',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': '实力',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': '机会',
    // needs translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations 
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': '如果您说“我同意”，Mursion 软件将处理您的实时模拟并录制模拟会话的视频，以便您在 Mursion 软件门户中查看。除非适用法律要求或出于质量保证目的，未经您的许可，Mursion 不会分发或披露您的视频录制内容。 Mursion 将使用您使用其软件时产生的匿名数据来改进其模拟技术。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': '如果您说“我同意”，Mursion 软件将处理您的实时模拟并录制模拟会话的视频，以便您在 Mursion 软件门户中查看。请注意，根据代表您获得 Mursion 软件许可的客户的要求，您录制的模拟会话的副本将与客户选择的教练、导师或审阅者共享。根据合同，所有审阅者都有义务遵守 <code>Mursion 的隐私声明</code>，除非您同意不同的隐私条款。除非适用法律要求或出于质量保证目的，未经您的许可，Mursion 不会分发或披露您的视频录制内容。 Mursion 将使用您使用其软件时产生的匿名数据来改进其模拟技术。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': '根据代表您获得 Mursion 软件许可的客户的要求，您的模拟会话将被录制为音频和视频。如果您同意继续，您录制的模拟会话的副本将与客户选择的第三方共享，以便对录制内容进行分析和评分。根据合同规定，这些人有义务遵守 <code>Mursion 的隐私声明</code>，除非您同意不同的隐私条款。但是，根据您同意完成的作业或评估的条款，您不会收到或有权访问您录制的模拟会话的副本。 Mursion 可能会出于质量保证目的使用您的视频录制，并将使用您使用其软件时产生的匿名数据来改进其模拟技术。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations  
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations  
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default chineseLangTranslations;
